<template>
  <basicLayout>
    <OrderLines
      v-if="purchase_order.id"
      :purchase_order="purchase_order"
      filter="purchase_order"
      origin="OrdersByPO"
      parent_can_supply_filter="all"></OrderLines>
  </basicLayout>
</template>

<script>
import { db } from "@/firebaseConfig";
import basicLayout from "@/components/basicLayout";
import OrderLines from "@/components/OrderLines";

const orders = db.collection("orders");

export default {
  name: "orderlinesbypo",
  data() {
    return {
      po_id: this.$route.params.po_id,
      purchase_order: {},
    };
  },

  components: { basicLayout, OrderLines },
  watch: {
    po_id: {
      immediate: true,
      handler(po_id) {
        if (po_id) {
          this.$bind("purchase_order", orders.doc(po_id));
        }
      },
    },
  },
};
</script>
