<template>
  <basicLayout>
    <form>
      <h5>EDIT ORDER {{ order.po_number }}</h5>
      <div class="card m-2">
        <div class="card-body">
          <div class="form-group col-md-12">
            <div class="form-row mb-1">
              <div class="col-4">
                <label>Name Code</label>
                <div
                     type="text"
                     class="form-control form-control-sm">
                  <h6>{{ order.name }}</h6>
                </div>
              </div>
              <div class="col">
                <label>Price Level</label>
                <div
                     type="text"
                     class="form-control form-control-sm">
                  {{ name && name.productpricing }}
                </div>
              </div>
              <div class="col">
                <label>PO Ref</label>
                <div
                     type="text"
                     class="form-control form-control-sm">
                  {{ order.po_number }}
                </div>
              </div>
              <div class="col">
                <label for="lyric_language"> Type </label>
                <select
                        v-model="order.type"
                        class="custom-select custom-select-sm d-block">
                  <option>Urgent</option>
                  <option>Catalogue</option>
                  <option>New Release</option>
                  <option>Website</option>
                </select>
              </div>
            </div>
          </div>
          <div class="form-group col-md-12">
            <button
                    @click.prevent="enter_order"
                    class="btn btn-sm btn-outline-success mr-2">
              Save Order
            </button>
          </div>
        </div>
      </div>

      <div
           v-infinite-scroll="loadMore"
           infinite-scroll-disabled="busy"
           infinite-scroll-distance="10">
        <div
             v-if="filtered.length"
             class="card m-2">
          <div class="card-header">
            <!-- <button
              @click.prevent="sort_by"
              class="btn btn-sm btn-outline-secondary"
            >
              sort
            </button> -->
            <div class="form-row mb-1">
              <div class="col-6">
                <label @click.prevent="sort_by">Title</label>
              </div>
              <!-- <div class="col text-center">
                <label>Available</label>
              </div> -->
              <div class="col text-center">
                <label>Quantity</label>
              </div>
              <div class="col text-center">
                <label>Shipped</label>
              </div>
              <div class="col text-center">
                <label>Price</label>
              </div>
              <div class="col text-center">
                <label>Discount %</label>
              </div>
              <div class="col text-center">
                <label>Extension</label>
              </div>
              <div class="col text-center">
                <label>Total</label>
              </div>
              <div class="col text-center"></div>
            </div>
          </div>
          <div class="card-body">
            <div class="form-group col-md-12">
              <div>
                <div
                     v-for="(line, index) in filtered.slice(0, visible_lines)"
                     :key="line.id">
                  <div
                       class="form-row mb-1"
                       :class="lineClass(line)">
                    <div class="col-6">
                      <v-select
                                @search="(query) => debounce_search(query)"
                                @input="(product) => set_price(line, product)"
                                v-model="line.product"
                                :filter="algolia_search"
                                :selectOnTab="true"
                                :clearable="false">
                        <template #option="{ _highlightResult }">
                          <div :class="prod_class(_highlightResult)">
                            <span
                                  v-html="_highlightResult.description.value
        "></span>
                            |
                            <span v-html="_highlightResult.code.value"></span> |
                            <span
                                  v-html="_highlightResult.barcode.value"></span>
                          </div>
                        </template>
                      </v-select>
                    </div>
                    <!-- <div class="col">
                      <div
                        type="number"
                        class="form-control form-control-sm text-right"
                      >
                        {{ available(line.product) }}
                      </div>
                    </div> -->

                    <v-popover
                               v-if="line.product.code"
                               placement="left"
                               offset="8"
                               trigger="hover">
                      <!-- This will be the popover target (for the events and position) -->
                      <info-icon size="1.2x"></info-icon>
                      <!-- This will be the content of the popover -->
                      <template slot="popover">
                        <ProductPopover
                                        :product_code="line.product.code"
                                        :product="line.product"
                                        :price_level="name && name.productpricing
        "></ProductPopover>
                      </template>
                    </v-popover>
                    <div
                         v-else
                         class="text-muted">
                      <info-icon size="1.2x"></info-icon>
                    </div>
                    <div class="col">
                      <input
                             v-model.number="line.quantity"
                             type="number"
                             class="form-control form-control-sm text-right"
                             placeholder="Qty"
                             required
                             @input="line_changed(line)" />
                    </div>
                    <div class="col">
                      <div
                           type="number"
                           class="form-control form-control-sm text-right">
                        {{ line.shipped }}
                      </div>
                    </div>
                    <div class="col">
                      <input
                             v-model.number="line.price"
                             type="number"
                             class="form-control form-control-sm text-right"
                             @input="line_changed(line)"
                             tabindex="-1" />
                    </div>
                    <div class="col">
                      <!-- {{line.id}} - {{i}} -->
                      <input
                             v-model.number="line.discount"
                             type="number"
                             class="form-control form-control-sm text-right"
                             @input="line_changed(line)" />
                    </div>
                    <div class="col">
                      <div
                           type="number"
                           class="form-control form-control-sm text-right">
                        {{ extension(line) }}
                      </div>
                    </div>
                    <div class="col">
                      <div
                           type="number"
                           class="form-control form-control-sm text-right">
                        {{ total(line) }}
                      </div>
                    </div>
                    <div class="col">
                      <button
                              @click.prevent="new_line(index)"
                              @keydown.tab="new_line(index)"
                              class="btn btn-sm btn-outline-success mr-1"
                              title="New line">
                        <corner-down-left-icon
                                               size="1.2x"
                                               class="custom-class"></corner-down-left-icon>
                      </button>
                      <button
                              @click.prevent="delete_line(line, index)"
                              class="btn btn-sm btn-outline-danger"
                              tabindex="-1"
                              title="Delete line">
                        <trash-icon
                                    size="1.2x"
                                    class="custom-class"></trash-icon>
                      </button>
                    </div>

                    <!-- <div class="col">
                      <button
                        @click.prevent="new_line(i)"
                        @keydown.tab="new_line(i)"
                        class="btn btn-sm btn-success mr-1"
                      >
                        +
                      </button>
                      <button
                        @click.prevent="delete_line(line, i)"
                        class="btn btn-sm btn-danger"
                        tabindex="-1"
                      >
                        -
                      </button>
                    </div> -->
                  </div>
                </div>
              </div>

              <!-- <div v-if="line_errors.length">
              <b>Please correct the following error(s):</b>
              <ul>
                <li v-for="error in line_errors" :key="error.line">{{ error.error }}</li>
              </ul>
            </div>-->
            </div>
            <div class="form-group col-md-12">
              <button
                      @click.prevent="enter_order"
                      class="btn btn-sm btn-outline-success mr-2">
                Save Order
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
    <!-- <OrderLines v-if="selectedName.name" :name="selectedName" filter="name" parent="order_form"></OrderLines> -->
  </basicLayout>
</template>

<script>
import { fb, db } from "@/firebaseConfig";
import algoliasearch from "algoliasearch/lite";
import { orderBy } from "lodash";
import { actions, getters } from "@/store";
import basicLayout from "@/components/basicLayout";
import ProductPopover from "@/components/ProductPopover";
import price_list_mixins from "@/mixins/price_list_mixins";
import { InfoIcon, CornerDownLeftIcon, TrashIcon } from "vue-feather-icons";

const names = db.collection("names");
const rules = db.collection("rules");

export default {
  name: "order_edit",
  data() {
    return {
      name: {},
      order: {},
      sort_column: "created",
      sort_order: "asc",
      order_lines: [
        {
          id: "",
          product: {
            id: "",
            code: "",
            label: "",
            description: "",
            sellprice_a: 0,
          },
          stock_code: "",
          description: "",
          price: 0,
          quantity: 1,
          shipped: 0,
          discount: 0,
          extension: 0,
          total: 0,
        },
      ],
      products: [],
      rules: [],
      line_errors: [],
      visible_lines: 20,
      search: "",
      searchClient: algoliasearch(
        "QDXUPVRDAC",
        "6edf124cf2acf6b7e34bc21e1b3e2964"
      ),
      algolia_hits: {},
      debounce: null,
    };
  },
  components: {
    basicLayout,
    InfoIcon,
    ProductPopover,
    CornerDownLeftIcon,
    TrashIcon,
  },
  mixins: [price_list_mixins],

  firestore() {
    return {
      order: db.collection("orders").doc(this.$route.params.order_id),
      // products: db.collection("products").where("deleted", "==", false),

      order_lines: db
        .collection("lines")
        .where("order_id", "==", this.$route.params.order_id)
        .orderBy("created"),
    };
  },
  created: async function () {
    this.index = this.searchClient.initIndex("products");
    this.algolia_hits = await this.index.search();
  },
  watch: {
    order: function (new_order) {
      console.log("order loaded");
      this.$bind("name", names.doc(new_order.name_code));
    },
    order_lines: function (new_order_lines) {
      if (new_order_lines.length) {
        console.log("order lines loaded");
        actions.setLoading(false);
      }
    },
    name: function (new_name) {
      if (new_name && new_name.has_rule) {
        this.$bind(
          "rules",
          rules.where("name_code", "in", [
            new_name.code,
            new_name.head_office,
            new_name.group,
          ])
        );
      }
    },
    search: {
      handler: async function (search) {
        console.log(search);
        this.algolia_hits = await this.index.search(search);
      },
    },
  },

  methods: {
    line_changed(line) {
      line.line_changed = true;
    },
    loadMore: function () {
      console.log("LOAD MORE");
      this.visible_lines += 50;
    },
    set_price(line, product) {
      console.log("SETTING PRICE");
      if (!product) return;
      const applied_rules = this.applied_rules;
      const product_pricing = this.sell_price;

      console.log("PRODUCT PRICING: " + product_pricing);
      console.log("APPLIED RULES: " + applied_rules);

      let prevailing_price;

      const product_rule = applied_rules.find((rule) => {
        return (
          rule.product_code === product.code || rule.product_code === "ALL"
        );
      });

      if (product_rule) {
        console.log("rule: " + product_rule);
      }

      if (
        product_rule &&
        product_rule.discount &&
        product.selldiscountmode === 2 // MoneyWorks - apply discount by customer
      ) {
        console.log("APPLY DISCOUNT");
        line.discount = product_rule.discount;
        // prevailing_price =
        //   product[product_pricing] * (1 - product_rule.discount / 100);
      } else if (product_rule && product_rule.price) {
        prevailing_price = product_rule.price;
      } else {
        prevailing_price = product[product_pricing];
      }

      console.log("SELL PRICE: " + prevailing_price);
      product.sell_price = prevailing_price;
      line.price = prevailing_price;
      this.line_changed(line);
    },

    extension(line) {
      const discount = line.discount || 0;
      return (line.price * (1 - discount / 100) || 0).toFixed(2);
    },

    total(line) {
      const discount = line.discount || 0;
      return (line.price * (1 - discount / 100) * line.quantity || 0).toFixed(
        2
      );
    },

    new_line(i) {
      console.log("i: " + i);
      console.log("this.order_lines.length: " + this.order_lines.length);

      this.order_lines.splice(i + 1, 0, {
        created: new Date(),
        order_id: this.order.id,
        po_number: this.order.po_number,
        name_code: this.order.name_code,
        name: this.order.name,
        product: {
          id: "",
          code: "",
          label: "",
          description: "",
          barcode: "",
          custom7: "", // alternate barcode
          sell_price: 0,
          sellprice_a: 0,
          rules: [],
          category1: "", // format
          custom8: "", // origin
        },
        line_sequence_number: null, // required for whl orders
        import_price: null, // required for whl orders
        stock_code: "",
        description: "",
        order_type: "",
        quantity: 1,
        allocated: 0,
        picking: 0,
        picked: 0,
        shipped: 0,
        price: 0,
        discount: 0,
        extension: 0,
        total: 0,
        is_outstanding: true,
        is_complete: false,
        locked: false,
        sent_back: false,
        line_changed: true,
      });
      // console.log(new_line);
      // const line_res = await db.collection("lines").add(new_line);
      // console.log(line_res);
      this.reset_sort();
    },
    delete_line: async function (line, index) {
      // if (this.order_lines.length === 1) {
      //   return false;
      // }
      console.log("DELETE LINE: " + line);
      console.log("LINE NUMBER: " + index);
      console.log("DELETE LINE ID: " + line.id);

      if (line.id) {
        console.log("DELETE LINE ID: " + line.id);
        if (line.picked > 0 || line.shipped > 0) {
          await db
            .collection("lines")
            .doc(line.id)
            .set({
              quantitiy: Number(line.picked) + Number(line.shipped),
              allocated: 0,
              picking: 0,
            });
        } else {
          await db.collection("lines").doc(line.id).delete();
        }
      } else {
        this.order_lines.splice(index, 1);
      }
      this.reset_sort();
    },

    check_order() {
      // CHECK THAT ALL RELEVENT FIELDS ARE FILLED IN
      if (!this.order.po_number) {
        this.$toast.error("PO Required", {
          position: "top-right",
          timeout: 5000,
        });
        return false;
      }
      if (!this.order.type) {
        this.$toast.error("Type Required", {
          position: "top-right",
          timeout: 5000,
        });
        return false;
      }
      // LOOP THROUGH LINES TO CHECK ALL VALUES ARE VALID

      this.line_errors = [];
      let line_errors = false;

      this.order_lines.forEach((o, i) => {
        if (!o.product) {
          this.line_errors.push({ line: i, error: "Product required." });
          line_errors = true;
        }
        if (o.quantity < 1) {
          this.line_errors.push({ line: i, error: "Quantity required." });
          line_errors = true;
        }
      });

      if (line_errors) {
        alert("errors");
        return false;
      }
      return true;
    },

    enter_order: async function () {
      console.log("updating");

      if (!this.check_order()) return;

      actions.setLoading(true);

      const po_number = this.order.po_number;
      const type = this.order.type;
      const last_updated = new Date();

      const order_update = {
        po_number,
        type,
        last_updated,
      };

      await db.collection("orders").doc(this.order.id).update(order_update);

      const line_products_array = [];

      const update_promises = this.order_lines
        .filter((ol) => ol.product.code && ol.quantity > 0 && ol.line_changed)
        .map(async (ol) => {
          const created = ol.created || new Date();

          const new_line = {
            order_id: this.order.id,
            po_number: this.order.po_number,
            name_code: this.order.name_code,
            name: this.order.name,
            order_type: this.order.type,
            created,
            product: {
              label: ol.product.label,
              code: ol.product.code.toString(),
              description: ol.product.description,
              deleted: ol.product.deleted || false,
              supplier: ol.product.supplier,
              barcode: ol.product.barcode,
              custom7: ol.product.custom7 || "", // alternate barcode
              category1: ol.product.category1 || "", // format
              custom8: ol.product.custom8 || "", // origin
            },
            deleted_title: ol.product.deleted || false,
            stock_code: ol.product.code,
            description: ol.product.description,
            price: Number(ol.price),
            discount: Number(ol.discount),
            quantity: Number(ol.quantity),
            category1: ol.product.category1 || "",
            supplier: ol.product.supplier || "",
            barcode: ol.product.barcode || "",
            custom7: ol.product.custom7 || "", // alternate barcode

            line_sequence_number: ol.line_sequence_number, // required for whl orders
            import_price: ol.import_price, // required for whl orders
            allocated: ol.allocated || 0,
            picking: ol.picking || 0,
            picked: ol.picked || 0,
            shipped: ol.shipped || 0,
            is_outstanding: ol.shipped < ol.quantity || true,
            locked: ol.locked || false,
            sent_back: ol.sent_back || false,
            last_update: new Date(),
          };

          line_products_array.push(ol.stock_code);

          console.log(new_line);

          if (ol.id) {
            console.log("updating line " + ol.product);
            db.collection("lines").doc(ol.id).update(new_line);
          } else {
            console.log("adding new line " + ol.product);
            db.collection("lines").add(new_line);
          }

          this.update_product(new_line.stock_code);
        });

      await Promise.all(update_promises);
      actions.setLoading(false);

      console.log("line_products_array: " + line_products_array);

      this.$router.push("/orders-by-po");
      this.$toast.success("Order Updated", {
        position: "top-right",
        timeout: 2000,
      });
    },

    update_product(stock_code) {
      const update_product = fb.httpsCallable("sync_update_product");
      update_product(stock_code).then(function (result) {
        console.log(result);
      });
    },

    lineClass(line) {
      return { "alert-primary": !line.product || line.quantity < 1 };
    },
    debounce_search(search) {
      console.log(search);
      let delay = 200;
      clearTimeout(this.debounce);
      if (search.length > 4) delay = 0;
      this.debounce = setTimeout(() => {
        this.search = search;
      }, delay);
    },
    algolia_search() {
      return this.algolia_hits.hits
        .filter(
          (product) =>
            product.colour != 7 || this.order.name_code.includes("050")
        )
        .map((product) => {
          product.label = `${product.description}  |  ${product.code} | ${product.barcode}`;
          product.id = product.objectID;
          product.stock_code = product.code;
          return product;
        });
    },

    prod_class(line) {
      if (line.colour.value == 1) return "text-orange";
      if (line.colour.value == 2) return "text-red";
      if (line.colour.value == 3) return "text-magenta";
      if (line.colour.value == 4) return "text-cyan";
      if (line.colour.value == 5) return "text-blue";
      if (line.colour.value == 6) return "text-green";
      if (line.colour.value == 7) return "text-brown";
    },

    sort_by() {
      this.sort_column = "description";
      this.sort_order = this.sort_order === "asc" ? "desc" : "asc";
    },
    reset_sort() {
      this.sort_column = "created";
      this.sort_order = "asc";
    },
    available(product) {
      return (
        (Number(product.stockonhand) || 0) -
        (Number(product.all_allocated) || 0) -
        (Number(product.all_processing) || 0)
      );
    },
  },
  computed: {
    is_loading() {
      return getters.isLoading();
    },

    order_lines_sorted() {
      return orderBy(this.order_lines, (item) => item.description || "", "asc");
    },

    filtered() {
      let fitered_lines = this.order_lines.slice();

      fitered_lines = orderBy(
        fitered_lines,
        (item) => item[this.sort_column] || "",
        this.sort_order
      );

      return fitered_lines;
    },

    // products_search() {
    //   let visible_products;

    //   if (!this.search) {
    //     return []; //visible_products.slice(0, 20);
    //   }

    //   visible_products = this.customer_price_list
    //     .slice()
    //     .filter((product) => {
    //       const s = Object.values(product);
    //       const su = s.toString().toUpperCase();
    //       return su.match(this.search.toString().toUpperCase());
    //     })
    //     .map((product) => {
    //       const description =
    //         product.description.length > 64
    //           ? product.description.slice(0, 64) + " ..."
    //           : product.description;

    //       product.label = `${description}  |  ${product.code}`;
    //       product.stock_code = product.code;
    //       return product;
    //     });

    //   return visible_products;
    // },

    // products_search_old() {
    //   let display_products;
    //   if (this.search.length) {
    //     display_products = this.customer_price_list;
    //   } else {
    //     display_products = this.customer_price_list.slice(0, 50);
    //   }
    //   return display_products.map((p) => {
    //     const description =
    //       p.description.length > 64
    //         ? p.description.slice(0, 64) + " ..."
    //         : p.description;

    //     p.label = `${description}  |  ${p.code}`;
    //     return p;
    //   });
    // },

    sell_price() {
      if (this.name && this.name.productpricing) {
        return `sellprice_${this.name.productpricing.toLowerCase()}`;
      } else {
        return "sellprice_a";
      }
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
form {
  font-size: 0.8rem;
}
</style>
