<template>
  <basicLayout>
    <h5>PRODUCTS</h5>
    <div class="row">
      <div class="col-xl-6">
        <div class="input-group input-group-sm mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text">Search...</span>
          </div>
          <input
            @input="debounce_search"
            :value="search"
            type="text"
            class="form-control"
            placeholder="Code, barcode, description, category etc." />
          <div class="input-group-append">
            <button
              @click="clear_search"
              class="btn btn-outline-secondary"
              type="button">
              Clear
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="btn-toolbar d-none d-lg-block">
      <button
        @click="verbose = !verbose"
        type="button"
        class="btn btn-sm btn-outline-secondary">
        {{ verbose ? "Less Detail" : "More Detail" }}
      </button>
      <button
        @click="toggle_new_releases()"
        type="button"
        class="btn btn-sm btn-outline-secondary ml-2">
        {{ show_new_releases ? "Show All" : "New Releases" }}
      </button>
      <button
        @click="toggle_new_releases()"
        type="button"
        class="btn btn-sm btn-outline-secondary ml-2">
        {{ show_new_releases ? "Show All" : "New Releases" }}
      </button>
    </div>

    <div class="row m-2">
      <div class="col text-right">
        <b>Showing: {{ this.filtered.length }}</b> of
        <b>{{ (this.hits && this.hits.nbHits) || 0 }}</b>
      </div>
    </div>

    <div class="row m-2">
      <div class="tableFixHead tableFixHeadLong">
        <table class="table table-bordered table-hover table-sm">
          <thead class="thead-dark">
            <tr>
              <th @click="sort_by('code')">Code</th>
              <th @click="sort_by('description')">Name</th>
              <th @click="sort_by('barcode')">Barcode</th>
              <th
                @click="sort_by('category1')"
                class="w-10">
                Format
              </th>
              <th
                @click="sort_by('custom8')"
                class="w-10">
                Origin
              </th>
              <th
                @click="sort_by('custom2')"
                class="w-5">
                Release
              </th>
              <!-- <th @click="sort_by('colour')" class="w-5">Colour</th> -->

              <th
                class="w-5"
                title="New orders for this item will be locked at 0 allocation">
                Locked
              </th>
              <th
                v-if="verbose"
                class="w-5">
                A
              </th>
              <th
                v-if="verbose"
                class="w-5">
                B
              </th>
              <th
                v-if="verbose"
                class="w-5">
                C
              </th>
              <th
                v-if="verbose"
                class="w-5">
                D
              </th>
              <th
                v-if="verbose"
                class="w-5">
                E
              </th>
              <th
                v-if="verbose"
                class="w-5">
                F
              </th>
              <th
                v-if="verbose"
                class="w-5">
                SOH
              </th>
              <th
                v-if="verbose"
                class="w-5">
                SOSO
              </th>
              <th
                v-if="verbose"
                class="w-5">
                SOPO
              </th>
              <th
                v-if="verbose"
                class="w-5">
                SALESACT
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="product in filtered"
              :key="product.id"
              :class="prod_class(product)">
              <td>{{ product.code }}</td>

              <td>
                <span
                  v-html="product._highlightResult.description.value"></span>
              </td>
              <td>{{ product.barcode }}</td>
              <td @dblclick="filter_by(product.category1)">
                {{ product.category1 }}
              </td>
              <td @dblclick="filter_by(product.custom8)">
                {{ product.custom8 }}
              </td>
              <td @dblclick="filter_by(product.custom2)">
                {{ product.custom2 | moment_shortest }}
              </td>

              <td
                @dblclick="filter_by(product.locked)"
                class="text-center">
                <div class="form-check">
                  <input
                    v-model="product.locked"
                    type="checkbox"
                    class="form-check-input"
                    :title="product.locked_by"
                    @change="lock_product(product)" />
                </div>
              </td>
              <!-- <td @dblclick="filter_by(product.colour)">{{product.colour}}</td> -->
              <td
                v-if="verbose"
                class="text-right">
                {{ product.sellprice_a | currency }}
              </td>
              <td
                v-if="verbose"
                class="text-right">
                {{ product.sellprice_b | currency }}
              </td>
              <td
                v-if="verbose"
                class="text-right">
                {{ product.sellprice_c | currency }}
              </td>
              <td
                v-if="verbose"
                class="text-right">
                {{ product.sellprice_d | currency }}
              </td>
              <td
                v-if="verbose"
                class="text-right">
                {{ product.sellprice_e | currency }}
              </td>
              <td
                v-if="verbose"
                class="text-right">
                {{ product.sellprice_f | currency }}
              </td>
              <td
                v-if="verbose"
                class="text-right">
                {{ product.stockonhand }}
              </td>
              <td
                v-if="verbose"
                class="text-right">
                {{ product.stock_on_so }}
              </td>
              <td
                v-if="verbose"
                class="text-right">
                {{ product.stock_on_po }}
              </td>
              <td
                v-if="verbose"
                class="text-right">
                {{ product.salesacct }}
              </td>

              <td>
                <button
                  @click="new_release(product)"
                  type="button"
                  class="btn btn-x-sm btn-outline-success mr-2"
                  title="New Release">
                  <activity-icon
                    size="1x"
                    class="custom-class"></activity-icon>
                </button>
                <button
                  @click="delete_item(product)"
                  type="button"
                  class="btn btn-x-sm btn-outline-danger"
                  title="Delete product from Playlist">
                  <trash-icon
                    size="1x"
                    class="custom-class"></trash-icon>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <Pagination
        v-if="pages"
        :pages="pages"
        :selected_page="selected_page"
        v-on:updatePage="updatePage" />
    </div>
  </basicLayout>
</template>

<script>
import { db, auth } from "@/firebaseConfig";
import algoliasearch from "algoliasearch";
import { orderBy } from "lodash";
// import { actions } from "@/store";
import basicLayout from "@/components/basicLayout";
import Pagination from "@/components/Pagination";
import filter_mixins from "@/mixins/filter_mixins";
import { TrashIcon, ActivityIcon } from "vue-feather-icons";

export default {
  name: "products",
  data() {
    return {
      search: "",
      filters: "deleted:false",
      verbose: false,
      show_new_releases: false,
      selected_page: 1,
      sort_column: ["description"],
      sort_order: "asc",
      debounce: null,
      searchClient: algoliasearch(
        "QDXUPVRDAC",
        "ac8789edfa720b6e991b6ea892cf40ee"
      ),
      index: {},
      hits: {},
    };
  },
  components: {
    basicLayout,
    Pagination,
    TrashIcon,
    ActivityIcon,
  },
  mixins: [filter_mixins],

  created: async function () {
    this.index = this.searchClient.initIndex("products");
    this.hits = await this.index.search("", {
      filters: this.filters,
    });
  },

  methods: {
    prod_class(line) {
      if (line.new_release) return "font-weight-bold text-mint";
      if (line.colour == 1) return "text-orange";
      if (line.colour == 2) return "text-red";
      if (line.colour == 3) return "text-magenta";
      if (line.colour == 4) return "text-cyan";
      if (line.colour == 5) return "text-blue";
      if (line.colour == 6) return "text-green";
      if (line.colour == 7) return "text-brown";
    },

    debounce_search(event) {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.search = event.target.value;
      }, 200);
    },
    clear_search() {
      console.log(this.search);
      this.search = "";
      this.selected_page = 1;
    },
    updatePage(value) {
      this.selected_page = value;
    },
    filter_by(filter) {
      this.search = filter;
    },
    sort_by(column) {
      this.sort_column = column;
      this.sort_order = this.sort_order === "asc" ? "desc" : "asc";
    },
    lock_product(product) {
      console.log(product.locked);
      console.log(product.code);

      const locked_by = (product.locked && this.currentUser.email) || null;

      console.log({ locked: product.locked, locked_by });
      db.collection("products")
        .doc(product.code)
        .update({ locked: product.locked, locked_by });
    },
    new_release(product) {
      console.log(product.new_release);
      console.log(product.code);

      console.log({ new_release: !product.new_release });
      db.collection("products")
        .doc(product.code)
        .update({ new_release: !product.new_release });
      product.new_release = !product.new_release;

      this.search = "";
      this.$forceUpdate();
    },
    toggle_new_releases() {
      console.log(this.show_new_releases);
      this.show_new_releases = !this.show_new_releases;
      if (this.show_new_releases) {
        return (this.filters = "deleted:false AND new_release:true");
      } else {
        return (this.filters = "deleted:false");
      }
    },
    delete_item: async function (product) {
      console.log(product.code);
      console.log(this.index);

      // DELETE FIRST FROM ALGOLIA INDEX

      const index_delete = await this.index.deleteObject(product.code);
      console.log("index_delete: " + index_delete);

      const clean_code = product.code.split("/").join("|");

      db.collection("products").doc(clean_code).delete();
      this.search = "";
      this.$forceUpdate();
    },
  },
  computed: {
    filtered: function () {
      let visible_products = this.hits.hits; // this.products.slice();

      if (this.sort_column) {
        visible_products = orderBy(
          visible_products,
          this.sort_column,
          this.sort_order
        );
      }

      return visible_products;
    },
    pages() {
      return this.hits.nbPages;
      // const pages_array = this.search ? this.filtered : this.products;
      // return Math.ceil(pages_array.length / 300);
    },
    search_query() {
      return {
        search: this.search,
        page: this.selected_page - 1,
        filters: this.filters,
      };
    },
    currentUser() {
      return auth.currentUser;
    },
  },
  watch: {
    search_query: {
      // immediate: true,
      handler: async function (query) {
        console.log(query);
        this.hits = await this.index.search(query.search, {
          page: query.page,
          filters: this.filters,
        });
      },
    },
  },
};
</script>

<style >
td em {
  font-style: normal;
  font-weight: bold;
  background-color: #cedcec;
}
</style>
