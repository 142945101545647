import Vue from 'vue'
import App from './App.vue'
import { actions } from '@/store'

import router from './router'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'vue-select/dist/vue-select.css'
import vSelect from 'vue-select'

import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'

import infiniteScroll from 'vue-infinite-scroll'

import { auth } from '@/firebaseConfig'
import './styles/style.css'

import { firestorePlugin } from 'vuefire'
import VTooltip from 'v-tooltip'

import LogRocket from 'logrocket'

const toast_options = {
  transition: 'Vue-Toastification__bounce',
  maxToasts: 10,
  newestOnTop: true,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: 'button',
  icon: true,
  rtl: false
}
LogRocket.init('7ls8dm/playlist')

Vue.component('v-select', vSelect)
Vue.use(Toast, toast_options)
Vue.use(VTooltip)
Vue.use(infiniteScroll)

Vue.config.productionTip = false
Vue.use(firestorePlugin)
let app

auth.onAuthStateChanged(user => {
  if (user) {
    actions.fetchCurrentUserProfile(user)
  }
  if (!app) {
    app = new Vue({
      router,
      render: h => h(App)
    }).$mount('#app')
  }
})
