export default {
  methods: {
    sort_by (column, type) {
      console.log('SORTING!!!')
      this.sort_column = column
      this.sort_type = type
      this.sort_toggle = -this.sort_toggle
    },

    sort_table (table, column, type) {
      console.log('SORTING TABLE')
      console.log('type: ' + type)
      if (type === 'string') {
        return this.sort_string(table, column)
      } else if (type === 'number') {
        console.log('sorting by number')
        return this.sort_number(table, column)
      } else if (type === 'date2') {
        console.log('sorting by date2')
        return this.sort_date_2(table, column)
      } else if (type === 'date') {
        console.log('sorting by date')
        return this.sort_date(table, column)
      } else if (type === 'can_supply') {
        console.log('sorting by can_supply')
        return this.sort_can_supply(table, column)
      }
    },

    sort_string (table, column) {
      return table.sort((a, b) => {
        const a_string = a[column] && a[column].toString().toUpperCase()
        const b_string = b[column] && b[column].toString().toUpperCase()
        if (
          a_string <
          b_string
        ) {
          return -this.sort_toggle
        }
        if (
          a_string >
          b_string
        ) {
          return this.sort_toggle
        }
        return 0
      })
    },

    sort_number (table, column) {
      // this.sort_toggle = -this.sort_toggle;
      return table.sort((a, b) => {
        const a_num = a[column]
        const b_num = b[column]
        if (a_num < b_num) {
          return -this.sort_toggle
        }
        if (a_num > b_num) {
          return this.sort_toggle
        }
        return 0
      })
    },

    sort_date (table, column) {
      return table.sort((a, b) => {
        const dateA = a[column]
        const dateB = b[column]
        if (dateA.seconds < dateB.seconds) {
          return -this.sort_toggle
        }
        if (dateA.seconds > dateB.seconds) {
          return this.sort_toggle
        }
        return 0
      })
    },

    sort_date_2 (table, column) {
      return table.sort((a, b) => {
        const dateA = a[column] && new Date(convertDate(a[column]))
        const dateB = b[column] && new Date(convertDate(b[column]))
        if (dateA < dateB) {
          return -this.sort_toggle
        }
        if (dateA > dateB) {
          return this.sort_toggle
        }
        return 0
      })

      function convertDate (date) {
        const date_array = date.split('/')
        return `${date_array[2]}/${date_array[1]}/${date_array[0]}`
      }
    },

    // sort_can_supply(table) {
    //   console.log("sorting by can_supply")
    //   return table.sort((a, b) => {
    //     const cs_a = a.line_product.stockonhand <= 0 ? Math.max(a.line_product.stockonhand, 0) : a.line_product.stockonhand - a.line_product.all_orders
    //     const cs_b = b.line_product.stockonhand <= 0 ? Math.max(b.line_product.stockonhand, 0) : b.line_product.stockonhand - b.line_product.all_orders
    //     if (
    //       cs_a <
    //       cs_b
    //     ) {
    //       console.log(this.sort_toggle)
    //       return -this.sort_toggle;
    //     }
    //     if (
    //       cs_a >
    //       cs_b
    //     ) {
    //       return this.sort_toggle;
    //     }
    //     return 0;
    //   });

    // },

    sort_can_supply (table) {
      console.log('sorting by can_supply')
      return table.sort((a, b) => {
        const cs_a = this.can_supply(a).toString()
        const cs_b = this.can_supply(b).toString()
        if (
          cs_a <
          cs_b
        ) {
          console.log(this.sort_toggle)
          return -this.sort_toggle
        }
        if (
          cs_a >
          cs_b
        ) {
          return this.sort_toggle
        }
        return 0
      })
    }
  }
}
