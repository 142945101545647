<template>
  <basicLayout>
    <h5>ORDERS BY NAME</h5>
    <div class="row">
      <div class="col-xl-3">
        <div class="input-group input-group-sm">
          <div class="input-group-prepend">
            <span class="input-group-text">Search...</span>
          </div>
          <input
            @input="debounce_search"
            :value="search"
            type="text"
            class="form-control"
            placeholder="Code or Name" />
          <div class="input-group-append">
            <button
              @click="clear_search"
              class="btn btn-outline-secondary"
              type="button">
              Clear
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row m-2">
      <div class="col text-right">
        <b>Showing: {{ this.filtered.length }}</b> of
        <b>{{ this.names.length }}</b>
      </div>
    </div>
    <div class="row m-2">
      <div class="tableFixHead tableFixHeadShort">
        <table class="table table-bordered table-hover table-sm">
          <thead class="thead-dark">
            <tr>
              <th
                @click="sort_by('code')"
                class="text-left">
                Code
              </th>
              <th
                @click="sort_by('name')"
                class="text-left">
                Name
              </th>
              <!-- <th>Total Allocated</th> -->
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="name in filtered"
              :key="name.id"
              @click="selectOne(name)"
              @dblclick="open_lines(name)"
              :class="rowClass(name)">
              <td class="text-left">{{ name.code }}</td>
              <td class="text-left">{{ name.name }}</td>
              <!-- <td class="text-right">
                {{ filtered_allocated_name(name) | currency }}
              </td> -->
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div
      v-if="selected_name.id"
      class="bottom-table">
      <hr />
      <OrderLines
        :name_id="selected_name.id"
        :name="selected_name"
        filter="name"
        parent_can_supply_filter="all"></OrderLines>
    </div>
  </basicLayout>
</template>

<script>
import { db } from "@/firebaseConfig";
import { orderBy } from "lodash";
import basicLayout from "@/components/basicLayout";
import OrderLines from "@/components/OrderLines";
import filter_mixins from "@/mixins/filter_mixins";
import orders_mixins from "@/mixins/orders_mixins";

export default {
  name: "names",
  data() {
    return {
      names: [],
      orders: [],
      lines: [],
      search: "",
      debounce: null,
      selected_name: {},
      sort_column: ["code"],
      sort_order: "asc",
      loading: false,
    };
  },
  components: { basicLayout, OrderLines },
  mixins: [filter_mixins, orders_mixins],

  firestore: {
    names: db.collection("names").where("deleted", "==", false),
    orders: db.collection("orders").where("is_outstanding", "==", true),
    lines: db.collection("lines").where("is_outstanding", "==", true),
  },

  methods: {
    selectOne(name) {
      this.selected_name = name;
    },
    open_lines(name) {
      this.$router.push({
        name: "OrderLinesByName",
        params: { name_code: name.code },
      });
    },
    rowClass(name) {
      return { "alert-primary": this.selected_name.code === name.code };
    },
    filter_by(filter) {
      this.search = filter;
    },
    debounce_search(event) {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.search = event.target.value;
      }, 600);
    },
    clear_search() {
      this.search = "";
    },
    sort_by(column) {
      this.sort_column = column;
      this.sort_order = this.sort_order === "asc" ? "desc" : "asc";
    },

    show_detail(name) {
      this.$router.push({
        name: "OrderDetail",
        params: { name_code: name.code },
      });
    },
    filtered_allocated_name(name) {
      const lines_for_name = this.lines.filter((l) => {
        return l.name_code === name.code && l.allocated > 0;
      });
      return lines_for_name
        .map(async (line) => {
          const clean_code = line.stock_code.split("/").join("|");

          const product_snap = await db.collection("products").doc(clean_code).get();

          const product = product_snap.data();
          line.product_colour = product.colour;
          console.log(line);
          return line;
        })
        .filter((line) => line.product_colour && line.product_colour != 4)
        .reduce(function (prev, cur) {
          return Number(prev) + Number(cur.allocated) * Number(cur.price);
        }, 0);
    },
  },

  computed: {
    names_with_orders() {
      const name_codes_with_orders = new Set(this.orders.map((l) => l.name_code));
      console.log(name_codes_with_orders);
      return this.names.filter((n) => {
        return name_codes_with_orders.has(n.code); // && this.all_orders_name(n.code) > 0
      });
    },

    filtered() {
      let visible_customers = this.names_with_orders.slice();
      const search = this.search;

      visible_customers = visible_customers.filter((customer) => {
        const s = Object.values(customer);
        const su = s.toString().toUpperCase();
        return su.match(search.toString().toUpperCase());
      });

      if (this.sort_column) {
        visible_customers = orderBy(visible_customers, this.sort_column, this.sort_order);
      }
      return visible_customers;
    },
  },
};
</script>

<style scoped>
</style>
