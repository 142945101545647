<template>
  <basicLayout>
    <div class="p-2">
      <h5>PRICE LIST</h5>
      <div class="row">
        <div class="col-6">
          <!-- <v-select :options="names_search" :reduce="name => name.code" @input="setName"></v-select> -->
          <v-select
            v-model="selectedName"
            @selected="(name) => set_name(name)"
            :options="names_search"
            ref="autocomplete_names"
            :selectOnTab="true"
          ></v-select>
        </div>

        <div class="col-6">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">Search...</span>
            </div>
            <input
              @input="debounce_search"
              @change="with_rules = false"
              :value="search"
              type="text"
              class="form-control"
              placeholder="PO, SO, Name, or Carrier"
            />
            <div class="input-group-append">
              <button
                @click="clear_search"
                class="btn btn-outline-secondary"
                type="button"
              >
                Clear
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="btn-toolbar">
        <a
          @click="download_csv('all', $event)"
          :disabled="this.customer_price_list.length === 0"
          class="btn btn-sm btn-outline-secondary mr-2"
          role="button"
          >Download All</a
        >
        <a
          @click="download_csv('selected', $event)"
          :disabled="this.customer_price_list.length === 0"
          class="btn btn-sm btn-outline-secondary mr-2"
          role="button"
          >Download Selected</a
        >
        <button
          @click="verbose = !verbose"
          type="button"
          class="btn btn-sm btn-outline-secondary mr-2"
        >
          {{ verbose ? "Less Detail" : "More Detail" }}
        </button>

        <button
          v-show="selectedName.has_rule"
          @click="toggle_with_rules"
          type="button"
          class="btn btn-sm btn-outline-secondary mr-2"
        >
          {{ with_rules ? "All" : "With Rules" }}
        </button>
        <!-- <router-link
          class="btn btn-sm btn-outline-secondary mr-2"
          :to="{ name: 'FeedFields'}"
        >Edit Fields</router-link> -->
      </div>
      <!-- <div class="row">{{applied_rules}}</div> -->
      <hr />
      <div class="row mb-2 mt-2">
        <div class="col">
          <p v-if="selectedName">
            <b>Code:</b>
            {{ selectedName.code }} &nbsp;&nbsp;
            <b>Price Level:</b>
            {{ selectedName.productpricing }}
          </p>
        </div>
        <div class="col text-right">
          <b>Showing: {{ this.filtered.length }}</b>
          <!-- <b>{{this.products.length}}</b> -->
          <br />
          <b>Selected: {{ this.selected.length }}</b> of
          <b>{{ this.filtered.length }}</b>
        </div>
      </div>

      <div class="tableFixHead tableFixHeadLong">
        <table class="table table-bordered table-hover table-sm">
          <thead class="thead-dark">
            <tr>
              <th>
                <input type="checkbox" v-model="selectAll" @click="select" />
              </th>
              <th @click="sort_string(products, 'code')">Code</th>
              <th @click="sort_string(products, 'description')">Name</th>
              <th @click="sort_string(products, 'category1')" class="w-10">
                Cat 1
              </th>
              <!-- <th @click="sort_string(products, 'category2')" class="w-10">
                Cat 2
              </th>
              <th @click="sort_string(products, 'category3')" class="w-10">
                Cat 3
              </th> -->
              <th
                v-if="verbose"
                @click="sort_number(products, 'product_pricing')"
                class="w-5"
              >
                Sell Price
              </th>
              <th></th>
              <th @click="sort_number(products, 'prevailing')" class="w-5">
                Prevailing
              </th>

              <th
                v-if="verbose"
                @click="sort_number(products, 'prevailing')"
                class="w-5"
              >
                Rule
              </th>
            </tr>
          </thead>
          <tbody v-for="(product, i) in filtered" :key="product.code">
            <tr :class="rowClass(product)">
              <td>
                <input
                  @click="selectOne($event, i)"
                  type="checkbox"
                  :value="product.code"
                  v-model="selected"
                />
              </td>
              <td>{{ product.code }}</td>
              <td>{{ product.description }}</td>
              <td @dblclick="filter_by(product.category1)">
                {{ product.category1 }}
              </td>
              <!-- <td @dblclick="filter_by(product.category2)">
                {{ product.category2 }}
              </td>
              <td @dblclick="filter_by(product.category3)">
                {{ product.category3 }}
              </td> -->
              <td v-if="verbose" class="text-right">
                {{ product[product_pricing] | currency }}
              </td>
              <td class="text-right" :class="price_type(product)">
                {{ product.sell_price | currency }}
              </td>
              <td @click="get_price(product)">?</td>
              <td v-if="verbose && product.rules">
                <router-link
                  :to="{
                    name: 'Rules',
                    params: { rule_id: product.rules.product_code },
                  }"
                  >{{ product.rules.name_code }}:
                  {{ product.rules.start_date }}-{{
                    product.rules.end_date
                  }}</router-link
                >
              </td>
              <td v-if="verbose && !product.rules">-</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </basicLayout>
</template>

<script>
import moment from 'moment'
import Papa from 'papaparse'
import { fb, db } from '@/firebaseConfig'
import { actions } from '@/store'
import basicLayout from '@/components/basicLayout'
import price_list_mixins from '@/mixins/price_list_mixins'
import filter_mixins from '@/mixins/filter_mixins'
import sort_mixins from '@/mixins/sort_mixins'

export default {
  name: 'pricelist',
  data () {
    return {
      names: [],
      products: [],
      rules: [],
      fields: [],
      with_rules: false,
      selectedName: {},
      search: '',
      debounce: null,
      sort_toggle: 1,
      selected: [],
      selectAll: false,
      savedIndex: null,
      verbose: false
    }
  },
  components: { basicLayout },
  mixins: [sort_mixins, filter_mixins, price_list_mixins],

  firestore: {
    rules: db.collection('rules'),
    products: db.collection('products').where('deleted', '==', false),
    names: db.collection('names')
    // fields: db.collection("feed_fields").orderBy("index")
  },

  // created() {
  //   actions.setLoading(true);
  // },
  watch: {
    // names: function (new_names) {
    //   if (new_names.length) {
    //     console.log("should stop loading products");
    //     actions.setLoading(false);
    //   }
    // },
    // selectedName: function (new_name) {
    //   if (new_name.name && !this.customer_price_list.length) {
    //     console.log("should be loading price_list");
    //     actions.setLoading(true);
    //   }
    // },
    // customer_price_list: function (new_pricebook) {
    //   if (new_pricebook.length) {
    //     console.log("should stop loading");
    //     actions.setLoading(false);
    //   }
    // },
  },

  methods: {
    get_price (product) {
      const data = {
        name_code: this.selectedName.code,
        group_code: this.selectedName.group,
        product_code: product.code
      }

      console.log(data)

      const get_product_price = fb.httpsCallable('get_product_price')
      get_product_price(data).then(function (result) {
        console.log(result)
      })
    },
    set_name (name) {
      actions.setLoading(true)
      console.log(name)
      // this.selectedName = name;
    },
    debounce_search (event) {
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.search = event.target.value
      }, 600)
    },
    toggle_with_rules () {
      this.search = ''
      this.with_rules = !this.with_rules
    },
    rowClass (product) {
      return { 'alert-primary': this.selected.includes(product.code) }
    },
    selectOne (e, index) {
      console.log(index)
      const savedIndex = this.savedIndex
      this.savedIndex = index

      if (savedIndex === null || !e.shiftKey) return
      if (index === savedIndex) return

      const subset = this.filtered
        .slice(Math.min(index, savedIndex), Math.max(index, savedIndex) + 1)
        // eslint-disable-next-line no-unused-vars
        .map(({ code, ...rule }) => {
          return code
        })

      if (e.currentTarget.checked) {
        if (!this.selected.length) {
          this.selected = subset
        } else {
          this.selected = [...new Set(this.selected.concat(subset))]
        }
      } else {
        const subset_codes = new Set(subset)
        this.selected = this.selected.filter((sel) => {
          return !subset_codes.has(sel)
        })
      }
    },
    select () {
      this.selected = []
      if (!this.selectAll) {
        for (const i in this.filtered) {
          this.selected.push(this.filtered[i].code)
        }
      }
    },
    filter_by (filter) {
      this.selected = []
      this.selectAll = false
      this.search = filter
    },
    price_type (product) {
      return product.rules && 'alert-primary'
    },

    clear_search () {
      this.selected = []
      this.selectAll = false
      this.search = ''
    },
    download_csv (selection, event) {
      // let csv_export;

      let csv_export = this.customer_price_list
        // .filter(line => {
        //   return !line.rules || line.rules.rule_type === "everyday";
        // })
        .map((line) => {
          const filtered_line = {}
          this.fields.forEach((field) => {
            filtered_line[field.display] = line[field.name]
          })
          return filtered_line
        })

      if (selection === 'selected') {
        csv_export = csv_export.filter((c) => this.selected_ids.has(c.id))
      }

      const csv = Papa.unparse({
        fields: this.csv_display_fields,
        data: csv_export,
        quotes: true
      })

      const csv_name = `${this.selectedName.code}-price-list-${moment().format(
        'DD-MM-YYYY'
      )}.csv`

      event.target.href = 'data:text/csv,' + encodeURIComponent(csv)
      event.target.download = csv_name
    }
  },
  computed: {
    // customer_products() {
    //   const products = this.products;
    //   const name = this.name;

    //   if (!name) {
    //     return [];
    //   }
    //   return products.filter((product) => {
    //     return (
    //       product.category1 !== "PRIVATE" ||
    //       product.category2.toUpperCase() === name.code.toUpperCase()
    //     );
    //   });
    // },

    // applied_rules() {
    //   // ================================================================
    //   // GET RULES APPLICABLE TO THIS NAME

    //   const name_code = this.name.code;
    //   const group_code = this.name.group;
    //   const head_office = this.name.head_office;

    //   // ================================================================
    //   // FIND HEAD-OFFICE RULES, NAME RULES AND GROUP RULES
    //   // NB CHECK HEAD-OFFICE FIRST

    //   const name_code_rules =
    //     this.rules.filter((r) => {
    //       const str_name_code = r.name_code.toString().toUpperCase();
    //       return str_name_code === head_office || str_name_code === name_code; //&& r.rule_type === "everyday";
    //     }) || [];

    //   console.log("name_code_rules: " + name_code_rules.length);

    //   // ================================================================
    //   // GET RULES APPLICABLE TO THE NAME'S GROUP

    //   const group_code_rules =
    //     (group_code &&
    //       this.rules.filter((r) => {
    //         const str_name_code = r.name_code.toString().toUpperCase();
    //         return str_name_code === group_code; //&& r.rule_type === "everyday";
    //       })) ||
    //     [];

    //   console.log("group_code_rules: " + group_code_rules.length);

    //   // ================================================================
    //   // MERGE THE RULE ARRAYS - WITH NAME RULES TAKING PRIORITY OVER GROUP RULES:

    //   // FIND UNIQUE PRODUCT CODES IN NAME RULES
    //   const product_codes = new Set(name_code_rules.map((d) => d.product_code));

    //   // FILTER GROUP RULES TO EXCLUDE THOSE UNIQUE CODES
    //   // RETURN CODE AND PRICE
    //   const merged_rules = [
    //     ...name_code_rules,
    //     ...group_code_rules.filter((r) => !product_codes.has(r.product_code)),
    //   ];
    //   return merged_rules || [];
    // },

    // customer_price_list() {
    //   const name = this.name;
    //   if (!name) return [];
    //   const customer_products = this.products;
    //   const applied_rules = this.applied_rules;

    //   const product_pricing =
    //     (name && `sellprice_${name.productpricing.toLowerCase()}`) ||
    //     "sellprice_a";

    //   console.log("product_pricing:" + product_pricing);

    //   const price_list = customer_products.map((product) => {
    //     let prevailing_price;

    //     const product_rule = applied_rules.find((rule) => {
    //       return (
    //         rule.product_code === product.code || rule.product_code === "ALL"
    //       );
    //     });

    //     if (product_rule) {
    //       console.log("rule: " + product_rule);
    //     }

    //     if (
    //       product_rule &&
    //       product_rule.discount &&
    //       product.selldiscountmode === 2 // MoneyWorks - apply discount by customer
    //     ) {
    //       prevailing_price =
    //         product[product_pricing] * (1 - product_rule.discount / 100);
    //     } else if (product_rule && product_rule.price) {
    //       prevailing_price = product_rule.price;
    //     } else {
    //       prevailing_price = product[product_pricing];
    //     }

    //     // OLD WAY WITHOUT 'DISCOUNTS'

    //     // const prevailing_price =
    //     //   (product_rule && product_rule.price) || product[product_pricing];
    //     // product.price_level;

    //     product.sell_price = prevailing_price;
    //     product.rules = product_rule;
    //     product.end_date = product_rule && product_rule.end_date;

    //     return product;
    //   });
    //   return price_list || [];
    // },

    filtered () {
      const search = this.search
      const customer_price_list = this.customer_price_list

      if (this.with_rules) {
        return this.customer_price_list.filter((p) => {
          return p.rules
        })
      }

      if (customer_price_list.length > 0 && !search) {
        return customer_price_list
      }
      return this.customer_price_list.filter((product) => {
        const s = Object.values(product)
        const su = s.toString().toUpperCase()
        if (this.search) {
          return su.match(this.search.toString().toUpperCase())
        }
        return true
      })
    },
    names_search () {
      return this.names.map((n) => {
        n.label = `${n.name} (${n.code})`
        return n
      })
    },
    product_pricing () {
      return (
        (this.selectedName &&
          this.selectedName.productpricing &&
          `sellprice_${this.selectedName.productpricing.toLowerCase()}`) ||
        'sellprice_a'
      )
    },
    csv_display_fields () {
      return this.fields.filter((f) => f.csv).map((f) => f.display)
    }
  }
}
</script>

<style scoped>
.container {
  width: 100% !important;
}
</style>
