<template>
  <basicLayout>
    <div class="p-2">
      <h5>RULES</h5>
      <div class="row">
        <div class="col-3">
          <div class="custom-file">
            <input
              type="file"
              class="custom-file-input"
              id="customFile"
              @change="file_picker"
            />
            <label class="custom-file-label" for="customFile"
              >Choose file...</label
            >
          </div>
        </div>
        <div class="col-3" v-cloak @drop.prevent="drag" @dragover.prevent>
          <input class="form-control" placeholder="...or drag file here" />
        </div>
        <div class="col-6">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">Search...</span>
            </div>
            <input
              @input="debounce_search"
              :value="search"
              type="text"
              class="form-control"
              placeholder="PO, SO, Name, or Carrier"
            />
            <div class="input-group-append">
              <button
                @click="clear_search"
                class="btn btn-outline-secondary"
                type="button"
              >
                Clear
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="btn-toolbar">
        <button
          @click="download_csv('filtered')"
          class="btn btn-sm btn-outline-secondary mr-2"
        >
          Download
        </button>
        <button
          @click="download_csv('all')"
          class="btn btn-sm btn-outline-secondary mr-2"
        >
          <loader-icon
            v-if="downloading"
            size="1.5x"
            class="custom-class icon-spinner mr-2"
          ></loader-icon>
          Download All
        </button>
      </div>
      <hr />
      <div class="row mb-2">
        <div class="col"></div>
        <div class="col text-right">
          <b>Showing: {{ this.filtered.length }}</b> of
          <b>{{ this.rules.length }}</b>
          <br />
          <b>Selected: {{ this.selected.length }}</b> of
          <b>{{ this.filtered.length }}</b>
        </div>
      </div>

      <div class="tableFixHead">
        <table class="table table-bordered table-hover table-sm">
          <thead class="thead-dark">
            <tr>
              <th>
                <input type="checkbox" v-model="selectAll" @click="select" />
              </th>
              <th @click="sort_by('name_code')">Customer</th>
              <th @click="sort_by('product_code')">Product</th>
              <th @click="sort_by('price')">Price $</th>
              <th @click="sort_by('discount')" class="w-5">Discount %</th>
              <th @click="sort_by('start_date')">Start</th>
              <th @click="sort_by('end_date')">End</th>
              <th @click="sort_by('prevailing')">Prevailing</th>
              <th></th>
              <th @click="deleteSelected" class="text-center">
                <trash-icon size="1x" class="custom-class"></trash-icon>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(rule, i) in filtered" :key="rule.id">
              <td>
                <input
                  @click="selectOne($event, i)"
                  type="checkbox"
                  :value="rule.id"
                  v-model.lazy="selected"
                />
              </td>
              <td @dblclick="filter_by(rule.name_code)">
                {{ rule.name_code }}
              </td>
              <td @dblclick="filter_by(rule.product_code)">
                {{ rule.product_code }}
              </td>
              <td class="text-right">{{ rule.price }}</td>
              <td class="text-right">{{ rule.discount }}</td>
              <td>{{ rule.start_date }}</td>
              <td>{{ rule.end_date }}</td>
              <td @dblclick="filter_by(rule.prevailing)">
                {{ rule.prevailing }}
              </td>
              <td class="text-center">
                <router-link
                  :to="{ name: 'RuleEdit', params: { rule_id: rule.id } }"
                >
                  <edit-icon size="1x" class="custom-class"></edit-icon>
                </router-link>
              </td>
              <td @click="deleteItem(rule)" class="text-center">
                <trash-icon size="1x" class="custom-class"></trash-icon>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <Pagination
        v-if="pages"
        :pages="pages"
        :selected_page="selected_page"
        v-on:updatePage="updatePage"
      />
    </div>
  </basicLayout>
</template>

<script>
import moment from 'moment'
import Papa from 'papaparse'
import { orderBy } from 'lodash'
import { fb, db } from '@/firebaseConfig'
import basicLayout from '@/components/basicLayout'
import Pagination from '@/components/Pagination'
import filter_mixins from '@/mixins/filter_mixins'
import { EditIcon, TrashIcon, LoaderIcon } from 'vue-feather-icons'

export default {
  name: 'rules',
  data () {
    return {
      rules: [],
      search: '',
      sort_column: '',
      sort_order: 'asc',
      selected: [],
      selectAll: false,
      savedIndex: null,
      files: [],
      count: 0,
      selected_page: 1,
      downloading: false
    }
  },
  components: { basicLayout, Pagination, EditIcon, TrashIcon, LoaderIcon },
  mixins: [filter_mixins],

  firestore: {
    rules: db.collection('rules') // .limit(100)
  },
  created () {
    this.search =
      this.$route.params.rule_id || this.$route.params.name_code || ''
  },

  methods: {
    updatePage (value) {
      console.log(value)
      this.search = ''
      this.selected = []
      this.selectAll = false
      this.selected_page = value
    },
    rowClass (rule) {
      return { 'alert-primary': this.selected.includes(rule.id) }
    },
    selectOne (e, index) {
      const savedIndex = this.savedIndex
      this.savedIndex = index

      if (savedIndex === null || !e.shiftKey) return
      if (index === savedIndex) return

      const subset = this.filtered
        .slice(Math.min(index, savedIndex), Math.max(index, savedIndex) + 1)
        // eslint-disable-next-line no-unused-vars
        .map(({ id, ...rule }) => {
          return id
        })

      if (e.currentTarget.checked) {
        if (!this.selected.length) {
          this.selected = subset
        } else {
          this.selected = [...new Set(this.selected.concat(subset))]
        }
      } else {
        const subset_ids = new Set(subset)
        this.selected = this.selected.filter((sel) => {
          return !subset_ids.has(sel)
        })
      }
    },
    select () {
      this.selected = []
      console.log(this.filtered.length)
      if (!this.selectAll) {
        this.filtered.forEach((order) => {
          this.selected.push(order.id)
        })
        // for (let i in this.filtered) {
        //   this.selected.push(this.orders[i].id);
        // }
      }
    },
    debounce_search (event) {
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.search = event.target.value
      }, 500)
    },
    clear_search () {
      console.log(this.search)
      this.search = ''
      this.selected = []
      this.selectAll = false
      this.selected_page = 1
    },
    sort_by (column) {
      this.sort_column = column
      this.sort_order = this.sort_order === 'asc' ? 'desc' : 'asc'
    },
    drag (event) {
      this.upload(event.dataTransfer.files[0])
      event.target.value = ''
    },
    file_picker (event) {
      console.log('file picker')
      this.upload(event.target.files[0])
      event.target.value = ''
    },
    upload (file) {
      console.log('upload')
      console.log(file.name)
      const toast = this.$toast
      const uploadRules = fb.httpsCallable('uploadRules')
      Papa.parse(file, {
        header: true,
        skipEmptyLines: 'greedy',
        dynamicTyping: true,
        /* eslint-disable no-unused-vars */
        complete (results, file) {
          console.log(JSON.stringify(results.data))
          uploadRules(results.data).then((result) => {
            console.log(result)
            console.log(result.data)
            if (result.data.success) {
              toast.success('Rules Uploaded', {
                position: 'top-right',
                timeout: 3000
              })
            } else {
              toast.error('Rules Upload Error: ' + result.data.error, {
                position: 'top-right',
                timeout: 3000
              })
            }
          })
        }
      })
    },

    filter_by (filter) {
      this.selected = []
      this.selectAll = false
      this.search = filter
    },
    deleteItem (rule) {
      this.selected = []
      this.selectAll = false
      db.collection('rules').doc(rule.id).delete()
    },
    deleteSelected () {
      this.selected.map((rule_id) => {
        console.log(rule_id)
        db.collection('rules').doc(rule_id).delete()
      })
    },
    download_csv: async function (selection) {
      let download_rules

      if (selection === 'all') {
        console.log('download all')
        this.downloading = true
        download_rules = this.rules
      } else {
        const selected_ids = new Set(this.selected)

        download_rules = this.filtered
          .filter((c) => selected_ids.has(c.objectID))
          /* eslint-disable no-unused-vars */
          .map(({ objectID, prevailing, ...rule }) => {
            console.log(rule)
            return rule
          })
      }

      if (download_rules.length === 0) return

      const csv = Papa.unparse({
        fields: [
          'id',
          'name_code',
          'product_code',
          'price',
          'discount',
          'start_date',
          'end_date'
        ],
        data: download_rules,
        quotes: true
      })
      const csv_name = moment().format('YYYY-MM-DD') + '-rules.csv'

      console.log(csv_name)
      this.downloading = false
      const anchor = document.createElement('a')
      anchor.href = 'data:text/csv,' + encodeURIComponent(csv)
      anchor.download = csv_name
      anchor.click()
    }
  },
  computed: {
    filtered () {
      let visible_rules = this.rules.slice()
      const search = this.search

      if (this.sort_column) {
        visible_rules = orderBy(
          visible_rules,
          this.sort_column,
          this.sort_order
        )
      }

      if (!search) {
        const start = (this.selected_page - 1) * 300
        const end = start + 300
        visible_rules = visible_rules.slice(start, end)
      }

      visible_rules = visible_rules.filter((rule) => {
        const s = Object.values(rule)
        const su = s.toString().toUpperCase()
        return su.match(search.toString().toUpperCase())
      })

      return visible_rules
    },
    pages () {
      return Math.ceil(this.rules.length / 300)
    }
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    search: function (newSearch) {
      if (newSearch) {
        this.selected = []
        this.selectAll = false
        this.selected_page = -1
      } else {
        this.selected_page = 1
      }
    }
  }
}
</script>

<style scoped>
.container {
  width: 100% !important;
}

@keyframes anim-rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.icon-spinner {
  display: inline-block;
  animation: anim-rotate 2s infinite linear;
}
.icon-spinner--steps {
  animation: anim-rotate 1s infinite steps(8);
}
.icon-spinner--steps2 {
  animation: anim-rotate 1s infinite steps(12);
}
</style>
<style >
td em {
  font-style: normal;
  font-weight: bold;
  background-color: #cedcec;
}
</style>
