<template>
  <div
    class="container"
    style="width: 100%">
    <div class="row alert-success p-1">
      <div class="col text-left">
        <span
          v-if="order.order_name"
          class="align-middle">
          <b>{{ order.order_name }} </b>
        </span>
        <span
          v-else
          class="align-middle">
          <b> {{ order.first_name }} {{ order.last_name }}</b>
        </span>
      </div>
    </div>
    <div class="row border-bottom">
      <div class="col text-left">Ship To</div>
      <div class="col text-right">
        {{ order.shipping_address.first_name }}
        {{ order.shipping_address.last_name }}
      </div>
    </div>
    <div class="row border-bottom">
      <div class="col text-left"><b>Items</b></div>
    </div>

    <div
      v-for="line in order.line_items"
      :key="line.id"
      class="row border-bottom">
      <div class="col text-left">
        {{ line.description }}
        <span v-if="line.variant_title">- {{ line.variant_title }}</span>
      </div>
      <div class="col text-right">{{ line.quantity }}</div>
    </div>
  </div>
</template>

<script>
// import filter_mixins from "@/mixins/filter_mixins";
export default {
  props: {
    order: Object,
  },
};
</script>

<style scoped>
.popover {
  max-width: 800px !important;
  display: block !important;
  z-index: 10000;
}

.tooltip .tooltip-inner {
  min-width: 300px;
  background: black;
  color: white;
  border-radius: 16px;
  padding: 2px;
}

.tooltip.popover .popover-inner {
  min-width: 300px;
  font-size: 0.8em;
  background: #fff;
  color: #495057;
  padding: 4px;
  border-radius: 5px;
  box-shadow: 0 5px 30px rgba(black, 0.1);
}

.tooltip.popover .popover-arrow {
  border-color: #f9f9f9;
}

.tooltip[aria-hidden="true"] {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.15s, visibility 0.15s;
}

.tooltip[aria-hidden="false"] {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.15s;
}
</style>
