<template>
  <basicLayout>
    <h5>LABEL PRINTER</h5>
    <div class="col">
      <div class="custom-control custom-switch mb-2">
        <input
          v-model="print"
          type="checkbox"
          class="custom-control-input"
          id="customSwitch1" />
        <label
          class="custom-control-label"
          for="customSwitch1"
          >Print label on scan</label
        >
      </div>
    </div>
    <div class="col-xl-9 col-sm-12">
      <div class="card">
        <div class="card-body text-center p-0">
          <h2>SCAN A BARCODE</h2>
          <barcode v-bind:value="123456789"> Show this if the rendering fails. </barcode>
        </div>
      </div>
    </div>
  </basicLayout>
</template>

<script>
import { db } from "@/firebaseConfig";
import basicLayout from "@/components/basicLayout";
import BarcodeToast from "@/components/BarcodeToast";
import ScanPopup from "@/components/ScanPopup";
import sort_mixins from "@/mixins/sort_mixins";
import filter_mixins from "@/mixins/filter_mixins";
import JSPM from "jsprintmanager/JSPrintManager";
import VueBarcode from "vue-barcode";

export default {
  name: "scan",

  data() {
    return {
      show: true,
      print: false,
      barcode: "",
      products: [],
      scanned_product: "",
    };
  },

  components: { basicLayout, barcode: VueBarcode },
  mixins: [sort_mixins, filter_mixins],

  firestore() {
    return {
      products: db.collection("products"),
    };
  },

  created() {
    window.addEventListener("keypress", this.scanner);
    JSPM.JSPrintManager.license_url = "https://playlist.rhythmethod.co.nz/api/jspm";
    JSPM.JSPrintManager.auto_reconnect = true;
    JSPM.JSPrintManager.start();
  },
  destroyed() {
    window.removeEventListener("keypress", this.scanner);
  },

  // ================================================================
  methods: {
    scanner(e) {
      console.log("E KEY: " + e.key);

      if (e.key != "Enter") {
        this.barcode = this.barcode + e.key;
        console.log(this.barcode);
      } else {
        console.log("BARCODE: " + this.barcode);
        const product = this.products.find((p) => {
          return (
            this.barcode === p.barcode || this.barcode === p.custom7 // barcode or alternate barcode
          );
        });

        console.log(product);

        const product_id = product && product.id;
        if (!product_id) {
          const content = {
            component: BarcodeToast,
            props: {
              code: this.barcode,
              description: "that barcode is not recognised",
            },
          };
          this.$toast.error(content, {
            position: "top-right",
            timeout: 3000,
          });
          console.log("that barcode is not recognised");
          this.barcode = "";
        } else {
          console.log("product_id: " + product_id);
          this.scanned_product = product;
          const content = {
            component: ScanPopup,
            props: {
              scanned_product: this.scanned_product,
            },
            listeners: {
              edit_product: () => {
                this.$router.push({
                  name: "ProductEdit",
                  params: { product_id: this.scanned_product.id },
                });
              },
            },
          };
          this.$toast.success(content, {
            position: "top-center",
            timeout: 4000,
          });

          if (this.print) {
            this.print_label(product);
          }
          this.barcode = "";
          setTimeout(function () {
            this.scanned_product = "";
          }, 3000);
        }
      }
    },
    print_label(product) {
      console.log("printing");
      console.log(product.id);
      const zpl = `

^XA
FWR
^FX GB command parameters:
^FX - width (number)
^FX - height (number)
^FX - thickness (number)
^FX - color (B/W)
^FX - rounding (number, 0-8)
^FO5,35^GB420,790,3,B,0^FS
^FO280,35^GB60,790,3,B,0^FS
^FO5,35^GB60,790,3,B,0^FS
^FO5,420^GB60,1,3,B,0^FS
^FO340,420^GB85,1,3,B,0^FS

^FO350,60^A0R,50^FD${product.code}^FS
^FO360,450^A0R,30^FD${product.supplier_code}^FS
^FO300,60^A0R,24^FD${product.description}^FS
^FO20,450^A0R,30^FD[location]^FS

^FX BC command parameters:
^FX - orientation (N/R/I/B)
^FX - height (number)
^FX - showHumanReadableText (Y/N)
^FX - showTextAbove (Y/N)
^FX - addUccCheckDigit (Y/N)
^FX - mode (A/U/D/N)
^BY3,2,150
^FO105,80^BCR^FD${product.barcode}^FS
^XZ`;
      const cpj = new JSPM.ClientPrintJob();
      cpj.clientPrinter = new JSPM.DefaultPrinter();
      cpj.printerCommands = zpl;
      cpj.sendToClient();
    },
  },
};

/*

^XA
FWR
^FX GB command parameters:
^FX - width (number)
^FX - height (number)
^FX - thickness (number)
^FX - color (B/W)
^FX - rounding (number, 0-8)
^FO5,30^GB420,790,3,B,0^FS
^FO280,30^GB60,790,3,B,0^FS
^FO220,30^GB1,790,3,B,0^FS
^FO340,515^GB85,1,3,B,0^FS
^FO280,515^GB85,1,3,B,0^FS
^FO220,515^GB60,1,3,B,0^FS

^FO350,60^A0R,50^FD[CODE]^FS
^FO350,550^A0R,50^FD[LABEL]^FS
^FO300,60^A0R,24^FD[DESCRIPTION]^FS
^FO300,550^A0R,24^FD[CAT]^FS
^FO230,60^A0R,30^FD[FORMAT]^FS
^FO230,550^A0R,30^FD[QUANTITY]^FS

^FX BC command parameters:
^FX - orientation (N/R/I/B)
^FX - height (number)
^FX - showHumanReadableText (Y/N)
^FX - showTextAbove (Y/N)
^FX - addUccCheckDigit (Y/N)
^FX - mode (A/U/D/N)
^BY3,2,150
^FO55,80^BCR^FD${product.barcode}^FS
^XZ`;

*/
</script>
