<template>
  <basicLayout>
    <h5>ORDERS FOR: {{ name.name }} ({{ name.code }})</h5>
    <div class="row mb-2">
      <div class="col-xl-6">
        <div class="input-group input-group-sm">
          <div class="input-group-prepend">
            <span class="input-group-text">Search...</span>
          </div>
          <input
                 @input="debounce_search"
                 :value="search"
                 type="text"
                 class="form-control"
                 :class="{ 'input-box': search.length }"
                 placeholder="PO, SO, Name, or Carrier" />
          <div class="input-group-append">
            <button
                    @click="clear_search"
                    class="btn btn-outline-secondary"
                    type="button">
              Clear
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="row mb-2">
      <div class="col">
        <div class="btn-toolbar">
          <button
                  @click="pdfgen"
                  class="btn btn-sm btn-secondary mr-2"
                  type="button">
            <clipboard-icon size="1.5x" class="custom-class"></clipboard-icon>
            Picking Slip
          </button>
          <button
                  @click="send_back_all"
                  :disabled="is_loading"
                  class="btn btn-sm btn-warning mr-2">
            <rewind-icon size="1.5x" class="custom-class"></rewind-icon>
            Send Back All
          </button>
          <button
                  @click="ship_order"
                  :disabled="is_loading"
                  class="btn btn-sm btn-success mr-2">
            <truck-icon size="1.5x" class="custom-class"></truck-icon>
            Ship Order
          </button>
        </div>
      </div>
      <div class="col text-right">
        <p style="font-size: 0.8em">
          CD: {{ filtered_cd }} | VINYL: {{ filtered_vinyl }} | DVD:
          {{ filtered_dvd }}| BLU-RAY: {{ filtered_blu_ray }}
        </p>
      </div>
    </div>
    <div class="row mb-2">
      <div class="tableFixHead tableFixHeadLong col" ref="pick_table">
        <table
               class="table table-bordered table-hover table-sm"
               :class="{ 'bg-search': search.length }">
          <thead class="thead-dark">
            <tr>
              <!-- <th @click="sort_by('release_priority')" class="text-left">
                Priority
              </th> -->
              <th @click="sort_by('po_number')" class="text-left">PO Number</th>
              <th @click="sort_by('order_type')" class="text-left">Type</th>
              <th @click="sort_by('category1')" class="text-left">Format</th>
              <th @click="sort_by('supplier')" class="text-left">Supplier</th>
              <th @click="sort_by('stock_code')" class="text-left">Code</th>
              <th @click="sort_by('description')" class="text-left">
                Description
              </th>
              <th @click="sort_by('barcode')" class="text-left">Barcode</th>
              <th class="text-right"></th>
              <th class="text-right">SOH</th>
              <th class="text-right">To Pick</th>
              <th class="text-right">Picked</th>
            </tr>
          </thead>
          <tbody>
            <tr
                v-for="line in filtered"
                :key="line.id"
                :class="prod_class(line)">
              <!-- <td @dblclick="filter_by(line.release_priority)">
                {{ line.release_priority }}
              </td> -->
              <td @dblclick="filter_by(line.po_number)">
                {{ line.po_number }}
              </td>
              <td @dblclick="filter_by(line.order_type)">
                {{ line.order_type }}
              </td>
              <td @dblclick="filter_by(line.category1)">
                {{ line.category1 }}
              </td>
              <td @dblclick="filter_by(line.supplier)" class="text-left">
                {{ line.supplier }}
              </td>
              <td @dblclick="filter_by(line.stock_code)" class="text-left">
                {{ line.stock_code }}
              </td>
              <td @dblclick="filter_by(line.description)" class="text-left">
                {{ line.description }}
              </td>
              <td @dblclick="filter_by(line.barcode)" class="text-left">
                {{ line.barcode }} | {{ line.custom7 }}
              </td>

              <td class="text-center w-20">
                <button
                        @click="pick_one_manually(line)"
                        type="button"
                        class="btn btn-x-sm btn-outline-success mr-2">
                  +1
                </button>
                <button
                        @click="unpick_one(line)"
                        type="button"
                        class="btn btn-x-sm btn-outline-danger mr-2">
                  - 1
                </button>
                <button
                        @click="pick_all_line(line)"
                        type="button"
                        class="btn btn-x-sm btn-outline-success mr-2">
                  All
                </button>
                <button
                        @click="unpick_all_line(line)"
                        type="button"
                        class="btn btn-x-sm btn-outline-danger mr-2">
                  Clear
                </button>
                <button
                        @click="send_back_modal(line)"
                        type="button"
                        class="btn btn-x-sm btn-outline-warning"
                        :disabled="line.picking < 1">
                  Back
                </button>
              </td>
              <td class="text-right">{{ line.soh }}</td>
              <td class="text-right">{{ line.picking }}</td>
              <td class="text-right" :class="picked_column_style(line)">
                {{ line.picked }}
              </td>
            </tr>
          </tbody>
          <tr>
            <td colspan="8" class="font-weight-bold text-right">TOTAL</td>
            <td></td>
            <td class="font-weight-bold text-right">
              {{ filtered_picking }}
            </td>
            <td class="font-weight-bold text-right">
              {{ filtered_picked }}
            </td>
          </tr>
        </table>
      </div>
    </div>

    <PickingSendBack
                     v-if="show_send_back"
                     :selected_line="selected_line"
                     v-on:close_send_back="close_send_back" />

    <PickingSendBackAll
                        v-if="show_send_back_all"
                        :order_lines="filtered"
                        v-on:close_send_back_all="close_send_back_all" />

    <!-- <PickingAddFreight
      v-if="show_add_freight"
      :freight_amount="freight_amount"
      v-on:set_freight_amount="set_freight_amount"
      v-on:close_add_freight="close_add_freight"
    /> -->
  </basicLayout>
</template>

<script>
import { fb, db } from "@/firebaseConfig";
import { orderBy } from "lodash";
import basicLayout from "@/components/basicLayout";
import PickingSendBack from "@/components/PickingSendBack";
import PickingSendBackAll from "@/components/PickingSendBackAll";
// import PickingAddFreight from "@/components/PickingAddFreight";
import BarcodeToast from "@/components/BarcodeToast";
import { actions, getters } from "@/store";
import orders_mixins from "@/mixins/orders_mixins";
import utility_mixins from "@/mixins/utility_mixins";
import pdf_mixins from "@/mixins/pdf_mixins";
import { ClipboardIcon, RewindIcon, TruckIcon } from "vue-feather-icons";

const lines = db.collection("lines");

export default {
  name: "customer_order_lines",
  data() {
    return {
      name_code: this.$route.params.name_code,
      name: {},
      order_lines: [],
      freight_amount: 0,
      free_shipping: false,
      mw_invoice: {},
      products: [],
      lines: [],
      barcode: "",
      search: "",
      selected_line: null,
      show_add_freight: false,
      show_send_back: false,
      show_send_back_all: false,
      processing: false,
      sort_column: ["supplier", "category1", "description"],
      sort_order: ["desc", "asc", "asc", "asc"],
    };
  },
  components: {
    basicLayout,
    PickingSendBack,
    PickingSendBackAll,
    ClipboardIcon,
    RewindIcon,
    TruckIcon,
  },

  mixins: [orders_mixins, utility_mixins, pdf_mixins],

  firestore() {
    return {
      // order_lines: db
      //   .collection("lines")
      //   .where("name_code", "==", this.name_code),

      name: db.collection("names").doc(this.name_code),
      // products: db.collection("products").where("all_orders", ">", 0),
    };
  },

  created() {
    window.addEventListener("keypress", this.scanner);
    this.get_products();
  },
  destroyed() {
    window.removeEventListener("keypress", this.scanner);
  },

  methods: {
    get_products: async function () {
      const productsSnapshot = await db
        .collection("products")
        .where("is_outstanding", "==", true)
        .get();

      const products = productsSnapshot.docs.map((doc) => {
        const product_data = doc.data();
        product_data.id = doc.id;
        return product_data;
      });

      console.log(products[0]);
      console.log("OUSTANDING PRODUCTS: " + products.length);

      this.products = products;
    },

    send_back_modal(line) {
      console.log("send back: " + JSON.stringify(line));
      this.selected_line = line;
      this.show_send_back = true;
    },
    send_back_all() {
      this.show_send_back_all = true;
    },
    close_send_back() {
      console.log("closing");
      this.show_send_back = false;
    },
    close_send_back_all() {
      console.log("closing");
      this.show_send_back_all = false;
    },

    scanner(e) {
      if (e.key != "Enter") {
        this.barcode = this.barcode + e.key;
        console.log(this.barcode);
      } else {
        console.log("BARCODE: " + this.barcode);
        const line = this.filtered
          .filter((l) => l.picking > 0)
          .find((l) => {
            return (
              l.barcode === this.barcode ||
              l.custom7 === this.barcode ||
              `0${l.barcode}` === this.barcode ||
              `0${l.custom7}` === this.barcode ||
              (l.barcode &&
                l.barcode.toString().substring(1) === this.barcode) ||
              (l.custom7 && l.custom7.toString().substring(1) === this.barcode)
            );
            // return l.barcode === this.barcode || l.custom7 === this.barcode; // barcode or alternate barcode
          });
        console.log(line);
        const product_id = line && line.id;
        if (!product_id) {
          console.log("that product is not in this order");
          const content = {
            component: BarcodeToast,
            props: {
              code: this.barcode,
              description: "that product is not in this order",
            },
          };
          this.$toast.error(content, {
            position: "top-right",
            timeout: 2000,
          });
          this.barcode = "";
        } else {
          const content = {
            component: BarcodeToast,
            props: {
              code: line.stock_code,
              description: line.description,
            },
          };
          this.$toast.success(content, {
            position: "top-right",
            timeout: 2000,
          });
          this.pick_one(line);
          this.barcode = "";
        }
      }
    },
    debounce_search(event) {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.search = event.target.value;
      }, 600);
    },
    filter_by(filter) {
      this.search = filter;
    },
    sort_by(column) {
      this.sort_column = column;
      this.sort_order = this.sort_order === "asc" ? "desc" : "asc";
    },
    clear_search() {
      this.search = "";
    },

    pick_one_manually(line) {
      const line_picked = { ...line };
      db.collection("lines_picked_manually").add({
        date_picked: new Date(),
        line_picked,
      });
      this.pick_one(line);
    },

    pick_one: async function (line) {
      const new_picked = Number(line.picked) + 1;
      const new_picking = Number(line.picking) - 1;
      if (line.picking === 0) {
        console.log("over picked, man");
        line.over_picked = true;
        return;
      }

      await db
        .collection("lines")
        .doc(line.id)
        .update({ picking: new_picking, picked: new_picked });
    },
    pick_all_line: async function (line) {
      const new_picked = Number(line.picked) + Number(line.picking);
      if (line.picking === 0) return;
      await db
        .collection("lines")
        .doc(line.id)
        .update({ picking: 0, picked: new_picked });
    },
    unpick_one: async function (line) {
      const new_picked = Number(line.picked) - 1;
      const new_picking = Number(line.picking) + 1;

      if (line.picked === 0) {
        line.over_picked = false;
        return;
      }
      await db
        .collection("lines")
        .doc(line.id)
        .update({ picking: new_picking, picked: new_picked });
    },
    unpick_all_line: async function (line) {
      const new_picking = Number(line.picked) + Number(line.picking);
      if (line.picked === 0) return;

      await db
        .collection("lines")
        .doc(line.id)
        .update({ picking: new_picking, picked: 0 });
    },
    picked_column_style(line) {
      return line.picking === 0 && line.picked > 0
        ? "text-white bg-success text-right font-weight-bold"
        : "text-right font-weight-bold";
    },

    // ================================================================
    // PRE-CHECK ITEMS BEFORE INVOICING / SHIPPING

    pre_check_order() {
      console.log(this.filtered_picking);

      if (this.filtered_picked < 1) {
        this.$toast.error(
          "Whoa there, cowboy.  You haven't picked anything yet",
          {
            timeout: 5000,
          }
        );
        return false;
      }

      // if (this.filtered_picking > 0 && !this.all_priority_one) {
      //   this.$toast.error(
      //     "Hold on there, poncho. There are unpicked items in this order.  Either pick them or send them back",
      //     { timeout: 5000 }
      //   );
      //   return false;
      // }
      return true;
    },

    ship_order: async function () {
      console.log("shipping order");
      actions.setLoading(true);

      const pc = this.pre_check_order();
      if (!pc) {
        console.log("PC IS FALSE");
        actions.setLoading(false);
        return;
      }

      try {
        const starshipit_res_data = await this.create_starshipit_order();
        const starshipit_res = starshipit_res_data.data;

        if (starshipit_res.success) {
          const ssid = starshipit_res.ssid;
          console.log("SSID: " + ssid);
          await this.create_shipment(ssid);
          this.$router.push("/shipped");
          this.$toast.success("Order Shipped", {
            position: "top-right",
            timeout: 2000,
          });
        } else {
          this.$toast.error("Order did not Ship: " + starshipit_res.error, {
            position: "top-right",
            timeout: 2000,
          });
        }
        return;
      } catch (err) {
        console.log("our function didn't finish on time");
      } finally {
        actions.setLoading(false);
      }
    },

    create_starshipit_order: async function () {
      const starshipit_data = {
        name_code: this.name_code,
      };
      const starshipit_create = fb.httpsCallable("starshipit_create");
      const starshipit_res = await starshipit_create(starshipit_data);
      return starshipit_res;
    },

    create_shipment: async function (ssid) {
      const order_lines = this.filtered
        .filter((line) => {
          return (line.picked > 0 && line.picking < 1) || line.priority === 1;
        })
        .map((line) => {
          const shipment_line = {
            id: line.id,
            order_id: line.order_id,
            po_number: line.po_number,
            job_code: line.po_line_number || line.po_number,
            stock_code: line.stock_code,
            description: line.description,
            price: line.price,
            discount: line.discount || 0,
            shipment_quantity: line.picked,
            total_shipped: line.shipped,
            total_ordered: line.quantity,
            order_type: line.order_type,
            origin: line.product.custom8,
            format: line.product.category1,
          };
          return shipment_line;
        });

      const shipment_data = {
        name_code: this.name_code,
        name: this.name.name,
        ssid: ssid,
        order_lines: order_lines,
      };

      console.log("SHIPMENT DATA: " + shipment_data);

      const shipment_create = fb.httpsCallable("shipment_create");
      const shipment_res = await shipment_create(shipment_data);
      return shipment_res;
    },
    prod_class(line) {
      if (line.line_product) {
        if (line.line_product.new_release) return "font-weight-bold text-mint";
        if (line.line_product.colour == 1) return "text-orange";
        if (line.line_product.colour == 2) return "text-red";
        if (line.line_product.colour == 3) return "text-magenta";
        if (line.line_product.colour == 4) return "text-cyan";
        if (line.line_product.colour == 5) return "text-blue";
        if (line.line_product.colour == 6) return "text-green";
        if (line.line_product.colour == 7) return "text-brown";
      }
    },
  },

  computed: {
    is_loading() {
      return getters.isLoading();
    },

    lines_processing() {
      return this.order_lines.filter((line) => {
        return line.picking + line.picked > 0 && line.quantity > line.shipped;
      });
    },

    all_priority_one() {
      const picked_lines = this.order_lines.filter((line) => {
        return line.picking === 0 && line.picked > 0;
      });
      return (
        picked_lines.length > 0 &&
        picked_lines.every((line) => {
          return line.release_priority === 1;
        })
      );
    },

    filtered() {
      let filtered_lines = this.lines_processing.slice();
      const search = this.search.toString().toUpperCase();

      filtered_lines = filtered_lines
        .filter((line) => {
          const s = Object.values(line);
          const su = s.toString().toUpperCase();
          return su.match(search);
        })
        .map((line) => {
          if (line.supplier.includes("RHYTHMETHOD")) line.supplier = "RM";
          line.line_id = line.id;
          const product = this.products.find((p) => p.code == line.stock_code);
          line.soh = product && product.stockonhand;
          return line;
        });

      if (this.sort_column) {
        filtered_lines = orderBy(
          filtered_lines,
          this.sort_column,
          this.sort_order
        );
      }
      return filtered_lines;
    },

    filtered_cd() {
      return this.filtered
        .filter((line) => line.category1.includes("CD"))
        .reduce(function (prev, cur) {
          return Number(prev) + Number(cur.picking) + Number(cur.picked);
        }, 0);
    },
    filtered_vinyl() {
      return this.filtered
        .filter((line) => line.category1.includes("VINYL"))
        .reduce(function (prev, cur) {
          return Number(prev) + Number(cur.picking) + Number(cur.picked);
        }, 0);
    },
    filtered_dvd() {
      return this.filtered
        .filter((line) => line.category1.includes("DVD"))
        .reduce(function (prev, cur) {
          return Number(prev) + Number(cur.picking) + Number(cur.picked);
        }, 0);
    },
    filtered_blu_ray() {
      return this.filtered
        .filter((line) => line.category1.includes("BLU-RAY"))
        .reduce(function (prev, cur) {
          return Number(prev) + Number(cur.picking) + Number(cur.picked);
        }, 0);
    },

    invoice_lines() {
      return this.filtered.filter((line) => {
        return line.picked > 0;
      });
    },
  },

  watch: {
    name_code: {
      immediate: true,
      handler(name_code) {
        if (name_code) {
          this.$bind(
            "order_lines",
            lines
              .where("is_outstanding", "==", true)
              .where("name_code", "==", name_code)
          );
        }
      },
    },
  },
};
</script>

<style scoped>
.bg-search {
  background-color: rgb(224, 219, 255, 0.5);
}

input.input-box,
textarea {
  background: rgb(224, 219, 255, 0.5);
}
</style>
