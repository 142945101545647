<template>
  <basicLayout>
    <div class="row">
      <div class="col-8">
        <h5>STARSHIPIT UPLOAD</h5>
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-6 form-group">
                <div class="custom-file">
                  <input
                    @change="file_picker"
                    type="file"
                    class="custom-file-input"
                    id="customFile"
                  />
                  <label class="custom-file-label" for="customFile"
                    >Choose file</label
                  >
                </div>
              </div>
              <div class="col-6 form-group">
                <div v-cloak @drop.prevent="drag" @dragover.prevent>
                  <input
                    class="form-control progress-bar-striped alert-dark"
                    placeholder="...or drag file here"
                  />
                </div>
              </div>
            </div>
            <div v-if="upload_file.length">
              <div class="form-group">
                <label for="starshipit_template">Saved Templates</label>
                <v-select
                  v-model="starshipit_template"
                  :options="template_options"
                  :selectOnTab="true"
                  :clearable="false"
                ></v-select>
              </div>
              <hr />

              <div
                v-for="field in starshipit_template.field_map"
                :key="field.id"
              >
                <div class="form-group">
                  <label :for="field.label">{{ field.label }}</label>
                  <v-select
                    placeholder="maps to..."
                    v-model="field.maps_to"
                    multiple
                    :options="upload_fields"
                    :selectOnTab="true"
                  ></v-select>
                </div>
              </div>
            </div>
          </div>

          <div
            v-if="upload_file.length && !starshipit_template.id"
            class="card-footer"
          >
            <p>Save this Template As</p>
            <form class="form-inline">
              <label class="sr-only" for="template_name">Name</label>
              <input
                v-model="starshipit_template.label"
                type="text"
                class="form-control mb-2 mr-sm-2"
                id="template_name"
                placeholder="Template Name"
              />
              <button
                @click.prevent="save_template"
                type="submit"
                class="btn btn-secondary mb-2"
              >
                Save
              </button>
            </form>
          </div>
          <div
            v-if="upload_file.length && starshipit_template.id"
            class="card-footer"
          >
            <button
              @click.prevent="update_template"
              type="submit"
              class="btn btn-secondary mb-2 text-right"
            >
              Update Template
            </button>
          </div>
          <div v-if="upload_file.length" class="card-footer text-right">
            <button
              @click.prevent="process_order"
              type="submit"
              class="btn btn-outline-success"
            >
              Preview Upload
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="upload_file.length && starshipit_array.length" class="row mt-2">
      <div class="col-8">
        <div class="card">
          <div class="card-body">
            <h6>PREVIEW</h6>
            <div class="tableFixHead tableFixHeadShort">
              <table class="table table-bordered table-hover table-sm">
                <thead class="thead-dark">
                  <tr>
                    <th class="text-left">Order No</th>
                    <th class="text-left">Order Ref</th>
                    <th class="text-left">Name</th>
                    <th class="text-left">Company</th>
                    <th class="text-left">Street</th>
                    <th class="text-left">Suburb</th>
                    <th class="text-left">City</th>
                    <th class="text-left">State</th>
                    <th class="text-left">Postcode</th>
                    <th class="text-left">Country</th>
                    <th class="text-left">Items</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="line in starshipit_array" :key="line.order_number">
                    <td class="text-left">{{ line.order_number }}</td>
                    <td class="text-left">{{ line.reference }}</td>
                    <td class="text-left">{{ line.destination.name }}</td>
                    <td class="text-left">{{ line.destination.company }}</td>
                    <td class="text-left">{{ line.destination.street }}</td>
                    <td class="text-left">{{ line.destination.suburb }}</td>
                    <td class="text-left">{{ line.destination.city }}</td>
                    <td class="text-left">{{ line.destination.state }}</td>
                    <td class="text-left">{{ line.destination.post_code }}</td>
                    <td class="text-left">{{ line.destination.country }}</td>
                    <td>
                      <ul
                        v-for="item in line.items"
                        :key="item.sku"
                        class="list-unstyled p-0 m-0"
                      >
                        <li>
                          <b>[{{ item.sku }}]</b> {{ item.description }} x{{
                            item.quantity
                          }}
                        </li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="card-footer text-right">
            <button
              @click.prevent="starshipit_submit"
              type="submit"
              class="btn btn-outline-success"
            >
              Upload
            </button>
          </div>
        </div>
      </div>
    </div>
  </basicLayout>
</template>

<script>
import basicLayout from '@/components/basicLayout'

import Papa from 'papaparse'
import { db } from '@/firebaseConfig'
import { starshipit_request } from '@/mixins/starshipit_request'

const default_template = {
  id: '',
  default: true,
  label: 'Default',
  field_map: [
    { id: 'key', label: 'Key (required for multi-line orders)', maps_to: '' },
    { id: 'order_number', label: 'Order Number', maps_to: '' },
    { id: 'reference', label: 'Order Ref', maps_to: '' },
    { id: 'destination_name', label: 'Destination Name', maps_to: '' },
    {
      id: 'destination_company',
      label: 'Destination Company',
      maps_to: ''
    },
    {
      id: 'destination_street',
      label: 'Destination Street',
      maps_to: ''
    },
    {
      id: 'destination_suburb',
      label: 'Destination Suburb',
      maps_to: ''
    },
    { id: 'destination_city', label: 'Destination City', maps_to: '' },
    { id: 'destination_state', label: 'Destination State', maps_to: '' },
    {
      id: 'destination_postcode',
      label: 'Destination Postcode',
      maps_to: ''
    },
    {
      id: 'destination_country',
      label: 'Destination Country',
      maps_to: ''
    },
    {
      id: 'items_sku',
      label: 'Items SKU',
      maps_to: ''
    },
    {
      id: 'items_description',
      label: 'Items Desciption',
      maps_to: ''
    },
    {
      id: 'items_quantity',
      label: 'Items Quantity',
      maps_to: ''
    }
  ]
}
export default {
  name: 'order_upload',
  data: function () {
    return {
      upload_file: {},
      upload_fields: [],
      starshipit_templates: [{ label: '', field_map: [] }],
      starshipit_template: default_template,
      starshipit_array: []
    }
  },
  firestore () {
    return {
      starshipit_templates: db.collection('starshipit_templates')
    }
  },
  components: {
    basicLayout
  },
  methods: {
    drag (event) {
      this.upload(event.dataTransfer.files[0])
      event.target.value = ''
    },
    file_picker (event) {
      console.log('file picker')
      this.upload(event.target.files[0])
      event.target.value = ''
    },
    upload (file) {
      const pre_process_upload = this.pre_process_upload
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        dynamicTyping: true,
        complete (results) {
          pre_process_upload(results)
        }
      })
    },

    pre_process_upload: function (upload_data) {
      console.log('pre-processing file')
      console.log(upload_data.meta.fields)
      this.upload_fields = upload_data.meta.fields
      this.upload_file = upload_data.data
    },

    process_order: async function () {
      const field_map = new Map(
        this.starshipit_template.field_map.map((i) => [i.id, i.maps_to])
      )

      const starshipit_array = this.upload_file
        .map((line) => {
          function join_fields (field_id) {
            let join = ' '
            if (field_id === 'key') {
              join = '_'
            }
            return (
              field_id &&
              field_map.get(field_id) &&
              field_map
                .get(field_id)
                .map((f) => line[f])
                .join(join)
            )
          }

          const starshipit_object = {
            key: join_fields('key'),
            order_date: new Date(),
            order_number: join_fields('order_number'),
            reference: join_fields('reference'),
            destination: {
              name: join_fields('destination_name'),
              company: join_fields('destination_company'),
              email: join_fields('destination_email'),
              street: join_fields('destination_street'),
              building: join_fields('destination_building'),
              suburb:
                join_fields('destination_suburb') ||
                join_fields('destination_city'),
              city: join_fields('destination_city'),
              state: join_fields('destination_state'),
              post_code: join_fields('destination_postcode'),
              country: join_fields('destination_country') || 'NZ'
            },
            items: [
              {
                sku: join_fields('items_sku'),
                description: join_fields('items_description'),
                quantity: join_fields('items_quantity')
              }
            ]
          }
          return starshipit_object
        })

        .reduce((result, line) => {
          const existing = result.find((r) => r.key === line.key)
          if (existing) {
            const items = {
              sku: line.items[0].sku,
              description: line.items[0].description,
              quantity: line.items[0].quantity
            }
            existing.items.push(items)
          } else {
            result.push(line)
          }
          return result
        }, [])

      this.starshipit_array = starshipit_array
    },

    starshipit_submit: async function () {
      const data = { orders: this.starshipit_array }
      const method = 'POST'
      const url = 'orders/import'
      const options = {
        url,
        method,
        data
      }
      const new_order_res = await starshipit_request(options)

      if (new_order_res.success) {
        this.$toast.success('Order Uploaded', {
          position: 'top-right',
          timeout: 2000
        })
      } else {
        const err = new_order_res.errors
          .map((err) => err.message + ': ' + err.details)
          .join('\n')
        this.$toast.error(err, {
          position: 'top-right',
          timeout: 5000
        })
      }

      console.log(new_order_res)
    },

    save_template: async function () {
      if (this.starshipit_template.label === 'Default') {
        alert("Don't name your saved template 'Default'")
        return
      }
      db.collection('starshipit_templates').add({
        created: new Date(),
        label: this.starshipit_template.label,
        field_map: this.starshipit_template.field_map
      })
    },

    update_template: async function () {
      db.collection('starshipit_templates')
        .doc(this.starshipit_template.id)
        .update({
          updated: new Date(),
          field_map: this.starshipit_template.field_map
        })
    }
  },
  computed: {
    template_options () {
      const template_array = [...this.starshipit_templates]
      template_array.push(default_template)
      return template_array
    }
  }
}
</script>
