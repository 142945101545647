<template>
  <basicLayout>
    <!-- https://bootstrapious.com/p/bootstrap-sidebar -->
    <h5>DASHBOARD</h5>

    <div>
      <!-- <div class="row">
        <div class="col-xl-3 p-3">
          <div class="card alert-success">
            <div class="card-body card-sm">
              <h6>OUTSTANDING - ALL</h6>
              <h3>{{ lines_all.length }}</h3>
              <h6>AVERAGE AGE: {{ filtered_age.toFixed(0) }} DAYS</h6>
            </div>
          </div>
        </div>
        <div class="col-xl-3 p-3">
          <div class="card alert-warning">
            <div class="card-body card-sm">
              <h6>OUTSTANDING - SOME</h6>
              <h3>{{ lines_some.length }}</h3>
              <h6>AVERAGE AGE: {{ filtered_age.toFixed(0) }} DAYS</h6>
            </div>
          </div>
        </div>
        <div class="col-xl-3 p-3">
          <div class="card alert-danger">
            <div class="card-body card-sm">
              <h6>OUTSTANDING - NONE</h6>
              <h3>{{ lines_none.length }}</h3>
              <h6>AVERAGE AGE: {{ filtered_age.toFixed(0) }} DAYS</h6>
            </div>
          </div>
        </div>
        <div class="col-xl-3 p-3">
          <div class="card alert-secondary">
            <div class="card-body card-sm">
              <h6>OUTSTANDING - TOTAL</h6>
              <h3>{{ lines.length }}</h3>
              <h6>AVERAGE AGE: {{ filtered_age.toFixed(0) }} DAYS</h6>
            </div>
          </div>
        </div>
      </div> -->
      <div class="row">
        <!-- <div class="card-group"> -->
        <div class="col-xl-4">
          <TickerSync />
        </div>
        <div class="col-xl-4">
          <TickerMoneyWorksSync />
        </div>

        <div class="col-xl-4">
          <Ticker />
        </div>

        <div class="col-xl-12">
          <Logs></Logs>
        </div>
      </div>
      <!-- </div> -->
      <div class="row">
        <div class="col-6">
          <div class="card mb-4 shadow-sm">
            <div class="card-header">
              <h5 class="my-0 font-weight-normal">BULK UPLOADS</h5>
            </div>
            <div class="card-body">
              <div class="custom-file">
                <input
                  type="file"
                  class="custom-file-input"
                  id="customFile"
                  @change="upload" />
                <label
                  class="custom-file-label"
                  for="customFile"
                  >Choose file...</label
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </basicLayout>
</template>

<script>
// import moment from "moment";
import { db, storage } from "@/firebaseConfig";
import moment from "moment";
import basicLayout from "@/components/basicLayout";
import Ticker from "@/components/Ticker";
import TickerSync from "@/components/TickerSync";
import TickerMoneyWorksSync from "@/components/TickerMoneyWorksSync";
import Logs from "@/components/Logs";
import { log } from "../mixins/log";
// import { doform } from "../mixins/utility_mixins";

export default {
  name: "rules",
  data() {
    return {
      logs: [],
      // lines: [],
      // orders: [],
      // products: [],
      xml: "",
    };
  },
  components: { basicLayout, Logs, Ticker, TickerSync, TickerMoneyWorksSync },
  firestore: {
    // orders: db.collection("bc_orders"),
    // lines: db.collection("lines").where("is_outstanding", "==", true),
    // products: db.collection("products").where("all_orders", ">", 0),
    logs: db.collection("logs").orderBy("time", "desc").limit(200),
  },
  methods: {
    log,
    log_class(log) {
      if (log.type == "info") return "text-secondary";
      if (log.type == "error") return "text-danger";
    },

    upload(event) {
      console.log("uploading...");
      const file = event.target.files[0];
      const storageRef = storage.ref(file.name);
      storageRef.put(file);
    },
  },
  computed: {
    lines_all() {
      const lines_all = this.filtered.filter(
        (line) =>
          line.line_product &&
          line.line_product.stockonhand >= line.line_product.all_orders
      );
      return lines_all;
    },
    lines_some() {
      const lines_all = this.filtered.filter(
        (line) =>
          line.line_product &&
          line.line_product.stockonhand < line.line_product.all_orders &&
          line.line_product.stockonhand > 0
      );
      return lines_all;
    },
    lines_none() {
      const lines_all = this.filtered.filter(
        (line) => line.line_product && line.line_product.stockonhand < 1
      );
      return lines_all;
    },
    filtered() {
      let filtered_lines = this.lines.slice();

      filtered_lines = filtered_lines.map((line) => {
        const product = this.products.find((p) => p.code === line.stock_code);
        line.line_product = product;
        return line;
      });
      return filtered_lines;
    },

    filtered_age() {
      const now = moment();
      return (
        this.filtered
          .filter((line) => line.created)
          .reduce(function (prev, cur) {
            const days = now.diff(cur.created.toDate(), "days");
            return Number(prev) + Number(days);
          }, 0) / this.filtered.length
      );
    },
  },
};
</script>

<style scoped>
table {
  font-size: 0.8rem;
}
</style>
