// import moment from "moment";
import { auth } from '@/firebaseConfig'

export default {
  methods: {
    get_form: async function (seq) {
      console.log('GET FORM')
      let token = await auth.currentUser.getIdToken()

      // .then(authToken => {
      //   console.log(authToken)
      let headers = new Headers({ Authorization: 'Bearer ' + token })

      const url = `https://playlist.rhythmethod.co.nz/api/forms/${seq}`

      let res = await fetch(url, {
        method: 'GET',
        responseType: 'blob',
        headers: headers
      })

      console.log(res)

      let res_blob = await res.blob()

      console.log(res_blob)
      let res_url = URL.createObjectURL(res_blob)
      console.log(res_url)


      window.open(res_url)
      // .then(response => response.blob())
      // .then(data => window.open(URL.createObjectURL(data)))
      // })
    }
  }
}
