<template>
  <basicLayout>
    <OrderLines
      v-if="name.id"
      :name="name"
      filter="name"
      origin="OrdersByName"
      parent_can_supply_filter="all"></OrderLines>
  </basicLayout>
</template>

<script>
import { db } from "@/firebaseConfig";
import basicLayout from "@/components/basicLayout";
import OrderLines from "@/components/OrderLines";

const names = db.collection("names");

export default {
  name: "orderlinesbyname",
  data() {
    return {
      name_code: this.$route.params.name_code,
      name: {},
    };
  },

  components: { basicLayout, OrderLines },
  watch: {
    name_code: {
      immediate: true,
      handler(name_code) {
        if (name_code) {
          this.$bind("name", names.doc(name_code));
        }
      },
    },
  },
};
</script>
