<template>
  <basicLayout>
    <div class="p-2">
      <h5>DROPSHIP STORES</h5>
      <div class="row">
        <div class="col-6">
          <div class="input-group input-group-sm mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">Search...</span>
            </div>
            <input
              :value="search"
              type="text"
              class="form-control"
              placeholder="PO, SO, Name, or Carrier"
            />
            <div class="input-group-append">
              <button
                @click="clear_search"
                class="btn btn-outline-secondary"
                type="button"
              >
                Clear
              </button>
            </div>
          </div>
        </div>
        <div class="col-6">
          <!-- <router-link
            class="btn btn-sm btn-outline-secondary mr-1"
            :to="{ name: 'DropShipUpload' }"
            title="Import Dropship Orders"
          >
            Upload Orders
          </router-link> -->
        </div>
      </div>

      <hr />
      <div class="row mb-2">
        <div class="col text-right">
          <b>Showing: {{ this.filtered.length }}</b> of
          <b>{{ this.stores.length }}</b>
        </div>
      </div>

      <div class="row">
        <div class="tableFixHead tableFixHeadShort">
          <table class="table table-bordered table-hover table-sm">
            <thead class="thead-dark">
              <tr>
                <th @click="sort_by('id', 'string')">Store</th>
                <th @click="sort_by('domain', 'string')">Domain</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="store in filtered"
                :key="store.id"
                :class="row_class(store)"
                @click="select_one(store)"
              >
                <td>{{ store.id }}</td>
                <td>{{ store.domain }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </basicLayout>
</template>

<script>
import { db } from '@/firebaseConfig'
import basicLayout from '@/components/basicLayout'
import filter_mixins from '@/mixins/filter_mixins'
import sort_mixins from '@/mixins/sort_mixins'

export default {
  name: 'shopify_stores',
  data () {
    return {
      search: '',
      stores: [],
      selected_store: {}
    }
  },
  components: {
    basicLayout
  },
  mixins: [filter_mixins, sort_mixins],

  firestore: {
    stores: db.collection('shopify_auth')
  },

  methods: {
    row_class (store) {
      return { 'alert-primary': this.selected_store.id === store.id }
    },
    select_one (store) {
      this.selected_store = store
    },

    filter_by (filter) {
      this.selected = []
      this.selectAll = false
      this.search = filter
    },
    clear_search () {
      this.selected_store = {}
      this.selectAll = false
      this.search = ''
    }
  },
  computed: {
    filtered () {
      const filtered_array = this.stores.filter((store) => {
        const s = Object.values(store)
        const su = s.toString().toUpperCase()
        return su.match(this.search.toString().toUpperCase())
      })
      return filtered_array
    }
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    search: function (newSearch) {
      if (newSearch) {
        this.selected_store = {}
        this.selected_page = -1
      } else {
        this.selected_page = 1
      }
    }
  }
}
</script>

<style scoped>
</style>
