<template>
  <basicLayout>
    <h5>SHIPMENTS</h5>
    <div class="row">
      <div class="col-xl-6">
        <div class="input-group input-group-sm">
          <div class="input-group-prepend">
            <span class="input-group-text">Search...</span>
          </div>
          <input
                 @input="debounce_search"
                 :value="search"
                 type="text"
                 class="form-control"
                 placeholder="Seq Number, Code, Name" />
          <div class="input-group-append">
            <button
                    @click="clear_search"
                    class="btn btn-outline-secondary"
                    type="button">
              Clear
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="row m-2">
      <div class="tableFixHead">
        <table class="table table-bordered table-hover table-sm">
          <thead class="thead-dark">
            <tr>
              <th @click="sort_by('ssid')" class="text-left">SSID</th>
              <th @click="sort_by('name_code')" class="text-left">Name Code</th>
              <th @click="sort_by('name')" class="text-left">Name</th>
              <th class="text-left">Date</th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="shipment in filtered" :key="shipment.id">
              <td class="text-left">
                {{ shipment.ssid }}
              </td>
              <td @dblclick="filter_by(invoice.name_code)" class="text-left">
                {{ shipment.name_code }}
              </td>
              <td @dblclick="filter_by(invoice.name)" class="text-left">
                {{ shipment.name }}
              </td>
              <td class="text-left">{{ shipment.created | moment_short }}</td>
              <td class="text-center">
                <button
                        @click="see_lines(shipment.ssid)"
                        type="button"
                        class="btn btn-x-sm btn-outline-success mr-2">
                  <list-icon size="1.0x" class="custom-class"></list-icon>
                  See Lines
                </button>
              </td>
              <td class="text-center">
                <button
                        v-if="!shipment.mw_seq"
                        @click="see_lines(shipment.ssid)"
                        type="button"
                        class="btn btn-x-sm btn-outline-success mr-2">
                  <list-icon size="1.0x" class="custom-class"></list-icon>
                  Invoice Shipment
                </button>
                <!-- {{ shipment.mw_seq }} -->
                <button
                        v-if="shipment.mw_seq"
                        @click="get_form(shipment.mw_seq)"
                        class="btn btn-x-sm btn-outline-success mr-2"
                        type="button">
                  <file-text-icon
                                  size="1.0x"
                                  class="custom-class"></file-text-icon>
                  Packing Slip
                </button>
              </td>

              <td v-if="shipment.ssid" class="text-center">
                <button
                        @click="track_order(shipment)"
                        class="btn btn-x-sm btn-outline-success mr-2"
                        type="button">
                  <compass-icon size="1.0x" class="custom-class"></compass-icon>
                  Track
                  {{ shipment.ssid }}
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <Pagination
                  v-if="pages"
                  :pages="pages"
                  :selected_page="selected_page"
                  v-on:updatePage="updatePage" />
    </div>
  </basicLayout>
</template>

<script>
import { fb, db } from "@/firebaseConfig";
import { orderBy } from "lodash";
import basicLayout from "@/components/basicLayout";
import filter_mixins from "@/mixins/filter_mixins";
import sort_mixins from "@/mixins/sort_mixins";
import utility_mixins from "@/mixins/utility_mixins";
import Pagination from "@/components/Pagination";
import { ListIcon, FileTextIcon, CompassIcon } from "vue-feather-icons";

export default {
  name: "shipments",
  data() {
    return {
      shipments: [],
      search: "",
      debounce: null,
      sort_column: ["code"],
      sort_order: "asc",
      page_limit: 300,
      selected_page: 1,
    };
  },
  components: {
    basicLayout,
    Pagination,
    ListIcon,
    FileTextIcon,
    CompassIcon,
  },
  mixins: [filter_mixins, sort_mixins, utility_mixins],

  firestore() {
    return {
      shipments: db
        .collection("shipments")
        .orderBy("ssid", "desc")
        .limit(100),
    };
  },
  methods: {
    debounce_search(event) {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.search = event.target.value;
      }, 200);
    },
    see_lines(ssid) {
      this.$router.push({
        name: "ShippedLines",
        params: { ssid: ssid },
      });
    },
    filter_by(filter) {
      this.search = filter;
    },
    sort_by(column) {
      this.sort_column = column;
      this.sort_order = this.sort_order === "asc" ? "desc" : "asc";
    },
    clear_search() {
      this.search = "";
    },
    updatePage(value) {
      this.selected_page = value;
      this.clear_search();
    },

    track_order: async function (shipment) {

      const { order_id, ssid } = shipment

      if (order_id === ssid) {

        this.$toast.warning("Order was not shipped via courier", {
          position: "top-right",
          timeout: 2000,
        });
        return
      }

      const track_order = fb.httpsCallable("starshipit_track");
      const tracking_res = await track_order(ssid);
      console.log(JSON.stringify(tracking_res));
      const order_data = tracking_res.data.data.order;
      console.log(order_data);
      console.log(order_data.packages);
      const tracking_url = order_data.packages[0].tracking_url;
      window.open(tracking_url, "_blank");
    },
  },
  computed: {
    filtered() {
      let visible_shipments = this.shipments.slice();

      visible_shipments = visible_shipments.filter(
        (shipment) => !shipment.mw_seq// && shipment.ssid
      );

      const search = this.search;

      if (this.sort_column) {
        visible_shipments = orderBy(
          visible_shipments,
          (item) => item[this.sort_column] || "",
          this.sort_order
        );
      }

      visible_shipments = visible_shipments.filter((shipment) => {
        const s = Object.values(shipment);
        const su = s.toString().toUpperCase();
        return su.match(search.toString().toUpperCase());
      });

      if (!search) {
        const start = (this.selected_page - 1) * this.page_limit;
        const end = start + this.page_limit;
        visible_shipments = visible_shipments.slice(start, end);
      }

      return visible_shipments;
    },
    pages() {
      const pages_array = this.search ? this.filtered : this.shipments;
      return Math.ceil(pages_array.length / this.page_limit);
    },
  },
};
</script>

<style scoped></style>
