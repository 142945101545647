export default {
  methods: {

    sort_string (table, column) {
      console.log('mixin sorting')
      this.sort_toggle = -this.sort_toggle

      return table.sort((a, b) => {
        if (
          a[column].toString().toUpperCase() <
          b[column].toString().toUpperCase()
        ) {
          return -this.sort_toggle
        }
        if (
          a[column].toString().toUpperCase() >
          b[column].toString().toUpperCase()
        ) {
          return this.sort_toggle
        }
        return 0
      })
    },
    sort_number (table, column) {
      this.sort_toggle = -this.sort_toggle

      return table.sort((a, b) => {
        if (a[column] < b[column]) {
          return -this.sort_toggle
        }
        if (a[column] > b[column]) {
          return this.sort_toggle
        }
        return 0
      })
    },

    sort_date (table, column) {
      this.sort_toggle = -this.sort_toggle

      return table.sort((a, b) => {
        const dateA = a[column]
        const dateB = b[column]
        if (dateA < dateB) {
          return -this.sort_toggle
        }
        if (dateA > dateB) {
          return this.sort_toggle
        }
        return 0
      })
    },

    sort_date_2 (table, column) {
      this.sort_toggle = -this.sort_toggle

      return table.sort((a, b) => {
        const dateA = a[column] && new Date(convertDate(a[column]))
        const dateB = b[column] && new Date(convertDate(b[column]))
        if (dateA < dateB) {
          return -this.sort_toggle
        }
        if (dateA > dateB) {
          return this.sort_toggle
        }
        return 0
      })

      function convertDate (date) {
        const date_array = date.split('/')
        return `${date_array[2]}/${date_array[1]}/${date_array[0]}`
      }
    }

  }
}
