<template>
  <div>
    <div
      class="modal fade show modal-mask"
      tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Send Back All</h5>

            <button
              @click="close_send_back_all"
              type="button"
              class="close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <!-- <div class="form-group">
              <label>Quantity NOT shipped</label>
              <div class="form-control">{{ selected_line.picking }}</div>
            </div> -->

            <div class="form-group">
              <label>Notes</label>
              <textarea v-model="pick_notes"></textarea>
            </div>
          </div>
          <div class="modal-footer">
            <button
              @click.prevent="send_back_all"
              @keyup.enter.prevent="send_back_all"
              type="button"
              class="btn btn-sm btn-outline-success mr-2">
              Send Back
            </button>
            <button
              @click="close_send_back_all"
              type="button"
              class="btn btn-sm btn-outline-secondary">
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { fb } from "@/firebaseConfig";

export default {
  name: "picking_send_back_all",
  props: {
    order_lines: Array,
  },
  data() {
    return { pick_notes: "" };
  },

  methods: {
    close_send_back_all() {
      this.$emit("close_send_back_all");
    },
    send_back_all: async function () {
      const lines_object = {
        order_lines: this.order_lines,
        pick_notes: this.pick_Notes,
      };
      const order_send_back_all = fb.httpsCallable("order_send_back_all");
      const send_back_res = await order_send_back_all(lines_object);
      console.log(send_back_res);

      this.close_send_back_all();
    },
  },
};
</script>

<style scoped>
.my-toast {
  font-size: 5em;
}
.modal {
  display: block;
  padding-right: 17px;
}
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

textarea {
  width: 100%;
  height: 100px;
}
</style>
