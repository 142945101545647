<template>
  <basicLayout>
    <h5>GROUPS</h5>
    <div class="row">
      <div class="col-6">
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text">Search...</span>
          </div>
          <input
            :value="search"
            type="text"
            class="form-control"
            placeholder="PO, SO, Name, or Carrier"
          />
          <div class="input-group-append">
            <button
              @click="clear_search"
              class="btn btn-outline-secondary"
              type="button"
            >
              Clear
            </button>
          </div>
        </div>
      </div>
      <div class="col-6"></div>
    </div>
    <hr />
    <div class="row mb-2">
      <div class="col"></div>
      <div class="col text-right">
        <b>Showing: {{ this.filtered.length }}</b> of
        <b>{{ this.groups.length }}</b>
      </div>
    </div>
    <div class="row m-2">
      <div class="tableFixHead">
        <table class="table table-bordered table-hover table-sm">
          <thead class="thead-dark">
            <tr>
              <th @click="sort_string('name')">Name</th>

              <th>Members</th>
              <th>Group Rules</th>
              <!-- <th></th> -->
            </tr>
          </thead>
          <tbody>
            <tr v-for="group in filtered" :key="group.id">
              <td>{{ group }}</td>
              <td class="text-right">
                <router-link
                  :to="{ name: 'Customers', params: { filter: group } }"
                  >{{ group_members(group) }}</router-link
                >
              </td>
              <td class="text-right">
                <router-link
                  :to="{ name: 'Rules', params: { name_code: group } }"
                  >{{ total_rules(group) }}</router-link
                >
              </td>

              <!-- <td class="text-center text-black">
                <router-link :to="{ name: 'NameEdit', params: {name_code: name.code }}">
                  <font-awesome-icon :icon="['fas', 'edit']" />
                </router-link>
              </td>-->
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </basicLayout>
</template>

<script>
// import moment from "moment";
import { db } from '@/firebaseConfig'
import basicLayout from '@/components/basicLayout'

export default {
  name: 'groups',
  data () {
    return {
      names: [],
      rules: [],
      search: '',
      sort_toggle: 1
    }
  },
  components: { basicLayout },

  firestore: {
    names: db.collection('names').where('deleted', '==', false),
    rules: db.collection('rules')
  },

  methods: {
    clear_search () {
      this.search = ''
    },
    sort_string (column) {
      this.sort_toggle = -this.sort_toggle

      return this.names.sort((a, b) => {
        if (
          a[column].toString().toUpperCase() <
          b[column].toString().toUpperCase()
        ) {
          return -this.sort_toggle
        }
        if (
          a[column].toString().toUpperCase() >
          b[column].toString().toUpperCase()
        ) {
          return this.sort_toggle
        }
        return 0
      })
    },
    sort_number (column) {
      this.sort_toggle = -this.sort_toggle

      return this.names.sort((a, b) => {
        if (a[column] < b[column]) {
          return -this.sort_toggle
        }
        if (a[column] > b[column]) {
          return this.sort_toggle
        }
        return 0
      })
    },

    filter_by (filter) {
      this.selected = []
      this.selectAll = false
      this.search = filter
    },

    total_rules (code) {
      const applicable_rules = this.rules.filter((r) => {
        return (
          code &&
          r.name_code.toString().toUpperCase() == code.toString().toUpperCase()
        )
      })
      return applicable_rules.length
    },
    group_members (code) {
      const group_members = this.names.filter((n) => {
        return (
          (code &&
            n.group &&
            n.group.toString().toUpperCase() ==
              code.toString().toUpperCase()) ||
          (n.head_office &&
            n.head_office.toString().toUpperCase() ==
              code.toString().toUpperCase())
        )
      })
      return group_members.length
    }
  },
  computed: {
    groups () {
      const group_codes = new Set(
        this.names.map((d) => d.head_office || d.group)
      )
      const group_codes_array = Array.from(group_codes)
      return group_codes_array.filter((g) => {
        return g
      })
    },

    filtered () {
      return this.groups.filter((name) => {
        const s = name
        const su = s.toString().toUpperCase()
        return su.match(this.search.toString().toUpperCase())
      })
    }
  }
}
</script>

<style scoped>
</style>
