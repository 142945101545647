<template>
  <basicLayout>
    <div class="row">
      <div class="col-8">
        <h5>ORDER UPLOAD</h5>
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-6 form-group">
                <div class="custom-file">
                  <input
                         @change="file_picker"
                         type="file"
                         class="custom-file-input"
                         id="customFile" />
                  <label class="custom-file-label" for="customFile">Choose file</label>
                </div>
              </div>
              <div class="col-6 form-group">
                <div v-cloak @drop.prevent="drag" @dragover.prevent>
                  <input
                         class="form-control progress-bar-striped alert-dark"
                         placeholder="...or drag file here" />
                </div>
              </div>
            </div>
            <div v-if="upload_file_name" class="row">
              <div class="col-xl-6 text-right">
                <small>{{ upload_file_name }}</small>
              </div>
            </div>

            <div class="form-group">
              <label for="upload_template">Saved Templates</label>
              <v-select
                        v-model="upload_template"
                        :options="template_options"
                        :selectOnTab="true"
                        :clearable="false"></v-select>
            </div>

            <!-- <div v-if="upload_file.length"> -->
            <div class="form-group">


              <label>Name Code</label>
              <v-select
                        v-model="selected_name"
                        :options="names_search"
                        ref="autocomplete_names"
                        :selectOnTab="true"
                        :clearable="false"></v-select>


              <!-- <label for="Name">Name Code</label>
              <small class="form-text text-muted"
                >Use this input if the csv upload file does not contain a column
                for Name Code</small
              >
              <input
                v-model="name_code"
                type="text"
                class="form-control"
                id="name"
                required
              /> -->
            </div>

            <div class="form-group">
              <label for="Name">PO Number</label>
              <small class="form-text text-muted">Use this input if the csv upload file does not contain a column
                for PO Number</small>
              <input
                     v-model="po_number"
                     type="text"
                     class="form-control"
                     id="name"
                     required />
            </div>
            <div class="form-group">
              <label for="upload_template">Order Type</label>
              <v-select
                        v-model="order_type"
                        :options="type_options"
                        :selectOnTab="true"
                        :clearable="false"></v-select>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="show_map" class="row mt-2" style="font-size: 0.8em">
      <div class="col-8">
        <div class="card">
          <div class="card-header">
            <div class="row">
              <div class="col">
                <h6>FIELD MAP</h6>
              </div>
              <div class="col text-right">
                <span type="button" @click="show_map = !show_map">&#9587;</span>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div v-for="field in upload_template.field_map" :key="field.id">
              <div class="form-group">
                <label :for="field.label">{{ field.label }}</label>
                <v-select
                          placeholder="maps to..."
                          v-model="field.maps_to"
                          multiple
                          :options="upload_fields"
                          :selectOnTab="true"></v-select>
              </div>
            </div>
          </div>

          <div class="card-footer">
            <div class="row align-items-end">
              <div v-if="upload_file.length && !upload_template.id" class="col">
                <p>Save this Template As</p>
                <form class="form-inline">
                  <label class="sr-only" for="template_name">Name</label>
                  <input
                         v-model="upload_template.label"
                         type="text"
                         class="form-control form-control-sm"
                         id="template_name"
                         placeholder="Template Name" />
                  <button
                          @click.prevent="save_template"
                          type="submit"
                          class="btn btn-sm btn-outline-secondary ml-2">
                    Save
                  </button>
                </form>
              </div>
              <div v-if="upload_file.length && upload_template.id" class="col">
                <p>This Template: {{ upload_template.label }}</p>
                <button
                        @click.prevent="update_template"
                        type="submit"
                        class="btn btn-sm btn-outline-secondary">
                  Update Template
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="show_preview" class="row mt-2">
      <div class="col-8">
        <div class="card">
          <div class="card-header">
            <div class="row">
              <div class="col">
                <h6>PREVIEW</h6>
              </div>
              <div class="col text-right">
                <button
                        type="button"
                        class="btn btn-x-sm btn-outline-secondary mr-1"
                        @click="pre_process_order"
                        title="refresh preview">
                  <refresh-cw-icon
                                   size="1.0x"
                                   class="custom-class me-1"></refresh-cw-icon>
                </button>
                <button
                        type="button"
                        class="btn btn-x-sm btn-outline-secondary"
                        @click="preview_upload"
                        title="close preview">
                  <x-icon size="1.0x" class="custom-class"></x-icon>
                </button>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row p-2">
              <div class="col">Name: {{ order_object.name_code }}</div>
              <div class="col">PO Number: {{ order_object.po_number }}</div>
              <div class="col">Type: {{ order_object.order_type }}</div>
            </div>

            <div class="tableFixHead tableFixHeadShort">
              <table class="table table-bordered table-hover table-sm">
                <thead class="thead-dark">
                  <tr>
                    <th class="text-left">Stock Code</th>
                    <th class="text-left">Barcode</th>
                    <th class="text-left">Title</th>
                    <th class="text-left">Quantity</th>
                    <th class="text-left">Import Price</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                      v-for="line in order_object.order_lines"
                      :key="line.order_number">
                    <td class="text-left">{{ line.stock_code }}</td>
                    <td class="text-left">{{ line.barcode }}</td>
                    <td class="text-left">{{ line.description }}</td>
                    <td class="text-left">{{ line.quantity }}</td>
                    <td class="text-left">{{ line.price }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="upload_file.length" class="row mt-2" style="font-size: 0.8em">
      <div class="col-8">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col">
                <button
                        v-if="upload_template"
                        @click.prevent="process_order"
                        type="submit"
                        class="btn btn-sm btn-outline-success">
                  Upload Order
                </button>
              </div>



              <button
                      @click="show_map = !show_map"
                      class="btn btn-sm btn-outline-secondary ml-1">
                {{ show_map ? "Hide Map" : "Show Map" }}
              </button>
              <button
                      v-if="upload_template"
                      @click.prevent="preview_upload"
                      type="submit"
                      class="btn btn-sm btn-outline-secondary ml-1">
                {{ show_preview ? "Hide Preview" : "Show Preview" }}
              </button>
              <router-link
                           v-if="new_order_id"
                           class="btn btn-sm btn-outline-success ml-1"
                           :to="{
                          name: 'OrderEdit',
                          params: { order_id: new_order_id },
                        }">
                Edit Order
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>

    <div v-if="skipped_lines.length" class="row mt-2">
      <div class="col-8">
        <div class="card">
          <div class="card-body">
            <h6>SKIPPED LINES</h6>
            <div class="tableFixHead">
              <table class="table table-bordered table-hover table-sm">
                <thead class="thead-dark">
                  <tr>
                    <th @click="sort_by('line', 'number')" class="text-left">
                      Line
                    </th>
                    <th
                        @click="sort_by('stock_code', 'string')"
                        class="text-left">
                      Stock Code
                    </th>
                    <th @click="sort_by('barcode', 'string')" class="text-left">
                      Barcode
                    </th>
                    <th
                        @click="sort_by('description', 'string')"
                        class="text-left">
                      Title
                    </th>
                    <th>Quantity</th>
                    <!-- <th></th> -->
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="line in skipped_lines_sorted" :key="line.line">
                    <td class="text-left">{{ line.line }}</td>
                    <td class="text-left">{{ line.stock_code }}</td>
                    <td class="text-left">{{ line.barcode }}</td>
                    <td class="text-left">{{ line.description }}</td>
                    <td class="text-left">{{ line.quantity }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="card-footer text-muted">
            <a
               :download="`po_${order_object.po_number}_skipped_lines.csv`"
               :href="download_url"
               :disabled="!this.skipped_lines.length"
               role="button"
               class="btn btn-outline-secondary float-right">Download</a>
          </div>
        </div>
      </div>
    </div>
  </basicLayout>
</template>

<script>
import basicLayout from "@/components/basicLayout";
import { actions } from "@/store";
import Papa from "papaparse";
import { fb, db } from "@/firebaseConfig";
import sort_mixins from "@/mixins/sort_mixins";
import { RefreshCwIcon, XIcon } from "vue-feather-icons";

const default_template = {
  id: "",
  default: true,
  label: "Default",
  //name_code: "",
  po_number: "",
  field_map: [
    { id: "name_code", label: "Name Code", maps_to: "" },
    { id: "po_number", label: "PO Number", maps_to: "" },
    { id: "stock_code", label: "Stock Code", maps_to: "" },
    { id: "barcode", label: "Barcode", maps_to: "" },
    { id: "description", label: "Title", maps_to: "" },
    {
      id: "quantity",
      label: "Quantity",
      maps_to: "",
    },

    {
      id: "price",
      label: "Import Price",
      maps_to: "",
    },
    {
      id: "po_line_number",
      label: "PO Line Number",
      maps_to: "",
    },
  ],
};
export default {
  name: "order_upload",
  data: function () {
    return {
      //name_code: "",
      po_number: "",
      order_type: "Standard",
      upload_file_name: "",
      upload_file: {},
      upload_fields: [],
      upload_templates: [{ label: "", field_map: [] }],
      upload_template: default_template,
      field_map: {},
      order_object: { name_code: "", po_number: "", order_lines: [] },
      type_options: ["Urgent", "Catalogue", "New Release", "Website", "Promo", "Staff"],
      names: [],
      selected_name: { code: "", name: "", label: "", productpricing: "" },
      new_order_id: "",
      show_map: false,
      show_preview: false,
      skipped_lines: [],
      sort_column: "index",
      sort_type: "number",
      sort_toggle: -1,
    };
  },
  mixins: [sort_mixins],
  firestore() {
    return {
      upload_templates: db.collection("upload_templates"),
      names: db.collection("names").where("deleted", "==", false),
    };
  },
  components: {
    basicLayout,
    RefreshCwIcon,
    XIcon,
  },
  methods: {
    drag(event) {
      this.upload(event.dataTransfer.files[0]);
      event.target.value = "";
    },
    file_picker(event) {
      // console.log("file picker");
      const upload_file = event.target.files[0];
      this.upload_file_name = upload_file.name;
      this.upload(upload_file);

      event.target.value = "";
    },
    upload(file) {
      const pre_process_file = this.pre_process_file;
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        dynamicTyping: true,
        complete(results) {
          pre_process_file(results);
        },
      });
    },

    pre_process_file: function (upload_data) {
      // console.log("pre-processing file");
      // console.log(upload_data.meta.fields);
      this.upload_fields = upload_data.meta.fields;
      this.upload_file = upload_data.data;
      // this.new_order_id = "";
    },

    preview_upload: async function () {
      console.log("show_preview: " + this.show_preview);
      this.show_preview = !this.show_preview;
      if (this.show_preview) {
        await this.pre_process_order();
      }
    },

    pre_process_order: async function () {
      this.field_map = new Map(this.upload_template.field_map.map((i) => [i.id, i.maps_to]));

      const join_fields = this.join_fields;

      const order_lines_array = this.upload_file.map((line) => {
        const order_lines_object = {
          name_code: join_fields(line, "name_code"),
          po_number: join_fields(line, "po_number"),
          stock_code: join_fields(line, "stock_code"),
          description: join_fields(line, "description"),
          barcode: join_fields(line, "barcode"),
          quantity: Number(join_fields(line, "quantity")),
          price: Number(join_fields(line, "price")),
          po_line_number: join_fields(line, "po_line_number"),
        };
        return order_lines_object;
      });

      const first_line = order_lines_array[0];
      const name_code = this.selected_name.code || first_line.name_code;
      const po_number = this.po_number || first_line.po_number;
      const order_type = this.order_type || "Catalogue";

      const order = {
        source: "CSV",
        po_number: po_number,
        name_code: name_code,
        order_type: order_type,
        order_lines: order_lines_array,
      };

      // console.log(JSON.stringify("ORDER: " + JSON.stringify(order)));
      this.order_object = order;
    },

    process_order: async function () {
      actions.setLoading(true);
      await this.pre_process_order();
      console.log("ORDER: " + JSON.stringify(this.order_object));

      const order_importer = fb.httpsCallable("order_importer");
      const order_importer_res = await order_importer(this.order_object);
      console.log(JSON.stringify(order_importer_res));

      console.log("SKIPPED LINES: " + order_importer_res.data);

      if (order_importer_res.data.success) {
        if (order_importer_res.data.skipped_lines && order_importer_res.data.skipped_lines.length) {
          console.log(order_importer_res.data.skipped_lines);

          this.$toast.warning("Order Imported - Lines Skipped", {
            position: "top-right",
            timeout: 5000,
          });
        } else {
          this.$toast.success("Order Imported", {
            position: "top-right",
            timeout: 5000,
          });
        }

        this.new_order_id = order_importer_res.data.new_order_id;
        this.skipped_lines = order_importer_res.data.skipped_lines;
      } else {
        this.$toast.error("Order did not Import: " + order_importer.error, {
          position: "top-right",
          timeout: 5000,
        });
      }

      actions.setLoading(false);
    },

    join_fields(line, field_id) {
      // console.log("JOINING FIELDS " + field_id);
      const field_map = this.field_map;
      const join = " ";

      return (
        field_id &&
        field_map.get(field_id) &&
        field_map
          .get(field_id)
          .map((f) => line[f])
          .join(join)
      );
    },

    save_template: async function () {
      if (this.upload_template.label === "Default") {
        alert("Don't name your saved template 'Default'");
        return;
      }
      db.collection("upload_templates").add({
        created: new Date(),
        label: this.upload_template.label,
        field_map: this.upload_template.field_map,
      });
    },

    update_template: async function () {
      db.collection("upload_templates").doc(this.upload_template.id).update({
        updated: new Date(),
        field_map: this.upload_template.field_map,
      });
    },
  },

  computed: {
    names_search() {
      return this.names.map((n) => {
        n.label = `${n.name} (${n.code})`;
        return n;
      });
    },
    template_options() {
      const template_array = [...this.upload_templates];
      template_array.push(default_template);
      return template_array;
    },
    skipped_lines_sorted() {
      return this.sort_table(this.skipped_lines, this.sort_column, this.sort_type);
    },
    download_url() {
      const csv = Papa.unparse(this.skipped_lines);
      return this.skipped_lines.length ? "data:text/csv," + encodeURIComponent(csv) : "javascript:void(0);";
    },
  },
};
</script>
