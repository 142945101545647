<template>
  <div
    class="container"
    style="width: 100%">
    <div class="row alert-success p-1">
      <div class="col text-left">
        <span class="align-middle">
          <b>{{ product.description }}</b>
        </span>
      </div>
    </div>
    {{ product.is_outstanding }}
    <div class="row border-bottom">
      <div class="col text-left">Code</div>
      <div class="col text-right">{{ product.code }}</div>
    </div>
    <div class="row border-bottom">
      <div class="col text-left">Deleted</div>
      <div class="col text-right">{{ product.deleted }}</div>
    </div>
    <div class="row border-bottom">
      <div class="col text-left">Barcode</div>
      <div class="col text-right">{{ product.barcode }}</div>
    </div>
    <div class="row border-bottom">
      <div class="col text-left">Supplier</div>
      <div class="col text-right">{{ product.supplier }}</div>
    </div>
    <div class="row border-bottom">
      <div class="col text-left">Format</div>
      <div class="col text-right">{{ product.category1 }}</div>
    </div>
    <div class="row border-bottom">
      <div class="col text-left">Release</div>
      <div class="col text-right">
        {{ product.custom2 | moment_shortest }}
      </div>
    </div>
    <div class="row border-bottom">
      <div class="col text-left">SOH</div>
      <div class="col text-right">{{ product.stockonhand }}</div>
    </div>
    <div class="row border-bottom">
      <div class="col text-left">Available</div>
      <div class="col text-right">
        {{
          (Number(product.stockonhand) || 0) -
          (Number(product.all_allocated) || 0) -
          (Number(product.all_processing) || 0)
        }}
      </div>
    </div>
    <div class="row border-bottom">
      <div class="col text-left">All Orders</div>
      <div
        class="col text-right"
        title="Stock on Sales Order in MW">
        {{
          (Number(product.all_orders) || 0) + (Number(product.stock_on_so) || 0)
        }}
      </div>
    </div>
    <div class="row border-bottom">
      <div class="col text-left">SOSO</div>
      <div
        class="col text-right"
        title="Stock on Sales Order in MW">
        {{ Number(product.stock_on_so) || 0 }}
      </div>
    </div>

    <div v-if="product.on_po && product.on_po.length">
      <div class="row border-bottom">
        <div
          class="col text-left"
          title="Stock on Purchase Order in MW">
          SOPO
        </div>
      </div>
      <div>
        <div
          v-for="p in product.on_po"
          :key="p.our_ref">
          <div class="row border-bottom">
            <div class="col-1 text-left"></div>
            <div class="col text-left">Supplier</div>
            <div class="col text-right">{{ p.name_code }}</div>
          </div>
          <div class="row border-bottom">
            <div class="col-1 text-left"></div>
            <div class="col text-left">Our Ref</div>
            <div class="col text-right">{{ p.our_ref }}</div>
          </div>
          <div class="row border-bottom">
            <div class="col-1 text-left"></div>
            <div class="col text-left">Their Ref</div>
            <div class="col text-right">{{ p.their_ref }}</div>
          </div>
          <div class="row border-bottom">
            <div class="col-1 text-left"></div>
            <div class="col text-left">Date</div>
            <div class="col text-right">
              {{ p.trans_date | moment_shortest }}
            </div>
          </div>
          <div class="row border-bottom">
            <div class="col-1 text-left"></div>
            <div class="col text-left">Quantity</div>
            <div class="col text-right">{{ p.quantity }}</div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-else
      class="row border-bottom">
      <div class="col text-left">SOPO</div>
      <div
        class="col text-right"
        title="Stock on Purchase Order in MW">
        0
      </div>
    </div>
    <div class="row border-bottom">
      <div class="col text-left">DISCOUNT MODE</div>
      <div class="col text-right">{{ product.selldiscountmode }}</div>
    </div>
    <div
      class="row border-bottom"
      :class="{
        'alert-primary': product.rules && product.rules.price,
      }">
      <div class="col text-left">SELL PRICE</div>
      <div class="col text-right">
        {{ product.sell_price | currency }}
      </div>
    </div>
    <div class="row border-bottom">
      <div class="col text-left">PRICE A</div>
      <div class="col text-right">
        {{ product.sellprice_a | currency }}
      </div>
    </div>
    <div class="row border-bottom">
      <div class="col text-left">PRICE B</div>
      <div class="col text-right">
        {{ product.sellprice_b | currency }}
      </div>
    </div>
    <div class="row border-bottom">
      <div class="col text-left">PRICE C</div>
      <div class="col text-right">
        {{ product.sellprice_c | currency }}
      </div>
    </div>
    <div class="row border-bottom">
      <div class="col text-left">PRICE D</div>
      <div class="col text-right">
        {{ product.sellprice_d | currency }}
      </div>
    </div>
    <div class="row border-bottom">
      <div class="col text-left">PRICE E</div>
      <div class="col text-right">
        {{ product.sellprice_e | currency }}
      </div>
    </div>
    <div class="row">
      <div class="col text-left">PRICE F</div>
      <div class="col text-right">
        {{ product.sellprice_f | currency }}
      </div>
    </div>
  </div>
</template>

<script>
import { db } from "@/firebaseConfig";
import filter_mixins from "@/mixins/filter_mixins";

const products = db.collection("products");

export default {
  props: {
    stock_code: String,
  },
  data() {
    return {
      product: {},
    };
  },
  mixins: [filter_mixins],
  watch: {
    stock_code: {
      immediate: true,
      handler: function (new_val) {
        const clean_code = new_val.split("/").join("|");
        this.$bind("product", products.doc(clean_code));
      },
    },
  },
};
</script>

<style>
.popover {
  max-width: 600px !important;
  display: block !important;
  z-index: 10000;
}

.tooltip .tooltip-inner {
  min-width: 300px;
  background: black;
  color: white;
  border-radius: 16px;
  padding: 2px;
}

.tooltip.popover .popover-inner {
  min-width: 300px;
  font-size: 0.8em;
  background: #fff;
  color: #495057;
  padding: 4px;
  border-radius: 5px;
  box-shadow: 0 5px 30px rgba(black, 0.1);
}

.tooltip.popover .popover-arrow {
  border-color: #f9f9f9;
}

.tooltip[aria-hidden="true"] {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.15s, visibility 0.15s;
}

.tooltip[aria-hidden="false"] {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.15s;
}
</style>
