<template>
  <basicLayout>
    <h5>ORDERS BY PO</h5>
    <div class="row">
      <div class="col-xl-3">
        <div class="input-group input-group-sm">
          <div class="input-group-prepend">
            <span class="input-group-text">Search...</span>
          </div>
          <input
            @input="debounce_search"
            :value="search"
            type="text"
            class="form-control"
            placeholder="PO Number,Code or Name" />
          <div class="input-group-append">
            <button
              @click="clear_search"
              class="btn btn-outline-secondary"
              type="button">
              Clear
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row m-2">
      <div class="col text-right">
        <b>Showing: {{ this.filtered.length }}</b> of
        <b>{{ this.orders.length }}</b>
      </div>
    </div>
    <div class="row m-2">
      <div class="tableFixHead tableFixHeadShort">
        <table class="table table-bordered table-hover table-sm">
          <thead class="thead-dark">
            <tr>
              <th
                @click="sort_by('created')"
                class="text-left">
                Date
              </th>
              <!-- <th>deleted | outstanding</th> -->
              <th
                @click="sort_by('po_number')"
                class="text-left">
                PO No.
              </th>
              <th
                @click="sort_by('name')"
                class="text-left">
                To
              </th>
              <th
                @click="sort_by('type')"
                class="text-left">
                Type
              </th>
              <th></th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="line in filtered"
              :key="line.id"
              @click="selectOne(line)"
              @dblclick="open_lines(line)"
              :class="rowClass(line)">
              <td
                class="text-left"
                :title="line.created">
                {{ line.created | moment_short }}
              </td>
              <!-- <td>{{ line.deleted }} | {{ line.is_outstanding }}</td> -->

              <td
                @dblclick="filter_by(line.po_number)"
                class="text-left d-none d-sm-table-cell">
                {{ line.po_number }}
              </td>
              <td
                @dblclick="filter_by(line.name_code)"
                class="text-left">
                {{ line.name }} ({{ line.name_code }})
              </td>
              <td
                @dblclick="filter_by(line.type)"
                class="text-left">
                {{ line.type }}
              </td>

              <td class="text-center w-10">
                <router-link
                  class="btn btn-x-sm btn-outline-secondary"
                  :to="{ name: 'OrderEdit', params: { order_id: line.id } }">
                  <edit-icon
                    size="1x"
                    class="custom-class"></edit-icon
                  >&nbsp;Edit
                </router-link>
              </td>
              <td class="text-center w-10">
                <button
                  @click="delete_item(line)"
                  type="button"
                  class="btn btn-x-sm btn-outline-danger">
                  <trash-icon
                    size="1x"
                    class="custom-class"></trash-icon>
                  &nbsp;{{ confirm_delete_ids.includes(line.id) ? "Really Delete?" : "Delete" }}
                </button>
              </td>
              <td class="text-center w-10">
                <button
                  @click="update_order(line)"
                  type="button"
                  class="btn btn-x-sm btn-outline-secondary">
                  <refresh-cw-icon
                    size="1.0x"
                    class="custom-class"></refresh-cw-icon>
                  update
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div
      v-if="selectedPO.id"
      class="bottom-table">
      <hr />
      <OrderLines
        :purchase_order="selectedPO"
        :purchase_order_id="selectedPO.id"
        filter="purchase_order"
        parent_can_supply_filter="all"></OrderLines>
    </div>
  </basicLayout>
</template>

<script>
import { db, fb } from "@/firebaseConfig";
import { orderBy } from "lodash";
import basicLayout from "@/components/basicLayout";
import OrderLines from "@/components/OrderLines";
import filter_mixins from "@/mixins/filter_mixins";
// import orders_mixins from "@/mixins/orders_mixins";
import { EditIcon, TrashIcon, RefreshCwIcon } from "vue-feather-icons";

export default {
  name: "orders",
  data() {
    return {
      orders: [],
      name: "",
      search: "",
      debounce: null,
      selectedPO: {},

      sort_column: ["created", "name_code"],
      sort_order: ["desc", "asc"],
      selected_page: 1,
      confirm_delete_ids: [],
      delete_timeouts: [],
    };
  },
  components: { basicLayout, OrderLines, EditIcon, TrashIcon, RefreshCwIcon },
  mixins: [filter_mixins],

  firestore: {
    orders: db.collection("orders").where("is_outstanding", "==", true),
  },
  methods: {
    selectOne(order) {
      this.selectedPO = order;
    },
    open_lines(order) {
      console.log(order.id);
      this.$router.push({
        name: "OrderLinesByPO",
        params: { po_id: order.id },
      });
    },
    rowClass(order) {
      return { "alert-primary": this.selectedPO.id === order.id };
    },
    filter_by(filter) {
      this.search = filter;
    },
    clear_search() {
      this.search = "";
    },
    debounce_search(event) {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.search = event.target.value;
      }, 600);
    },
    sort_by(column) {
      this.sort_column = column;
      this.sort_order = this.sort_order === "asc" ? "desc" : "asc";
    },
    delete_item: async function (po) {
      let confirm_delete_ids = this.confirm_delete_ids;

      // let delete_wait;
      if (confirm_delete_ids.includes(po.id)) {
        clearTimeout(this.delete_timeouts[po.id]);
        const order_delete = fb.httpsCallable("order_delete");
        await order_delete(po.id);
        confirm_delete_ids = confirm_delete_ids.filter((i) => i !== po.id);
        return;
      }
      confirm_delete_ids.push(po.id);
      this.delete_timeouts[po.id] = setTimeout(() => {
        confirm_delete_ids = confirm_delete_ids.filter((i) => i !== po.id);
      }, 4000);
    },
    update_order: async function (po) {
      const update_order = fb.httpsCallable("sync_update_order");
      await update_order(po.id);
    },
  },
  computed: {
    // filtered_old() {
    //   const filtered_array = this.orders
    //     // .filter(order => {
    //     //   this.all_orders_name(order.name_code) > 0;
    //     // })
    //     .filter(order => {
    //       const s = Object.values(order);
    //       const su = s.toString().toUpperCase();
    //       return su.match(this.search.toString().toUpperCase());
    //     });

    //   if (this.sort_column) {
    //     return this.sort_table(
    //       filtered_array,
    //       this.sort_column,
    //       this.sort_type
    //     );
    //   }
    //   return filtered_array;
    // },
    filtered() {
      let visible_orders = this.orders.slice();
      const search = this.search;

      visible_orders = visible_orders.filter((order) => {
        const s = Object.values(order);
        const su = s.toString().toUpperCase();
        return su.match(search.toString().toUpperCase());
      });

      if (this.sort_column) {
        visible_orders = orderBy(visible_orders, this.sort_column, this.sort_order);
      }
      return visible_orders;
    },
  },
};
</script>

<style scoped>
</style>
