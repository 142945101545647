import { render, staticRenderFns } from "./OrderEdit.vue?vue&type=template&id=e4c51bfc&scoped=true&"
import script from "./OrderEdit.vue?vue&type=script&lang=js&"
export * from "./OrderEdit.vue?vue&type=script&lang=js&"
import style0 from "vue-multiselect/dist/vue-multiselect.min.css?vue&type=style&index=0&prod&lang=css&"
import style1 from "./OrderEdit.vue?vue&type=style&index=1&id=e4c51bfc&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e4c51bfc",
  null
  
)

export default component.exports