import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
pdfMake.vfs = pdfFonts.pdfMake.vfs

export default {
  methods: {
    pdfgen () {
      const table_data = this.order.line_items
        .slice()

        .reduce((array, line, i) => {
          const {
            sku,
            description,
            quantity,
            weight
          } = line

          const fields = {
            sku,
            description,
            quantity,
            weight
          }

          if (i === 0) {
            const keys = Object.keys(fields).map(k => {
              return { text: k.toUpperCase(), style: 'tableHeader' }
            })
            array.push(keys)
          }

          const entries = Object.entries(fields).map(([k, v]) => {
            const right = ['quantity', 'weight']
            const alignment = right.includes(k) ? 'right' : 'left'
            return { text: v, style: 'tableExample', alignment }
          })
          array.push(entries)
          return array
        }, [])

      console.log(JSON.stringify(table_data))
      const dd = {
        pageSize: 'A4',
        pageOrientation: 'landscape',
        content: [
          {
            text: `ORDER ${this.order.order_number}`,
            margin: [0, 20, 0, 5],
            style: 'header'
          },
          {
            text: `${this.order.shipping_address.first_name} ${this.order.shipping_address.last_name}`,
            margin: [0, 5, 0, 0],
            style: 'subheader'
          },
          {
            text: `${this.order.shipping_address.address1}\n${this.order.shipping_address.address2}\n${this.order.shipping_address.city}\n${this.order.shipping_address.state} ${this.order.shipping_address.postcode}\n${this.order.shipping_address.country}`,
            margin: [0, 5, 0, 25],
            style: 'subheader'
          },
          {
            style: 'tableExample',
            table: {
              widths: [
                'auto',
                '*',
                'auto',
                'auto'
              ],
              headerRows: 1,
              body: table_data
            },
            layout: {
              hLineColor: function () {
                return '#ccc'
              },
              vLineColor: function () {
                return '#ccc'
              },
              hLineWidth: function () {
                return 0.5
              },
              vLineWidth: function () {
                return 0.5
              },
              fillColor: function (rowIndex) {
                return rowIndex % 2 === 0 ? '#ddd' : null
              }
            }
          }
        ],
        styles: {
          header: {
            fontSize: 14,
            bold: true,
            margin: [0, 5, 0, 5]
          },
          subheader: {
            fontSize: 11,
            bold: true,
            margin: [0, 5, 0, 5]
          },
          tableExample: {
            fontSize: 10,
            margin: [0, 0, 0, 0]
          },
          tableHeader: {
            bold: true,
            fontSize: 11,
            color: 'black'
          }
        }
      }
      pdfMake.createPdf(dd).open()
    }
  }
}
