<template>
  <basicLayout>
    <div class="p-2">
      <h5>DROPSHIP ORDERS</h5>
      <div class="row">
        <div class="col-6">
          <div class="input-group input-group-sm mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">Search...</span>
            </div>
            <input
              @input="debounce_search"
              :value="search"
              class="form-control"
              placeholder="PO, SO, Name, or Carrier" />
            <div class="input-group-append">
              <button
                @click="clear_search"
                class="btn btn-outline-secondary"
                type="button">
                Clear
              </button>
            </div>
          </div>
        </div>
        <div class="col-6">
          <router-link
            class="btn btn-sm btn-outline-secondary mr-1"
            :to="{ name: 'DropShipStores' }"
            title="Dropship Stores">
            DropShip Stores
          </router-link>
          <button
            @click="fetch_bandcamp_orders"
            class="btn btn-sm btn-outline-secondary mr-2">
            Fetch Bandcamp
          </button>
          <button
            @click="fetch_orders"
            class="btn btn-sm btn-outline-secondary mr-2">
            Fetch Shopify
          </button>
          <router-link
            class="btn btn-sm btn-outline-secondary mr-1"
            :to="{ name: 'DropShipUpload' }"
            title="Import Dropship Orders">
            Upload Orders
          </router-link>
          <button
            @click="download_csv"
            class="btn btn-sm btn-outline-secondary mr-2">
            Download csv
          </button>
          <button
            v-if="selected.length"
            @click="ship_selected()"
            class="btn btn-sm btn-outline-secondary mr-2">
            Ship Selected
          </button>
        </div>
      </div>
      <div class="btn-toolbar">
        <button
          @click="update_status_filter('unshipped')"
          class="btn btn-sm btn-outline-secondary mr-1"
          :class="filter_active('unshipped')">
          Unshipped
        </button>
        <button
          @click="update_status_filter('shipped')"
          class="btn btn-sm btn-outline-secondary mr-1"
          :class="filter_active('shipped')">
          Shipped
        </button>
        <!-- <button
          @click="update_status_filter('incomplete')"
          class="btn btn-sm btn-outline-secondary mr-1"
          :class="filter_active('incomplete')"
        >
          Incomplete
        </button> -->
        <button
          @click="update_status_filter('complete')"
          class="btn btn-sm btn-outline-secondary mr-1"
          :class="filter_active('complete')">
          Complete
        </button>

        <button
          @click="update_status_filter('all')"
          class="btn btn-sm btn-outline-secondary mr-1"
          :class="filter_active('all')">
          All
        </button>
      </div>

      <hr />
      <div class="row mb-2">
        <div
          class="col text-right"
          :class="filter_class()">
          <b>Showing: {{ this.filtered.length }}</b> of
          <b>{{ this.dropship_orders.length }}</b>
        </div>
      </div>

      <div class="row">
        <div class="tableFixHead tableFixHeadShort">
          <table class="table table-bordered table-hover table-sm">
            <thead class="thead-dark">
              <tr>
                <th>
                  <!-- <input type="checkbox" v-model="selectAll" @click="select" /> -->
                </th>
                <th @click="sort_by('service')">Service</th>
                <th @click="sort_by('store')">Store</th>
                <th @click="sort_by('order_number')">Order #</th>
                <th @click="sort_by('order_date')">Date</th>
                <th @click="sort_by('first_name')">Name</th>
                <th></th>
                <th>Items</th>
                <th>Value</th>
                <th @click="sort_by('shipping_address.country')">Country</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(order, i) in filtered"
                :key="order.id"
                :class="row_class(order)"
                @click="select_one(order)">
                <td>
                  <input
                    @click="selectOne($event, i)"
                    type="checkbox"
                    :value="order.id"
                    v-model.lazy="selected" />
                </td>
                <td @dblclick="filter_by(order.service)">
                  {{ order.service }}
                </td>
                <td @dblclick="filter_by(order.store)">{{ order.store }}</td>
                <td>{{ order.order_number }}</td>
                <td>{{ order.order_date | date_short }}</td>
                <td>{{ order.first_name }} {{ order.last_name }}</td>
                <td>
                  <v-popover
                    placement="left"
                    offset="8"
                    trigger="hover">
                    <info-icon size="1.2x"></info-icon>
                    <template slot="popover">
                      <DropshipPopover :order="order"></DropshipPopover>
                    </template>
                  </v-popover>
                </td>
                <td class="text-right">{{ total_items(order) }}</td>
                <td class="text-right">{{ order.order_total | currency }}</td>
                <td>{{ order.shipping_address.country }}</td>

                <td>
                  <h6>
                    <span
                      v-if="order.shipped"
                      @click="track_order(order)"
                      class="badge badge-success mr-1"
                      role="button"
                      title="shipped"
                      >S</span
                    >
                    <span
                      v-else-if="order.starshipit_error"
                      @click="track_order(order)"
                      class="badge badge-error mr-1"
                      role="button"
                      :title="order.starshipit_error"
                      >S</span
                    >
                    <span
                      v-if="order.fulfilled"
                      class="badge badge-success mr-1"
                      title="fulfilled"
                      >F</span
                    >
                    <span
                      v-else-if="order.shipped && !order.fulfilled"
                      @click="shopify_fulfillment(order)"
                      class="badge badge-warning mr-1"
                      role="button"
                      title="not fulfilled"
                      >F</span
                    >
                    <span
                      v-if="order.complete"
                      class="badge badge-success"
                      title="complete"
                      >C</span
                    >
                  </h6>
                </td>
                <td class="text-center w-5">
                  <button
                    v-if="!order.shipped"
                    @click="delete_order(order, $event)"
                    class="btn btn-x-sm btn-outline-danger">
                    <trash-2-icon
                      size="1x"
                      class="custom-class"></trash-2-icon>
                    <span v-if="confirm_delete">&nbsp;Really?</span>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <hr />
      <DropShipOrder :order="selected_order"></DropShipOrder>
    </div>
  </basicLayout>
</template>

<script>
import { fb, db } from "@/firebaseConfig";
import { orderBy } from "lodash";
import DropShipOrder from "@/components/DropShipOrder";
import basicLayout from "@/components/basicLayout";
import DropshipPopover from "@/components/DropshipPopover";
import filter_mixins from "@/mixins/filter_mixins";
import pdfgen_mixins from "@/mixins/dropship_pdfgen";
import { Trash2Icon, InfoIcon } from "vue-feather-icons";
import Papa from "papaparse";
// import moment from "moment";

export default {
  name: "dropship_orders",
  data() {
    return {
      dropship_orders: [],
      selected_order: {},
      search: "",
      debounce: null,
      status_filter: "unshipped",
      sort_column: ["order_date", "desc"],
      sort_order: "desc",
      selected: [],
      selectAll: false,
      savedIndex: null,
      confirm_delete: false,
    };
  },
  components: {
    basicLayout,
    DropShipOrder,
    DropshipPopover,
    Trash2Icon,
    InfoIcon,
  },
  mixins: [filter_mixins, pdfgen_mixins],

  firestore: {
    dropship_orders: db
      .collection("dropship_orders")
      .orderBy("order_date", "desc")
      .limit(2000),
  },
  created() {
    this.search =
      this.$route.params.rule_id || this.$route.params.name_code || "";
  },

  methods: {
    total_items(order) {
      return order.line_items.reduce(function (prev, cur) {
        return prev + cur.quantity;
      }, 0);
    },
    row_class(order) {
      return { "alert-primary": this.selected_order.id === order.id };
    },
    rowClass(order) {
      return { "alert-primary": this.selected.includes(order.id) };
    },
    selectOne(e, index) {
      const savedIndex = this.savedIndex;
      this.savedIndex = index;

      if (savedIndex === null || !e.shiftKey) return;
      if (index === savedIndex) return;

      const subset = this.filtered
        .slice(Math.min(index, savedIndex), Math.max(index, savedIndex) + 1)
        // eslint-disable-next-line no-unused-vars
        .map(({ id, ...order }) => {
          return id;
        });

      if (e.currentTarget.checked) {
        if (!this.selected.length) {
          this.selected = subset;
        } else {
          this.selected = [...new Set(this.selected.concat(subset))];
        }
      } else {
        const subset_ids = new Set(subset);
        this.selected = this.selected.filter((sel) => {
          return !subset_ids.has(sel);
        });
      }
    },
    select_one(order) {
      this.selected_order = order;
    },
    debounce_search(event) {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.search = event.target.value;
      }, 600);
    },
    sort_by(column) {
      this.sort_column = column;
      this.sort_order = this.sort_order === "asc" ? "desc" : "asc";
    },
    filter_by(filter) {
      this.selected = [];
      this.selectAll = false;
      this.search = filter;
    },
    clear_search() {
      this.selected_order = {};
      this.selectAll = false;
      this.search = "";
    },
    update_status_filter(filter) {
      this.status_filter = filter;
      this.selected_order = {};
    },
    filter_active(filter) {
      if (this.status_filter === filter) {
        return "active";
      }
    },
    filter_class() {
      if (this.status_filter === "complete") {
        return "alert-success";
      } else if (this.status_filter === "shipped") {
        return "alert-warning";
      }
    },
    ship_selected: async function () {
      const selected_orders = this.filtered.filter((order) => {
        return this.selected.includes(order.id);
      });

      const order_promises = selected_orders.map(async (order) => {
        return this.ship_order(order);
      });

      await Promise.all(order_promises);

      this.selected = [];
    },
    fetch_orders: async function () {
      const dropship_shopify_fetch_all = fb.httpsCallable(
        "dropship_shopify_fetch_all"
      );
      const dropship_shopify_fetch_all_res = await dropship_shopify_fetch_all();
      console.log(dropship_shopify_fetch_all_res.data);
    },

    fetch_bandcamp_orders: async function () {
      const dropship_bandcamp_fetch_all = fb.httpsCallable(
        "dropship_bandcamp_fetch_all"
      );
      const dropship_bandcamp_fetch_all_res =
        await dropship_bandcamp_fetch_all();
      console.log(dropship_bandcamp_fetch_all_res.data);
    },

    ship_order: async function (order) {
      const dropship_starshipit = fb.httpsCallable("dropship_starshipit");
      const dropship_starshipit_res = await dropship_starshipit(order);

      console.log(dropship_starshipit_res);

      if (dropship_starshipit_res.data.success) {
        db.collection("dropship_orders")
          .doc(order.id)
          .update({ shipped: true, starshipit_error: null });
        this.$toast.success("Order Shipped", {
          position: "top-right",
          timeout: 2000,
        });
      } else {
        db.collection("dropship_orders").doc(order.id).update({
          shipped: false,
          starshipit_error: dropship_starshipit_res.error,
        });
        this.$toast.error(
          "Order Was Not Shipped: " + dropship_starshipit_res.error,
          {
            position: "top-right",
            timeout: 2000,
          }
        );
      }
    },
    track_order: async function (order) {
      const track_order = fb.httpsCallable("starshipit_track");
      const tracking_res = await track_order(order.ssid);
      const order_data = tracking_res.data.data.order;
      console.log(order_data);
      console.log(order_data.packages);
      const tracking_url = order_data.packages[0].tracking_url;
      window.open(tracking_url, "_blank");
    },
    shopify_fulfillment: async function (order) {
      console.log("shopify_fulfillment");
      const track_order = fb.httpsCallable("starshipit_track");
      const tracking_res = await track_order(order.ssid);
      const order_data = tracking_res.data.data.order;

      const record_id = order.id;
      const carrier_name = order_data.carrier_name;
      const tracking_number = order_data.packages[0].tracking_number;
      const tracking_url = order_data.packages[0].tracking_url;

      const fulfillment_data = {
        record_id,
        carrier_name,
        tracking_number,
        tracking_url,
      };

      console.log(fulfillment_data);

      const dropship_shopify_fulfillment = fb.httpsCallable(
        "dropship_shopify_fulfillment"
      );
      const fulfillment_res = await dropship_shopify_fulfillment(
        fulfillment_data
      );
      const fulfillment_res_data = fulfillment_res.data;

      if (!fulfillment_res_data.success) {
        if (
          fulfillment_res_data.error.errors.base.includes(
            "Line items are already fulfilled"
          )
        ) {
          db.collection("dropship_orders")
            .doc(order.id)
            .update({ fulfilled: true });
        }
      }
    },
    delete_order: async function (order, event) {
      event.stopPropagation();
      let delete_wait;
      if (this.confirm_delete) {
        clearTimeout(delete_wait);
        db.collection("dropship_orders").doc(order.id).delete();
        this.selected_order = {};
        this.confirm_delete = false;
        return;
      }
      this.confirm_delete = true;
      delete_wait = setTimeout(() => {
        this.confirm_delete = false;
      }, 4000);
    },

    download_csv(event) {
      console.log(event);
      if (this.filtered.length === 0) return;

      const csv_export = this.dropship_orders
        // eslint-disable-next-line no-unused-vars

        .map(({ shipping_address, line_items, ...order }) => {
          const shipping_object = {
            shipping_first_name: shipping_address.first_name,
            shipping_last_name: shipping_address.last_name,
            shipping_email: shipping_address.email,
            shipping_address1: shipping_address.address1,
            shipping_address2: shipping_address.address2,
            shipping_city: shipping_address.city,
            shipping_state: shipping_address.state,
            shipping_postcode: shipping_address.postcode,
            shipping_country: shipping_address.country,
          };
          order.order_date = new Date(
            order.order_date.seconds * 1000
          ).toLocaleDateString("en-NZ");

          const line_object = line_items.map(({ id, ...line }) => {
            line.line_id = id;
            return { ...order, ...shipping_object, ...line };
          });
          return line_object;
        })
        .reduce((prev, curr) => {
          return prev.concat(curr);
        }, []);

      console.log(JSON.stringify(csv_export));

      const csv = Papa.unparse({
        fields: [
          "order_number",
          "order_name",
          "order_id",
          "record_id",
          "line_id",
          "service",
          "store",
          "app_id",
          "landing_site",
          "order_date",
          "first_name",
          "last_name",
          "email",
          "shipping_first_name",
          "shipping_last_name",
          "shipping_address1",
          "shipping_address2",
          "shipping_city",
          "shipping_country",
          "quantity",
          "sku",
          "description",
          "order_total",
          "shipped",
          "complete",
          "fulfilled",
        ],
        data: csv_export,
      });
      const csv_name = "orders.csv";

      console.log(csv);

      event.target.href = "data:text/csv," + encodeURIComponent(csv);
      event.target.download = csv_name;
    },
  },
  computed: {
    filtered() {
      let visible_orders = this.dropship_orders.slice();
      const search = this.search;

      if (!search) visible_orders = visible_orders.slice(0, 1000);

      if (this.status_filter === "unshipped") {
        visible_orders = visible_orders.filter((line) => !line.shipped);
      } else if (this.status_filter === "shipped") {
        visible_orders = visible_orders.filter(
          (line) => line.shipped && !line.complete
        );
      } else if (this.status_filter === "complete") {
        visible_orders = visible_orders.filter((line) => line.complete);
      } else if (this.status_filter === "all") {
        visible_orders = visible_orders;
      }

      // visible_orders = visible_orders.map((order) => {
      //   order.order_date = moment(order.order_date).format();
      //   // console.log(order.order_date);
      //   return order;
      // });

      visible_orders = visible_orders.filter((order) => {
        const s =
          Object.values(order) +
          order.line_items.map((line) => line.sku) +
          order.line_items.map((line) => line.description);
        const su = s.toString().toUpperCase();
        return su.match(search.toString().toUpperCase());
      });

      visible_orders = visible_orders.map((order) => {
        order.record_id = order.id;
        return order;
      });

      if (this.sort_column) {
        visible_orders = orderBy(
          visible_orders,
          this.sort_column,
          this.sort_order
        );
      }
      return visible_orders;
    },
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    search: function (newSearch) {
      if (newSearch) {
        this.selected_order = {};
        this.selected_page = -1;
      } else {
        this.selected_page = 1;
      }
    },
  },
};

/*

  shopify_fulfill_all: async function () {
      const fulfillment_promises = this.dropship_orders
        .filter((order) => order.fulfilled === false)
        .map((order) => {
          this.shopify_fulfillment(order);
        });
      await Promise.all(fulfillment_promises);
    },

update_shopify: async function () {
      const update_data = {
        since_id: 0,
        store: "BF",
      };
      console.log("dropship_shopify_update");
      const shopify_update = fb.httpsCallable("dropship_shopify_update");
      const order_data = await shopify_update(update_data);
      console.log(order_data.data.response.orders);

      let order_array = order_data.data.response.orders;

      order_array.map((order) => {
        // console.log("ORDER: " + JSON.stringify(order));
        // console.log("ORDER_NUMBER: " + order.order_number);
        let local_record = this.dropship_orders.find(
          (order_record) =>
            order_record.order_number === order.order_number ||
            order_record.order_number === `#${order.order_number}`
        );
        if (local_record) {
          const line_items = order.line_items.map((line) => {
            const line_item = {
              id: line.id,
              sku: line.sku,
              description: line.title,
              quantity: line.quantity,
            };
            return line_item;
          });

          const order_details = {
            service: "SHOPIFY",
            store: "BF",
            order_id: order.id,
            order_number: order.order_number,
            order_name: order.name,
            order_date: order.created_at,
            first_name: (order.customer && order.customer.first_name) || "",
            last_name: (order.customer && order.customer.last_name) || "",
            email: (order.customer && order.customer.email) || "",
            shipping_address: order.shipping_address || {},
            order_total: Number(order.total_price),
            line_items: line_items,
            shipped: order.fulfillment_status === "fulfilled",
            complete: false,
            fulfilled: order.fulfillment_status === "fulfilled",
          };

          order_details.shipping_address.postcode =
            (order.shipping_address && order.shipping_address.zip) || "";
          order_details.shipping_address.state =
            (order.shipping_address && order.shipping_address.province) || "";
          order_details.shipping_address.state_code =
            (order.shipping_address && order.shipping_address.province_code) ||
            "";

          console.log(order_details);

          db.collection("dropship_orders")
            .doc(local_record.id)
            .update(order_details);
        }
      });

      // this.dropship_orders.map((order) => {
      //   console.log(
      //     order.id + " - " + order.order_number + " - " + order.record_id
      //   );
      // });
    },
update_fulfilled: async function () {
      const update_promises = this.dropship_orders
        .filter(
          (order) =>
            !order.fulfilled && order.fulfillment_status === "fulfilled"
        )
        // .slice(0, 2)
        .map((order) => {
          console.log(JSON.stringify(order));
          db.collection("dropship_orders")
            .doc(order.record_id)
            .update({ fulfilled: true });
        });

      await Promise.all(update_promises);
    },

    update_complete: async function () {
      const update_promises = this.dropship_orders
        .filter(
          (order) => !order.complete && order.fulfillment_status === "fulfilled"
        )
        // .slice(0, 2)
        .map((order) => {
          console.log(JSON.stringify(order));
          db.collection("dropship_orders")
            .doc(order.record_id)
            .update({ complete: true });
        });

      await Promise.all(update_promises);
    },

    update_all_states: async function () {
      const update_promises = this.dropship_orders
        .filter((order) => order.fulfilled && !order.shippped)
        .slice(0, 2)
        .map((order) => {
          console.log(JSON.stringify(order));
          db.collection("dropship_orders")
            .doc(order.record_id)
            .update({ shipped: true });
        });

      await Promise.all(update_promises);
    },

    update_all: async function () {
      console.log(
        "shopify_orders_array: " +
          JSON.stringify(this.shopify_orders.slice(0, 5))
      );

      const update_promises = this.dropship_orders.map((order) => {
        let shopify_order = this.shopify_orders.find(
          (shopify_order) =>
            shopify_order.name === order.order_name ||
            shopify_order.name === order.order_number
        );

        console.log((shopify_order && shopify_order.id) || order.order_number);
      });

      await Promise.all(update_promises);

      // shopify_order = shopify_orders_array.find(line => line.name === order.order_number || line.name === order.order_name)

      //     let update_promises = this.dropship_orders
      //     .filter(order => !order.order_number)
      //     .map(order => {

      //     });

      //     await Promise.all(fulfillment_promises);
    },
    */
</script>

<style scoped>
</style>
