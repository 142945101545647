// Component.vue (style omitted)
<template>
  <div class="container">
    <h1>{{code}}</h1>
    <span>{{description}}</span>
  </div>
</template>

<script>
export default {
  name: 'toast',
  props: {
    code: String,
    description: String
  }
}
</script>
