<template>
  <basicLayout>
    <h5>ORDER MANAGEMENT</h5>
    <button @click="make_daily_list" class="btn btn-success">make list</button>

    <div class="row mb-2">
      <div class="col-xl-6">
        <div class="input-group input-group-sm">
          <div class="input-group-prepend">
            <span class="input-group-text">Search...</span>
          </div>
          <input
                 @input="debounce_search"
                 :value="search"
                 type="text"
                 class="form-control"
                 placeholder="PO, SO, Name, or Carrier" />
          <div class="input-group-append">
            <button
                    @click="clear_search"
                    class="btn btn-outline-secondary"
                    type="button">
              Clear
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="row mb-2">
      <div class="tableFixHead col">
        <table class="table table-bordered table-hover table-sm">
          <thead class="thead-dark">
            <tr>
              <th class="text-center w-5">_</th>

              <th
                  @click="sort_by('name_code')"
                  role="button"
                  class="text-left w-5"
                  scope="col">
                Code
                <span
                      v-if="sort_column === 'name_code' && sort_order === 'desc'"><chevron-down-icon
                                     size="0.9x"
                                     class="muted ms-1"></chevron-down-icon></span>
                <span v-if="sort_column === 'name_code' && sort_order === 'asc'"><chevron-up-icon
                                   size="0.9x"
                                   class="muted ms-1"></chevron-up-icon></span>
              </th>
              <th
                  @click="sort_by('name')"
                  role="button"
                  class="text-left w-20"
                  scope="col">
                Name
                <span v-if="sort_column === 'name' && sort_order === 'desc'"><chevron-down-icon
                                     size="0.9x"
                                     class="muted ms-1"></chevron-down-icon></span>
                <span v-if="sort_column === 'name' && sort_order === 'asc'"><chevron-up-icon
                                   size="0.9x"
                                   class="muted ms-1"></chevron-up-icon></span>
              </th>
              <th class="text-left w-25">Description</th>
              <th class="w-5">Outstanding</th>

              <th class="w-15">Assigned To</th>
              <th class="w-5">Start</th>
              <th class="w-5">Finish</th>

              <th class="w-5">Archive</th>
              <th></th>
              <th class="w-5">Pick</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in filtered" :key="item.name_code">
              <td class="text-center">
                {{ item.priority }}
                <button
                        @click="priority_increase(index)"
                        class="btn btn-x-sm btn-outline-secondary mr-1">
                  <span><chevron-up-icon
                                     size="0.9x"
                                     class="muted ms-1"></chevron-up-icon></span>
                </button>
                <button class="btn btn-x-sm btn-outline-secondary">
                  <span><chevron-down-icon
                                       size="0.9x"
                                       class="muted ms-1"></chevron-down-icon></span>
                </button>
              </td>
              <td class="text-left w-5">
                {{ item.name_code }}
              </td>
              <td class="text-left">{{ item.name }}</td>

              <td class="text-left">
                <div class="row">
                  <div class="col-10 text-left">
                    <span>{{ item.notes }}</span>
                  </div>
                  <div class="col- text-right">
                    <button
                            @click="add_note_modal(item)"
                            type="button"
                            class="btn btn-x-sm btn-outline-secondary">
                      Notes
                    </button>
                  </div>
                </div>
              </td>

              <td class="text-right">
                {{ item.price_picking | currency }}
              </td>

              <td class="text-center">
                <v-select
                          multiple
                          v-model="item.assigned_users"
                          :options="drones"
                          @input="update_line_actions(item)"
                          :disabled="item.finish_time?.seconds > 0" />
              </td>
              <td
                  v-if="item.start_time"
                  class="text-center"
                  :title="days_ago(item.start_time)">
                {{ short_time(item.start_time) }}
              </td>
              <td v-else class="text-center">
                <button
                        @click="set_start_time(item)"
                        class="btn btn-x-sm btn-outline-success"
                        :disabled="!item.assigned_users?.length">
                  <play-circle-icon size="1.0x" class="mr-1"></play-circle-icon>
                  start
                </button>
              </td>
              <td
                  v-if="item.finish_time"
                  class="text-center"
                  :title="duration(item.start_time, item.finish_time)">
                {{ short_time(item.finish_time) }}
              </td>
              <td v-else class="text-center">
                <button
                        @click="set_finish_time(item)"
                        class="btn btn-x-sm btn-outline-danger"
                        :disabled="!item.start_time?.seconds > 0">
                  <stop-circle-icon size="1.0x" class="mr-1"></stop-circle-icon>
                  finish
                </button>
              </td>

              <td class="text-center">
                <button
                        @click="archive_item(item)"
                        type="button"
                        class="btn btn-x-sm btn-outline-danger mr-2"
                        :disabled="!item.is_complete">
                  <archive-icon size="1.0x" class="mr-1"></archive-icon>
                  archive
                </button>
              </td>
              <td class="text-center">
                <button
                        @click="delete_item(item)"
                        type="button"
                        class="btn btn-x-sm btn-outline-danger">
                  x
                </button>
              </td>

              <td class="text-center">
                <button
                        @click="pick_lines(item.name_code)"
                        type="button"
                        class="btn btn-x-sm btn-outline-success mr-2">
                  <list-icon size="1.0x" class="mr-1"></list-icon>
                  Pick Lines
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <OrderManagementAddNote
                            v-if="show_add_note"
                            :selected_item="selected_item"
                            v-on:close_add_note="close_add_note"
                            v-on:update_note="update_notes" />
  </basicLayout>
</template>

<script>
import { db } from "@/firebaseConfig";
import { orderBy } from "lodash";
import basicLayout from "@/components/basicLayout";
import OrderManagementAddNote from "@/components/OrderManagementAddNote";
import filter_mixins from "@/mixins/filter_mixins";
import orders_mixins from "@/mixins/orders_mixins";
import utility_mixins from "@/mixins/utility_mixins";
import {
  ListIcon,
  ArchiveIcon,
  PlayCircleIcon,
  StopCircleIcon,
  ChevronUpIcon,
  ChevronDownIcon,
} from "vue-feather-icons";
import moment from "moment";

const lines = db.collection("lines").where("is_outstanding", "==", true);

export default {
  name: "picking",
  data() {
    return {
      // names: [],
      display_lines: [],
      daily_lists: [],
      daily_list_actions: [],
      search: "",
      to_pick_only: true,
      show_add_note: false,
      selected_item: {},
      drones: [
        "Ant",
        "Arthur",
        "Chris",
        "Dave",
        "Hollie",
        "Lisa",
        "Max",
        "Maxwell",
        "Pete",
        "Sanda",
        "Scott",
        "Star",
        "Stefan",
        "Travis",
        "Vida",
        "Warren",
        "Whiro",
        "New Staff"
      ],
      sort_column: ["name"],
      sort_order: "asc",
    };
  },
  components: {
    basicLayout,
    OrderManagementAddNote,
    ListIcon,
    ArchiveIcon,
    PlayCircleIcon,
    StopCircleIcon,
    ChevronUpIcon,
    ChevronDownIcon,
  },
  mixins: [orders_mixins, filter_mixins, utility_mixins],

  firestore() {
    return {
      // lines: db.collection("lines").where("is_outstanding", "==", true),
      daily_lists: db
        .collection("daily_lists")
        .where("is_archived", "==", false),
    };
  },

  methods: {
    priority_increase(index) {
      console.log(index);
    },
    sort_by(column) {
      console.log("sorting by " + column);
      this.sort_column = column;
      this.sort_order = this.sort_order === "asc" ? "desc" : "asc";
    },
    make_daily_list: async function () {
      let linesQuerySnapshot = await lines.get();
      let display_lines = linesQuerySnapshot.docs.map((doc) => {
        let line_data = doc.data();
        line_data.id = doc.id;
        return line_data;
      });

      console.log(display_lines.length);

      let list = display_lines
        .filter((l) => this.handling(l))
        .map((line) => {
          const line_tally = {
            // priority: index,
            name_code: line.name_code,
            name: line.name,
            picking: line.picking,
            picked: line.picked,
            outstanding: Math.max(
              Number(line.quantity) - Number(line.shipped),
              0
            ),
            processing: Math.max(Number(line.picking) + Number(line.picked), 0),
            allocated: Math.max(Number(line.allocated), 0),
            price: Number(line.price) || 0,
            price_picked: 0,
            price_outstanding: 0,
            price_picking: Number(line.picking) * Number(line.price),
          };
          return line_tally;
        })
        .reduce((result, item) => {
          const existing = result.find(
            (line) => line.name_code === item.name_code
          );
          if (existing) {
            existing.picking += Number(item.picking);
            existing.picked += Number(item.picked);
            existing.outstanding += Number(item.outstanding);
            existing.processing += Number(item.processing);
            existing.allocated += Number(item.allocated);
            // existing.price_picked +=
            //   Number(item.picked) * Number(item.price) || 0;
            // existing.price_outstanding +=
            //   Number(item.outstanding) * Number(item.price) || 0;
            existing.price_picking += Number(item.price_picking);
          } else {
            // item.priority = Math.max.apply(
            //   null,
            //   result.map((e) => e.priority)
            // );
            result.push(item);
          }
          return result;
        }, [])
        .map((item, index, array) => {
          item.is_complete = false;

          // item.priority =
          //   item.priority ||
          //   Math.max.apply(
          //     null,
          //     array.map((i) => i.priority)
          //   ) ||
          //   array.length;

          const existing = this.daily_lists
            .filter((a) => !a.is_archived)
            .find((a) => a.name_code == item.name_code);

          if (existing) {
            console.log(existing);
            console.log(item);
            console.log("---------");
            return db.collection("daily_lists").doc(existing.id).update(item);
          } else {
            let new_actions = {
              date_created: new Date(),
              assigned_users: [],
              start_time: null,
              end_time: null,
              is_certified_complete: false,
              certified_complete_by: null,
              is_complete: false,
              is_archived: false,
            };

            return db
              .collection("daily_lists")
              .add({ ...item, ...new_actions });
          }
        });

      // console.table(list);
      return list;
    },

    delete_item: async function (item) {
      console.log("DELETING ITEM: " + item.id);
      return await db.collection("daily_lists").doc(item.id).delete();
    },

    archive_item: async function (item) {
      console.log("COMPLETING ITEM: " + item.id);

      return await db
        .collection("daily_lists")
        .doc(item.id)
        .update({ is_complete: true, is_archived: true });
    },

    update_line_actions: async function (item) {
      console.log("updating line actions for " + item.id);

      console.log("UPDATING");
      await db
        .collection("daily_lists")
        .doc(item.id)
        .update({ assigned_users: item.assigned_users });
    },

    set_start_time: async function (item) {
      console.log("updating line actions for " + item.id);
      console.log(item.id);

      // let snapshot = await this.make_picking_snapshot(item);

      // console.log(snapshot);

      await db
        .collection("daily_lists")
        .doc(item.id)
        .update({ start_time: new Date() });
    },

    make_picking_snapshot: async function (item) {
      let list = this.lines.filter(
        (l) => this.handling(l) && l.name_code === item.name_code
      );

      let tally = list
        .map((line) => {
          const line_tally = {
            name_code: line.name_code,
            name: line.name,
            picking: line.picking,
            picked: line.picked,
            outstanding: Math.max(
              Number(line.quantity) - Number(line.shipped),
              0
            ),

            processing: Math.max(Number(line.picking) + Number(line.picked), 0),
            allocated: Math.max(Number(line.allocated), 0),
          };
          return line_tally;
        })
        .reduce((result, item) => {
          const existing = result.find(
            (line) => line.name_code === item.name_code
          );
          if (existing) {
            existing.picking += Number(item.picking);
            existing.picked += Number(item.picked);
            existing.outstanding += Number(item.outstanding);
            existing.processing += Number(item.processing);
            existing.allocated += Number(item.allocated);
          } else {
            result.push(item);
          }
          return result;
        }, []);

      return { list, tally };
    },

    set_finish_time: async function (item) {
      console.log("updating line actions for " + item.id);
      console.log(item.id);

      await db
        .collection("daily_lists")
        .doc(item.id)
        .update({ finish_time: new Date(), is_complete: true });
    },

    debounce_search(event) {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.search = event.target.value;
      }, 600);
    },
    filter_by(filter) {
      this.search = filter;
    },
    clear_search() {
      this.search = "";
    },
    sort_by(column) {
      this.sort_column = column;
      this.sort_order = this.sort_order === "asc" ? "desc" : "asc";
    },
    pick_lines(name_code) {
      this.$router.push({
        name: "PickingCustomerLines",
        params: { name_code: name_code },
      });
    },

    short_time(date) {
      const m = moment(date.toDate());
      return moment(m).format("LT");
    },
    days_ago(date) {
      const m = moment(date.toDate());
      return moment(m).fromNow();
    },

    duration(start, finish) {
      if (start && finish) {
        let s = moment(start.toDate());
        let f = moment(finish.toDate());
        const duration = moment.duration(f.diff(s));
        const interval = duration.humanize();
        return interval;
      }
      return 0;
    },

    add_note_modal(item) {
      this.selected_item = item;
      this.show_add_note = true;
    },

    update_notes: async function (value) {
      console.log("updating note: " + value);
      console.log(this.selected_item.id);
      await db
        .collection("daily_lists")
        .doc(this.selected_item.id)
        .update({ notes: value });

      this.show_add_note = false;
    },

    close_add_note() {
      console.log("closing");
      this.show_add_note = false;
    },
  },
  computed: {
    filtered: function () {
      let visible_lines = [...this.daily_lists];

      const search = this.search;

      let sort_column = this.sort_column;

      if (sort_column) {
        visible_lines = orderBy(
          visible_lines,
          ["priority", sort_column] || "",
          ["asc", this.sort_order]
        );
      }

      visible_lines = visible_lines.filter((line) => {
        const s = line.name + line.name_code;
        const su = s.toUpperCase();
        return su.match(search.toUpperCase());
      });

      return visible_lines;
    },

    lines_to_pick() {
      return this.lines?.filter((l) => this.handling(l));
    },
  },
};
</script>

<style scoped>
.tableFixHead {
  font-size: 1rem;
}

.tableFixHead td {
  font-size: 1rem;
}

.tableFixHead th {
  font-size: 1rem;
  cursor: pointer;
}
</style>
