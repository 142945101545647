<template>
  <basicLayout>
    <!-- <div class="container p-2"> -->
    <h5>ORDER FORM</h5>
    <form>
      <div class="card m-2">
        <div class="card-body">
          <div class="form-group col-md-12">
            <div class="form-row mb-1">
              <div class="col-4">
                <label>Name Code</label>
                <v-select
                          v-model="selected_name"
                          :options="names_search"
                          ref="autocomplete_names"
                          :selectOnTab="true"
                          :clearable="false"
                          :disabled="order_lines[0].product.code != ''"></v-select>
              </div>

              <div class="col">
                <label>Price Level</label>
                <div
                     type="text"
                     class="form-control form-control-sm">
                  {{ selected_name.productpricing }}
                </div>
              </div>
              <div class="col">
                <label for="lyric_language"> Type </label>
                <select
                        v-model="type"
                        class="custom-select custom-select-sm d-block">
                  <option>Urgent</option>
                  <option>Catalogue</option>
                  <option>New Release</option>
                  <option>Website</option>
                  <option>Promo</option>
                  <option>Staff</option>
                </select>
              </div>
              <div class="col">
                <label>PO Ref</label>
                <input
                       v-model="po_number"
                       type="text"
                       class="form-control form-control-sm"
                       placeholder="PO Number"
                       required />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card m-2">
        <div class="card-body">
          <div class="form-group col-md-12">
            <div class="form-row mb-1">
              <div class="col-5">
                <label>Code</label>
              </div>
              <div class="col text-center">
                <label>Available</label>
              </div>
              <!-- <div class="col text-center">
                <label>SOPO</label>
              </div> -->
              <div class="col text-center">
                <label>Quantity</label>
              </div>
              <div class="col text-center">
                <label>Allocate</label>
              </div>
              <div class="col text-center w-5">
                <label>Lock</label>
              </div>
              <div class="col text-center">
                <label>Price</label>
              </div>
              <div class="col text-center">
                <label>Discount %</label>
              </div>
              <div class="col text-center">
                <label>Extension</label>
              </div>
              <div class="col text-center">
                <label>Total</label>
              </div>

              <div class="col"></div>
            </div>
            <div
                 v-for="(line, i) in order_lines"
                 :key="line.id">
              <div
                   class="form-row mb-1"
                   :class="lineClass(line)">
                <div class="col-5">
                  <v-select
                            @search="(query) => debounce_search(query)"
                            @input="(product) => set_price(line, product)"
                            v-model="line.product"
                            :filter="algolia_search"
                            :selectOnTab="true"
                            :clearable="false">
                    <template #option="{ _highlightResult }">
                      <div :class="prod_class(_highlightResult)">
                        <span v-html="_highlightResult.description.value"></span>
                        | <span v-html="_highlightResult.code.value"></span> |
                        <span v-html="_highlightResult.barcode.value"></span>
                      </div>
                    </template>
                  </v-select>
                </div>
                <div
                     class="text-muted"
                     title="copy product info to clipboard">
                  <copy-icon
                             @click="copy_line_to_clipboard(line)"
                             size="1.2x"></copy-icon>
                </div>
                <div class="col">
                  <div
                       type="number"
                       class="form-control form-control-sm text-right">
                    {{ available(line.product) }}
                  </div>
                </div>
                <!-- <div class="col">
                  <div
                    type="number"
                    class="form-control form-control-sm text-right"
                  >
                    {{ line.product.sopo }}
                  </div>
                </div> -->
                <v-popover
                           v-if="line.product.code"
                           placement="left"
                           offset="8">
                  <info-icon size="1.2x"></info-icon>
                  <template slot="popover">
                    <ProductPopover
                                    :product_code="line.product.code"
                                    :price_level="selected_name.productpricing"></ProductPopover>
                  </template>
                </v-popover>
                <div
                     v-else
                     class="text-muted">
                  <info-icon size="1.2x"></info-icon>
                </div>
                <div class="col">
                  <input
                         v-model.number="line.quantity"
                         type="number"
                         class="form-control form-control-sm text-right"
                         placeholder="Qty"
                         required />
                </div>
                <div class="col">
                  <input
                         v-model.number="line.allocated"
                         type="number"
                         class="form-control form-control-sm text-right"
                         placeholder="LAoocate"
                         required
                         tabindex="-1" />
                </div>
                <div class="col text-center w-5">
                  <div class="form-check">
                    <input
                           v-model="line.locked"
                           type="checkbox"
                           class="form-check-input"
                           tabindex="-1" />
                  </div>
                </div>
                <div class="col">
                  <input
                         @dblclick="editing_price = !editing_price"
                         v-model.number="line.price"
                         type="number"
                         class="form-control form-control-sm text-right"
                         tabindex="-1" />
                </div>
                <div class="col">
                  <input
                         v-model.number="line.discount"
                         type="number"
                         class="form-control form-control-sm text-right"
                         tabindex="-1" />
                </div>
                <div class="col">
                  <div
                       type="number"
                       class="form-control form-control-sm text-right">
                    {{ extension(line) }}
                  </div>
                </div>
                <div class="col">
                  <div
                       type="number"
                       class="form-control form-control-sm text-right">
                    {{ total(line) }}
                  </div>
                </div>

                <div class="col">
                  <button
                          @click.prevent="new_line(i)"
                          @keydown.tab="new_line(i)"
                          class="btn btn-sm btn-outline-success mr-1"
                          title="New line">
                    <corner-down-left-icon
                                           size="1.2x"
                                           class="custom-class"></corner-down-left-icon>
                  </button>
                  <button
                          @click.prevent="delete_line(i)"
                          class="btn btn-sm btn-outline-danger"
                          tabindex="-1"
                          title="Delete line">
                    <trash-icon
                                size="1.2x"
                                class="custom-class"></trash-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group col-md-12">
            <div class="btn-toolbar m-2">
              <button
                      @click.prevent="enter_order"
                      class="btn btn-sm btn-outline-success mr-2">
                Submit Order
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
    <div
         v-if="selected_name.name"
         class="row m-2">
      <button
              @click="show_order_lines = !show_order_lines"
              class="btn btn-sm btn-outline-secondary mr-2">
        {{ show_order_lines ? "Hide Order Lines" : "Show Order Lines" }}
        <!-- <span class="badge badge-light">{{ order_lines.length }}</span> -->
      </button>
    </div>
    <div
         v-if="show_order_lines"
         class="m-2">
      <OrderLines
                  :name="selected_name"
                  filter="name"
                  parent="order_form"></OrderLines>
    </div>
  </basicLayout>
</template>

<script>
import { db, fb } from "@/firebaseConfig";
import algoliasearch from "algoliasearch/lite";
import { actions } from "@/store";
import basicLayout from "@/components/basicLayout";
import OrderLines from "@/components/OrderLines";
import ProductPopover from "@/components/ProductPopover";
import price_list_mixins from "@/mixins/price_list_mixins";
import { InfoIcon, CopyIcon, CornerDownLeftIcon, TrashIcon } from "vue-feather-icons";

const rules = db.collection("rules");
const lines = db.collection("lines");

export default {
  name: "OrderForm",
  data() {
    return {
      po_number: "",
      type: "Catalogue",
      selected_name: { code: "", name: "", label: "", productpricing: "" },
      order_lines: [
        {
          id: "",
          product: {
            id: "",
            code: "",
            label: "",
            description: "",
            sellprice_a: 0,
          },
          stock_code: "",
          description: "",
          price: 0,
          quantity: 1,
          allocated: 0,
          locked: false,
          shipped: 0,
          discount: 0,
          extension: 0,
          total: 0,
        },
      ],
      names: [],
      lines: [],
      rules: [],
      line_errors: [],
      line_key: 0,
      search: "",
      show_order_lines: false,
      index: null,
      searchClient: algoliasearch("QDXUPVRDAC", "6edf124cf2acf6b7e34bc21e1b3e2964"),
      algolia_hits: {},
      algolia_options: [],
      // algolia_search: [],
      editing_price: false,
      debounce: null,
    };
  },
  components: {
    basicLayout,
    OrderLines,
    InfoIcon,
    CopyIcon,
    ProductPopover,
    CornerDownLeftIcon,
    TrashIcon,
  },
  mixins: [price_list_mixins],

  firestore() {
    return {
      names: db.collection("names").where("deleted", "==", false),
    };
  },
  created: async function () {
    this.index = this.searchClient.initIndex("products");
    this.algolia_hits = await this.index.search("", {
      filters: "deleted:false",
    });
  },

  watch: {
    selected_name: function (new_name) {
      if (new_name && new_name.has_rule) {
        this.$bind("rules", rules.where("name_code", "in", [new_name.code, new_name.head_office, new_name.group]));
        this.$bind("lines", lines.where("name_code", "==", new_name.code));
      }
    },
    search: {
      handler: async function (search) {
        this.algolia_hits = await this.index.search(search);
      },
    },
  },
  methods: {
    debounce_search(search) {
      console.log(search);
      let delay = 200;
      clearTimeout(this.debounce);
      if (search.length > 4) delay = 0;
      console.log("delay: " + delay);
      this.debounce = setTimeout(() => {
        this.search = search;
      }, delay);
    },
    algolia_search() {
      return this.algolia_hits.hits
        .filter((product) => product.colour != 7 || this.selected_name.code.includes("050"))
        .map((product) => {
          product.label = `${product.description}  |  ${product.code} | ${product.barcode}`;
          product.id = product.objectID;
          product.stock_code = product.code;
          return product;
        });
    },

    prod_class(line) {
      if (line.colour.value == 1) return "text-orange";
      if (line.colour.value == 2) return "text-red";
      if (line.colour.value == 3) return "text-magenta";
      if (line.colour.value == 4) return "text-cyan";
      if (line.colour.value == 5) return "text-blue";
      if (line.colour.value == 6) return "text-green";
      if (line.colour.value == 7) return "text-brown";
    },

    // fetchAlgoliaOptions: async function (search) {
    //   const algolia_hits = await this.index.search(search, {
    //     filters: `deleted:false`,
    //   });
    //   this.algolia_options = algolia_hits.hits
    //     .filter(
    //       (product) =>
    //         product.colour != 7 || this.selected_name.code.includes("050")
    //     )
    //     .map((product) => {
    //       product.label = `${product.description}  |  ${product.code} | ${product.barcode}`;
    //       product.id = product.objectID;
    //       product.stock_code = product.code;
    //       return product;
    //     });
    // },
    // algolia_filter(options, search) {
    //   console.log(search);
    //   return options;
    // },

    set_price(line, product) {
      if (!product) return;

      // console.log(this.type.toUpperCase());

      // SET PRICE TO ZERO FOR PROMO ORDERS
      // if (this.type.toUpperCase() === "PROMO") {
      //   line.price = 0;
      //   return;
      // }

      const applied_rules = this.applied_rules;
      const product_pricing = this.sell_price;

      let prevailing_price;

      const product_rule = applied_rules.find((rule) => {
        return rule.product_code === product.code || rule.product_code === "ALL";
      });

      if (product_rule) {
        console.log("rule: " + product_rule);
      }
      // APPLY DISCOUNT TO DISCOUNT COLUMN
      if (
        product_rule &&
        product_rule.discount &&
        product.selldiscountmode === 2 // MoneyWorks - apply discount by customer
      ) {
        console.log("APPLY DISCOUNT");
        line.discount = product_rule.discount;

        // prevailing_price =
        //   product[product_pricing] * (1 - product_rule.discount / 100);
      } else if (product_rule && product_rule.price) {
        prevailing_price = product_rule.price;
      } else {
        prevailing_price = product[product_pricing];
      }

      console.log("SELL PRICE: " + prevailing_price);
      product.sell_price = prevailing_price;
      line.price = prevailing_price;
    },
    extension(line) {
      const discount = line.discount || 0;
      return (line.price * (1 - discount / 100) || 0).toFixed(2);
    },

    total(line) {
      const discount = line.discount || 0;
      return (line.price * (1 - discount / 100) * line.quantity || 0).toFixed(2);
    },

    new_line(i) {
      console.log("i: " + i);
      console.log("this.order_lines.length: " + this.order_lines.length);
      this.order_lines.splice(i + 1, 0, {
        product: {
          id: "",
          code: "",
          label: "",
          description: "",
          barcode: "",
          custom7: "", // alternate barcode
          sell_price: 0,
          sellprice_a: 0,
          rules: [],
          category1: "", // format
          custom8: "", // origin
        },
        stock_code: "",
        description: "",
        quantity: 1,
        allocated: 0,
        locked: false,
        shipped: 0,
        price: 0,
        discount: 0,
        extension: 0,
        total: 0,
      });
    },
    delete_line(i) {
      if (this.order_lines.length === 1) {
        return (this.order_lines = [
          {
            id: "",
            product: {
              id: "",
              code: "",
              label: "",
              description: "",
              sellprice_a: 0,
              category1: "", // format
              custom8: "", // origin
            },
            stock_code: "",
            description: "",
            price: 0,
            quantity: 1,
            allocated: 0,
            locked: false,
            shipped: 0,
            discount: 0,
            extension: 0,
            total: 0,
          },
        ]);
      }
      this.order_lines.splice(i, 1);
    },
    check_order() {
      console.log("checking order");
      // CHECK THAT ALL RELEVENT FIELDS ARE FILLED IN
      if (!this.selected_name.code) {
        this.$toast.error("Name Required", {
          position: "top-right",
          timeout: 5000,
        });
        return false;
      }
      if (!this.po_number) {
        this.$toast.error("PO Required", {
          position: "top-right",
          timeout: 5000,
        });
        return false;
      }
      if (!this.type) {
        this.$toast.error("Type Required", {
          position: "top-right",
          timeout: 5000,
        });
        return false;
      }

      // LOOP THROUGH LINES TO CHECK ALL VALUES ARE VALID
      this.line_errors = [];
      let line_errors = false;
      this.order_lines.forEach((o, i) => {
        if (!o.product) {
          this.line_errors.push({ line: i, error: "Product required." });
          line_errors = true;
        }
        if (o.quantity < 1) {
          this.line_errors.push({ line: i, error: "Quantity required." });
          line_errors = true;
        }
      });

      if (line_errors) {
        alert("errors");
        return false;
      }
      return true;
    },

    enter_order: async function () {
      if (!this.check_order()) return;

      actions.setLoading(true);

      const po_number = this.po_number;
      const order_type = this.type;
      const name_code = this.selected_name.code;
      const name = this.selected_name.name;
      const created = new Date();

      const order = {
        po_number,
        type: order_type,
        name_code,
        name,
        created,
        is_outstanding: true,
        ftp_id: null,
      };

      const new_order = await db.collection("orders").add(order);
      console.log(new_order.id);

      const order_line_promises = this.order_lines
        .filter((ol) => ol.product.code && ol.quantity > 0)
        .map(async (ol) => {
          const new_line = {
            order_id: new_order.id,
            po_number,
            name_code,
            name,
            created,
            order_type,
            product: {
              label: ol.product.label,
              code: ol.product.code.toString(),
              description: ol.product.description,
              deleted: ol.product.deleted,
              supplier: ol.product.supplier,
              barcode: ol.product.barcode,
              custom7: ol.product.custom7 || "", // alternate barcode
              category1: ol.product.category1 || "", // format
              custom8: ol.product.custom8 || "", // origin
            },
            deleted_title: ol.product.deleted,
            stock_code: ol.product.code.toString(),
            description: ol.product.description,
            price: ol.price,
            discount: ol.discount,
            quantity: ol.quantity,
            allocated: ol.allocated,
            category1: ol.product.category1 || "",
            supplier: ol.product.supplier,
            barcode: ol.product.barcode,
            custom7: ol.product.custom7 || "", // alternate barcode

            locked: ol.locked || ol.product.locked || false, // lock line containing locked product

            line_sequence_number: null, // required for whl orders
            import_price: null, // required for whl orders
            // allocated: 0,
            picking: 0,
            picked: 0,
            shipped: 0,
            is_complete: false,
            is_outstanding: true,
            sent_back: false,
            last_update: new Date(),
          };
          console.log("NEW LINE: " + JSON.stringify(new_line));

          const clean_code = new_line.stock_code.split("/").join("|");

          await db.collection("lines").add(new_line);
          await db.collection("products").doc(clean_code).update({ is_outstanding: true });
          await this.update_product(clean_code);
        });
      await Promise.all(order_line_promises);
      actions.setLoading(false);
      this.clear_form();
      this.$toast.success("Order Saved", {
        position: "top-right",
        timeout: 2000,
      });
    },

    update_product(stock_code) {
      const update_product = fb.httpsCallable("sync_update_product");
      update_product(stock_code).then(function (result) {
        console.log(result);
      });
    },

    clear_form() {
      this.po_number = "";
      this.type = "";
      this.$refs.autocomplete_names.search = "";
      this.selected_name = { label: "", name_code: "", name: "" };
      this.order_lines.splice(0, this.order_lines.length);
      this.new_line(0);
    },

    lineClass(line) {
      return { "alert-primary": !line.product || line.quantity < 1 };
    },
    available(product) {
      return (
        (Number(product.stockonhand) || 0) -
        (Number(product.all_allocated) || 0) -
        (Number(product.all_processing) || 0)
      );
    },
    copy_line_to_clipboard: async function (line) {
      const line_string = `${line.product.stock_code}\t${line.product.description}\t${line.product.barcode}\tx ${line.quantity}\t @ $${line.price}`;
      await navigator.clipboard.writeText(line_string);
      console.log(line_string);
    },
  },

  computed: {
    names_search() {
      return this.names
        .filter(n => !n.code.includes("050."))
        .map((n) => {
          n.label = `${n.name} (${n.code})`;
          return n;
        });
    },

    sell_price() {
      if (this.selected_name.productpricing) {
        return `sellprice_${this.selected_name.productpricing.toLowerCase()}`;
      } else {
        return "sellprice_a";
      }
    },
  },
};
// async onSearch(query) {
//   console.log(query + " - " + query.length);
//   if (!query || query.length < 3) {
//     this.address_array = [];
//     return;
//   }
//   let url =
//     "https://api.addy.co.nz/search?key=e7856cd0170f4205bffb4434ea80dd09&secret=e8cd6f81ba5c45de9f75cd3049c52129&max=10&s=" +
//     query;
//   let address_res = await fetch(url);
//   let address_res_json = await address_res.json();
//   this.address_array = address_res_json.addresses;
// },
//   async getAddress(value) {
//     console.log(JSON.stringify(value));

//     let url =
//       "https://api.addy.co.nz/address/" +
//       value.id +
//       "?key=e7856cd0170f4205bffb4434ea80dd09";

//     let address_res = await fetch(url);
//     let add = await address_res.json();

//     console.log(add);
//     const outwards = this.outwards;

//     outwards.address_number = add.number;
//     outwards.address_street = add.street;
//     outwards.address_suburb = add.suburb;
//     outwards.address_city = add.city;
//     outwards.address_postcode = add.postcode;
//     outwards.address_full = add.full;
//   }
</script>
<style scoped>
form {
  font-size: 0.8rem;
}
</style>
<style>
li span em {
  font-style: normal;
  font-weight: bold;
}
</style>
