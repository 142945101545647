<template>
  <div
    ref="confirm_modal"
    class="modal"
    tabindex="-1"
    role="dialog">
    <div
      class="modal-dialog"
      role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ confirm_title }}</h5>
          <button
            type="button"
            class="btn-close text-white"
            @click="modal.hide()"
            aria-label="Close"></button>
        </div>
        <div class="modal-body">
          {{ confirm_message }}
        </div>

        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-outline-secondary"
            @click="_cancel">
            Cancel
          </button>
          <button
            @click="_confirm"
            type="button"
            class="btn btn-outline-secondary">
            {{ confirm_ok_button }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import { Modal } from "bootstrap";

export default {
  data() {
    return {
      confirm_title: undefined,
      confirm_message: undefined, // Main text content
      confirm_ok_button: undefined,
      resolvePromise: undefined,
      rejectPromise: undefined,
      modal: null,
    };
  },

  mounted() {
    this.modal = new Modal(this.$refs.confirm_modal);
    this.modal.hide();
  },

  methods: {
    open_modal() {
      this.get_release_territories();
      this.modal.show();
    },

    show(opts = {}) {
      this.confirm_title = opts.confirm_title;
      this.confirm_message = opts.confirm_message;
      this.confirm_ok_button = opts.confirm_ok_button;
      if (opts.cancelButton) {
        this.cancelButton = opts.cancelButton;
      }
      // Once we set our config, we tell the popup modal to open
      this.modal.show();
      // Return promise so the caller can get results
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },

    _confirm() {
      this.modal.hide();
      this.resolvePromise(true);
    },

    _cancel() {
      this.modal.hide();
      this.resolvePromise(false);
      // Or you can throw an error
      // this.rejectPromise(new Error('User cancelled the dialogue'))
    },
  },
};
</script>
  
  <style scoped>
</style>