<template>
  <div class="card-deck mb-2">
    <div class="card shadow-sm">
      <div class="card-header">
        <h5 class="my-0 font-weight-normal">LOG</h5>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-xl-6">
            <div
              class="btn-toolbar"
              role="toolbar"
              aria-label="Toolbar with button groups"
            >
              <div
                class="btn-group flex-wrap mb-2"
                role="group"
                aria-label="First group"
              >
                <button
                  @click="log_filter('all')"
                  type="button"
                  class="btn btn-sm btn-outline-secondary w-15"
                >
                  all
                </button>
                <button
                  @click="log_filter('error')"
                  type="button"
                  class="btn btn-sm btn-outline-secondary w-15"
                >
                  error
                </button>
                <button
                  @click="log_filter('info')"
                  type="button"
                  class="btn btn-sm btn-outline-secondary w-15"
                >
                  info
                </button>
                <button
                  @click="log_filter('moneyworks')"
                  class="btn btn-sm btn-outline-secondary w-15"
                >
                  moneyworks
                </button>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="input-group input-group-sm">
              <div class="input-group-prepend">
                <span class="input-group-text">Search...</span>
              </div>
              <input
                @input="debounce_search"
                :value="search"
                type="text"
                class="form-control"
                placeholder="Code, Name etc."
              />
              <div class="input-group-append">
                <button
                  @click="clear_search"
                  class="btn btn-outline-secondary"
                  type="button"
                >
                  Clear
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="tableFixHead">
          <table class="table table-bordered table-hover table-sm">
            <thead class="thead-dark">
              <tr>
                <th class="text-left" scope="col">Date</th>
                <th class="text-left" scope="col">Message</th>
              </tr>
            </thead>
            <tbody v-for="log in filtered" :key="log.id">
              <tr :class="log_class(log)">
                <td class="w-30 text-left">{{ log.time | moment_long }}</td>
                <td class="w-70 text-left">{{ log.message }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import moment from "moment";
import { fb, db } from '@/firebaseConfig'
import filter_mixins from '@/mixins/filter_mixins'

export default {
  name: 'logs',
  data () {
    return {
      search: '',
      list: [],
      logs: [],
      log_type: 'all'
    }
  },
  mixins: [filter_mixins],
  firestore: {
    logs: db.collection('logs').orderBy('time', 'desc').limit(200)
  },

  methods: {
    debounce_search (event) {
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.search = event.target.value
      }, 200)
    },
    clear_search () {
      this.search = ''
    },
    log_filter (type) {
      if (type) {
        this.log_type = type
      }
    },

    logs_purge () {
      console.log('logs purge')
      const logs_purge = fb.httpsCallable('logs_purge')
      logs_purge().then((result) => {
        console.log(JSON.stringify(result))
      })
    },

    log_class (log) {
      if (log.type == 'info') return 'text-secondary'
      if (log.type == 'error') return 'text-danger'
      if (log.type == 'moneyworks') return 'text-primary'
    },
    ftp_directory () {
      const ftp_dir = fb.httpsCallable('ftp_dir')

      ftp_dir({ text: 'ftp_dir' }).then((result) => {
        console.log(JSON.stringify(result))
        this.list = result.data
      })
    }
  },
  computed: {
    filtered: function () {
      let visible_logs = this.logs.slice()
      const search = this.search

      visible_logs = visible_logs.filter((log) => {
        const s = Object.values(log)
        const su = s.toString().toUpperCase()
        return su.match(search.toString().toUpperCase())
      })

      if (this.log_type === 'all') {
        return visible_logs
      }
      return visible_logs.filter((l) => {
        return l.type === this.log_type
      })
    }
  }
}
</script>

<style scoped>
table {
  font-size: 0.75rem;
}
</style>
