// import { db } from "@/firebaseConfig";
export default {

  methods: {
    outstanding(line) {
      return (line.quantity - line.allocated - line.picking - line.picked - line.shipped) || 0
    },
    available(line) {
      return line.line_product && line.line_product.stockonhand - line.line_product.all_processing || 0
    },
    unallocated(line) {
      return line.line_product && line.line_product.stockonhand - line.line_product.all_processing - line.line_product.all_allocated || 0
    },
    handling(line) {
      return line.picking + line.picked > 0 && line.quantity > line.shipped || 0
    },
    unpicked(line) {
      return (line.quantity - line.allocated - line.picking - line.picked) || 0
    },
    pro_rata(line) {
      if (!line.line_product) return 0
      const outstanding = this.outstanding(line) + line.allocated // add back items already allocated for this calculation of 'outstanding'
      // console.log("OUTSTANDING: " + outstanding)
      if (outstanding < 1) return 0
      const soh = line.line_product && line.line_product.stockonhand || 0
      if (soh < 1) return 0
      const unallocated = this.unallocated(line)
      // console.log("UNALLOCATED: " + unallocated)
      if (unallocated < 1) return 0
      const all_orders = line.line_product && (line.line_product.all_orders + line.stock_on_so) || 0
      // console.log("ALL_ORDERS: " + all_orders)
      if (unallocated >= all_orders) return outstanding

      const pro_rata = Math.floor((unallocated / all_orders) * outstanding)
      // console.log("PRO RATA: " + pro_rata)

      if (pro_rata < 1) return 0
      return Math.min(outstanding, pro_rata) || 0
    },
    all_orders_name(name_code) {
      return this.lines
        .filter(line => {
          return line.name_code == name_code && line.is_outstanding
        })
        .reduce(function (prev, cur) {
          return prev + cur.quantity - cur.picking - cur.picked - cur.shipped
        }, 0)
    },
    all_orders_po(po_number) {
      return this.lines
        .filter(line => {
          return line.po_number == po_number && line.is_outstanding
        })
        .reduce(function (prev, cur) {
          return prev + cur.quantity - cur.picking - cur.picked - cur.shipped
        }, 0)
    },

    can_supply(line) {
      if (!line.line_product) return ''
      if (line.line_product.stockonhand >= line.line_product.all_orders) {
        return 'all'
      } else if (line.line_product.stockonhand < line.line_product.all_orders && line.line_product.stockonhand > 0) {
        return 'some'
      } else if (line.line_product.stockonhand < 1) {
        return 'none'
      }
    },
    cyan(line) {
      if (line.line_product && line.line_product.colour === 4) { return 'text-primary' }
    }
  },
  computed: {

    filtered_quantity() {
      return this.filtered.reduce(function (prev, cur) {
        return Number(prev) + Number(cur.quantity)
      }, 0)
    },
    filtered_shipped() {
      return this.filtered.reduce(function (prev, cur) {
        return Number(prev) + Number(cur.shipped)
      }, 0)
    },
    filtered_allocated() {
      return this.filtered
        .filter(line => line.line_product && line.line_product.colour != 4)
        .reduce(function (prev, cur) {
          return Number(prev) + Number(cur.price * cur.allocated)
        }, 0)
    },
    filtered_picking() {
      return this.filtered.reduce(function (prev, cur) {
        return Number(prev) + Number(cur.picking)
      }, 0)
    },
    filtered_picked() {
      return this.filtered.reduce(function (prev, cur) {
        return Number(prev) + Number(cur.picked)
      }, 0)
    },
    total_price() {
      return this.filtered
        .filter(line => line.line_product && line.line_product.colour != 4)
        .reduce(function (prev, cur) {
          return Number(prev) + Number(cur.price * (cur.quantity - cur.shipped))
        }, 0)
    }
  }
}
