<template>
  <div v-if="order.shipping_address">
    <div class="card">
      <div class="card-header">
        <div class="row">
          <div class="col-6 mb-2">
            <h6>{{ order.store }}: {{ order.order_number }}</h6>

            <address>
              <strong>
                {{ order.shipping_address.first_name }}
                {{ order.shipping_address.last_name }}</strong
              >
              <br />

              {{ order.shipping_address.address1
              }}<span v-if="order.shipping_address.address1">,</span>
              {{ order.shipping_address.address2
              }}<span v-if="order.shipping_address.address2">,</span>
              {{ order.shipping_address.city
              }}<span v-if="order.shipping_address.city">,</span>
              {{ order.shipping_address.state
              }}<span v-if="order.shipping_address.state">,</span>
              {{ order.shipping_address.postcode || order.shipping_address.zip
              }}<span
                v-if="
                  order.shipping_address.postcode || order.shipping_address.zip
                "
                >,</span
              >

              {{ order.shipping_address.country
              }}<span v-if="order.shipping_address.country">,</span>
              {{ order.shipping_address.phone }}
            </address>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="tableFixHead col-md-8 col-sm-12">
            <table class="table table-bordered table-hover table-sm">
              <thead class="thead-dark">
                <tr>
                  <th>SKU</th>
                  <th>Description</th>
                  <th>Quantity</th>
                  <th>Weight</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="line in order.line_items" :key="line.id">
                  <td>{{ line.sku }}</td>
                  <td>
                    {{ line.description }}
                    <span v-if="line.variant_title"
                      >- {{ line.variant_title }}</span
                    >
                  </td>
                  <td>{{ line.quantity }}</td>
                  <td>{{ line.weight }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div v-if="order.shipping_address" class="row">
          <div class="col">
            <button
              type="button"
              class="btn btn-sm btn-outline-secondary mr-2"
              @click="pdfgen(order)"
            >
              <clipboard-icon size="1.5x" class="custom-class"></clipboard-icon>
              &nbsp; Packing Slip
            </button>
            <button
              type="button"
              class="btn btn-sm btn-outline-success mr-2"
              @click="ship_order(order)"
            >
              <truck-icon size="1.0x" class="custom-class"></truck-icon>
              &nbsp; Ship Order
            </button>
            <button
              type="button"
              class="btn btn-sm btn-outline-success mr-2"
              :disabled="!order.shipped || order.complete"
              @click="complete_order(order)"
            >
              <check-square-icon
                size="1.0x"
                class="custom-class"
              ></check-square-icon>
              &nbsp; Complete Order
            </button>
          </div>
          <!-- <Pagination :pages="pages" v-on:updatePage="updatePage" /> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { fb, db } from '@/firebaseConfig'
import filter_mixins from '@/mixins/filter_mixins'
import pdfgen_mixins from '@/mixins/dropship_pdfgen'
import { TruckIcon, CheckSquareIcon, ClipboardIcon } from 'vue-feather-icons'

export default {
  name: 'dropship_order',
  props: {
    order: Object
  },
  data () {
    return {}
  },
  components: {
    TruckIcon,
    CheckSquareIcon,
    ClipboardIcon
  },
  mixins: [filter_mixins, pdfgen_mixins],

  created () {
    this.search =
      this.$route.params.rule_id || this.$route.params.name_code || ''
  },

  methods: {
    ship_order: async function (order) {
      const dropship_starshipit = fb.httpsCallable('dropship_starshipit')
      const dropship_starshipit_res = await dropship_starshipit(order)

      console.log(dropship_starshipit_res)

      if (dropship_starshipit_res.data.success) {
        db.collection('dropship_orders')
          .doc(order.id)
          .update({ shipped: true, starshipit_error: null })
        this.$toast.success('Order Shipped', {
          position: 'top-right',
          timeout: 2000
        })
      } else {
        db.collection('dropship_orders')
          .doc(order.id)
          .update({
            shipped: false,
            starshipit_error: dropship_starshipit_res.data.error
          })
        this.$toast.error(
          'Order Was Not Shipped: ' +
            dropship_starshipit_res.data.error[0].message +
            '\n' +
            dropship_starshipit_res.data.error[0].details,
          {
            position: 'top-right',
            timeout: 2000
          }
        )
      }
    },

    complete_order (order) {
      db.collection('dropship_orders')
        .doc(order.id)
        .update({ complete: true })
    }
  }
}
</script>

<style scoped>
</style>
