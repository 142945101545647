<template>
  <basicLayout>
    <h5>ORDERS BY PRODUCT</h5>
    <div class="row">
      <div class="col-xl-3">
        <div class="input-group input-group-sm">
          <div class="input-group-prepend">
            <span class="input-group-text">Search...</span>
          </div>
          <input
            @input="debounce_search"
            :value="search"
            type="text"
            class="form-control"
            placeholder="Code or Name" />
          <div class="input-group-append">
            <button
              @click="clear_search"
              class="btn btn-outline-secondary"
              type="button">
              Clear
            </button>
          </div>
        </div>
      </div>

      <div class="col-xl-3 mb-2">
        <div class="btn-group btn-group-sm mr-3">
          <button
            @click="can_supply_filter = 'all'"
            type="button"
            class="btn btn-sm alert-success">
            all
          </button>
          <button
            @click="can_supply_filter = 'some'"
            type="button"
            class="btn btn-sm alert-warning">
            some
          </button>
          <button
            @click="can_supply_filter = 'none'"
            type="button"
            class="btn btn-sm alert-danger">
            none
          </button>
          <button
            @click="can_supply_filter = 'clear'"
            type="button"
            class="btn btn-sm alert-secondary">
            clear
          </button>
        </div>
      </div>

      <div class="col-xl-6 mb-2">
        <div class="row">
          <div class="col-xl-6 mb-2">
            <div class="input-group input-group-sm mb-3">
              <div class="input-group-prepend">
                <span
                  class="input-group-text"
                  id="inputGroup-sizing-sm"
                  >From</span
                >
              </div>
              <input
                v-model="filter_date_start"
                type="date"
                class="form-control"
                aria-label="Small"
                aria-describedby="inputGroup-sizing-sm"
                title="Release date - earliest" />
            </div>
          </div>
          <div class="col-xl-6 mb-2">
            <div class="input-group input-group-sm mb-3">
              <div class="input-group-prepend">
                <span
                  class="input-group-text"
                  id="inputGroup-sizing-sm"
                  >To</span
                >
              </div>
              <input
                v-model="filter_date_end"
                type="date"
                class="form-control"
                aria-label="Small"
                aria-describedby="inputGroup-sizing-sm"
                title="Release date - latest" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row m-2">
      <div
        class="col text-right"
        :class="filter_class()">
        <b>Showing: {{ this.filtered.length }}</b>
        <!-- <b>{{ this.products.length }}</b> -->
      </div>
    </div>

    <div class="row m-2">
      <div class="tableFixHead tableFixHeadShort">
        <table class="table table-bordered table-hover table-sm">
          <thead class="thead-dark">
            <tr>
              <th></th>
              <th @click="sort_by('code')">Code</th>
              <th @click="sort_by('description')">Name</th>
              <th class="w-5 text-center">All Orders</th>

              <th
                @click="sort_by('stockonhand')"
                class="w-5 text-center">
                Available
              </th>

              <th
                @click="sort_by('custom2')"
                class="w-10 text-center">
                Release
              </th>
            </tr>
          </thead>

          <tbody>
            <tr
              v-for="product in filtered"
              :key="product.id"
              @click="selectOne(product)"
              @dblclick="open_lines(product)"
              :class="[rowClass(product), prod_class(product)]">
              <td v-if="product.can_supply === 'all'">
                <span
                  title="Can fuflil all orders"
                  class="badge badge-success"
                  >a</span
                >
              </td>
              <td v-else-if="product.can_supply === 'some'">
                <span
                  title="Cannot fulfill all orders"
                  class="badge badge-warning"
                  >s</span
                >
              </td>
              <td v-else-if="product.can_supply === 'none'">
                <span
                  title="Cannot fulfill any orders"
                  class="badge badge-danger"
                  >n</span
                >
              </td>
              <td>{{ product.code }}</td>
              <td>{{ product.description }}</td>
              <td class="text-right">
                {{
                  Number(product.all_orders) +
                  ((product.stock_on_so && Number(product.stock_on_so)) || 0)
                }}
              </td>
              <td class="text-right">
                {{
                  (product && product.stockonhand - product.all_processing) || 0
                }}
              </td>

              <td @dblclick="filter_by(product.custom2)">
                {{ product.custom2 | moment_shortest }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div
      v-if="selectedProduct.id"
      class="bottom-table">
      <hr />
      <OrderLines
        :product="selectedProduct"
        :product_id="selectedProduct.id"
        filter="product"
        :parent_can_supply_filter="can_supply_filter"></OrderLines>
    </div>
  </basicLayout>
</template>

<script>
import { db } from "@/firebaseConfig";
import { orderBy } from "lodash";
import moment from "moment";
import basicLayout from "@/components/basicLayout";
import OrderLines from "@/components/OrderLines";
import filter_mixins from "@/mixins/filter_mixins";
import sort_mixins from "@/mixins/sort_mixins";

const products = db.collection("products");

export default {
  name: "products",
  data() {
    return {
      // products: [],
      display_products: [],
      lines: [],
      search: "",
      debounce: null,
      selectedProduct: {},
      // release_date: new Date(),
      can_supply_filter: "all",
      filter_date_start: "", // moment().subtract(7, "d").format("YYYY-MM-DD"),
      filter_date_end: "", // moment().add(7, "d").format("YYYY-MM-DD"),
      sort_column: "code",
      sort_order: "asc",
      selected_page: 1,
    };
  },
  components: { basicLayout, OrderLines },
  mixins: [sort_mixins, filter_mixins],

  // firestore: {
  //   products: db.collection("products").where("is_outstanding", "==", true),
  // },
  methods: {
    filter_class() {
      if (this.can_supply_filter === "all") {
        return "alert-success";
      }
      if (this.can_supply_filter === "some") {
        return "alert-warning";
      }
      if (this.can_supply_filter === "none") {
        return "alert-danger";
      }
    },
    prod_class(product) {
      if (product.colour == 1) return "text-orange";
      if (product.colour == 2) return "text-red";
      if (product.colour == 3) return "text-magenta";
      if (product.colour == 4) return "text-cyan";
      if (product.colour == 5) return "text-blue";
      if (product.colour == 6) return "text-green";
      if (product.colour == 7) return "text-brown";
    },
    selectOne(product) {
      console.log(product);
      this.selectedProduct = product;
    },
    open_lines(product) {
      this.$router.push({
        name: "OrderLinesByProduct",
        params: { stock_code: product.id },
        query: { can_supply_filter: this.can_supply_filter },
      });
    },
    rowClass(product) {
      return { "alert-primary": this.selectedProduct.id === product.id };
    },

    filter_by(filter) {
      this.search = filter;
    },
    debounce_search(event) {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.search = event.target.value;
      }, 600);
    },
    clear_search() {
      this.search = "";
    },
    sort_by(column) {
      this.sort_column = column;
      this.sort_order = this.sort_order === "asc" ? "desc" : "asc";
    },

    can_supply(product) {
      const all_orders = product.all_orders;
      const stock_on_hand = product.stockonhand;

      if (stock_on_hand >= all_orders) {
        return "all";
      } else if (stock_on_hand < all_orders && stock_on_hand > 0) {
        return "some";
      } else if (stock_on_hand < 1) {
        return "none";
      }
    },
  },

  watch: {
    can_supply_filter: {
      immediate: true,
      handler(can_supply_filter) {
        if (can_supply_filter) {
          let query = products.where("is_outstanding", "==", true);

          if (can_supply_filter && can_supply_filter !== "clear") {
            query = query.where("can_supply", "==", can_supply_filter);
          }

          this.$bind("display_products", query);
        }
      },
    },
  },

  computed: {
    filtered() {
      // let visible_products = this.products.slice();
      let visible_products = this.display_products.slice();
      const search = this.search.toString().toUpperCase();

      // if (this.can_supply_filter) {
      //   visible_products = visible_products.filter((product) => {
      //     return this.can_supply(product) === this.can_supply_filter;
      //   });
      // }

      if (this.filter_date_start) {
        visible_products = visible_products.filter((product) => {
          const release_date = product.custom2.toDate();
          return release_date && release_date >= moment(this.filter_date_start);
        });
      }

      if (this.filter_date_end) {
        visible_products = visible_products.filter((product) => {
          const release_date = product.custom2.toDate();
          return release_date && release_date <= moment(this.filter_date_end);
        });
      }

      visible_products = visible_products.filter((product) => {
        const s = Object.values(product).join("");
        const su = s.toString().toUpperCase();
        return su.match(search);
      });

      if (this.sort_column) {
        visible_products = orderBy(
          visible_products,
          this.sort_column,
          this.sort_order
        );
      }
      return visible_products;
    },

    today() {
      return new Date();
    },
  },
};
</script>
