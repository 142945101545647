<template>
  <basicLayout>
    <div v-if="invoice" class="p-2">
      <h5>INVOICE SEQ #{{ seq }}</h5>
      <h5>{{ invoice.name }} ({{ invoice.name_code }})</h5>
      <hr />
      <h6>CREATED: {{ invoice.created | moment_short }}</h6>
      <h6>FREIGHT: {{ Number(invoice.freight_amount) | currency }}</h6>
      <h6>
        SSID #<span @click="track_order(invoice.ssid)">{{ invoice.ssid }}</span>
      </h6>
      <h6>ITEMS (EXCLUDING LEVY): {{ Number(filtered_invoiced) }}</h6>
    </div>
    <div class="btn-toolbar m-2">
      <a
        href="/invoiced"
        role="button"
        class="btn btn-sm btn-outline-secondary mr-2"
        >Back</a
      >
      <button
        @click="get_form(seq)"
        class="btn btn-sm btn-secondary mr-2"
        type="button"
      >
        Packing Slip
      </button>
    </div>
    <div class="printable-table">
      <div class="tableFixHead">
        <table class="table table-bordered table-hover table-sm">
          <thead class="thead-dark">
            <tr>
              <th @click="sort_by('stock_code', 'string')" class="text-left">
                Code
              </th>
              <th @click="sort_by('stock_code', 'string')" class="text-left">
                Description
              </th>
              <th @click="sort_by('stock_code', 'string')" class="text-left">
                Format
              </th>
              <th @click="sort_by('origin', 'string')" class="text-left">
                Origin
              </th>
              <th @click="sort_by('po_number', 'string')" class="text-left">
                PO Number
              </th>
              <th @click="sort_by('price', 'string')" class="text-left">
                Price
              </th>
              <th @click="sort_by('po_number', 'string')" class="text-left">
                Quantity
              </th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="line in filtered" :key="line.id">
              <td>{{ line.stock_code }}</td>
              <td>{{ line.description }}</td>
              <td>{{ line.format || line.category1 }}</td>
              <td>{{ line.origin || line.custom8 }}</td>
              <td>{{ line.po_number }}</td>
              <td class="text-right">{{ line.price | currency }}</td>
              <td class="text-right">
                {{ line.shipment_quantity || line.invoice_quantity }}
              </td>
            </tr>
          </tbody>
          <tr>
            <td colspan="6" class="font-weight-bold text-right">TOTAL</td>
            <td class="font-weight-bold text-right">{{ filtered_invoiced }}</td>
          </tr>
        </table>
      </div>
    </div>
  </basicLayout>
</template>

<script>
import { db, fb } from '@/firebaseConfig'
import basicLayout from '@/components/basicLayout'
import { orderBy } from 'lodash'
import filter_mixins from '@/mixins/filter_mixins'
import orders_mixins from '@/mixins/orders_mixins'
import utility_mixins from '@/mixins/utility_mixins'

const invoices = db.collection('invoices')

export default {
  name: 'invoiced_lines',
  data () {
    return {
      seq: this.$route.params.seq,
      invoice: {},
      name: {},
      search: '',
      sort_column: ['code'],
      sort_order: 'asc'
    }
  },
  components: { basicLayout },
  mixins: [filter_mixins, orders_mixins, utility_mixins],

  // firestore() {
  //   return {
  //     invoice: db.collection("invoices").doc(this.seq)
  //   };
  // },

  watch: {
    seq: {
      immediate: true,
      handler: function (new_val) {
        console.log('invoice: ' + new_val)
        this.$bind('invoice', invoices.doc(new_val))
      }
    }
  },

  methods: {
    sort_by (column) {
      this.sort_column = column
      this.sort_order = this.sort_order === 'asc' ? 'desc' : 'asc'
    },
    filter_by (filter) {
      this.search = filter
    },
    track_order: async function (ssid) {
      const track_order = fb.httpsCallable('starshipit_track')
      const tracking_res = await track_order(ssid)
      console.log(JSON.stringify(tracking_res))
      const order_data = tracking_res.data.data.order
      console.log(order_data)
      console.log(order_data.packages)
      const tracking_url = order_data.packages[0].tracking_url
      window.open(tracking_url, '_blank')
    }
  },

  computed: {
    filtered () {
      if (!this.invoice.order_lines) {
        return []
      }
      let visible_lines = this.invoice.order_lines.slice()

      if (this.sort_column) {
        visible_lines = orderBy(
          visible_lines,
          (item) => item[this.sort_column] || '',
          this.sort_order
        )
      }
      return visible_lines
    },
    filtered_invoiced () {
      return this.filtered
        .filter((line) => !line.stock_code.includes('LEVY'))
        .reduce(function (prev, cur) {
          return Number(prev) + Number(cur.shipment_quantity)
        }, 0)
    }
  }
}
</script>

<style scoped>
</style>
