<template>
  <basicLayout>
    <OrderLines
      v-if="product.id"
      :product="product"
      filter="product"
      :parent_can_supply_filter="can_supply_filter"
      origin="OrdersByProduct"></OrderLines>
  </basicLayout>
</template>

<script>
import { db } from "@/firebaseConfig";
import basicLayout from "@/components/basicLayout";
import OrderLines from "@/components/OrderLines";

const products = db.collection("products");

export default {
  name: "orderlinesbypo",
  data() {
    return {
      stock_code: this.$route.params.stock_code,
      can_supply_filter: this.$route.query.can_supply_filter,
      product: {},
    };
  },

  components: { basicLayout, OrderLines },
  watch: {
    stock_code: {
      immediate: true,
      handler(stock_code) {
        if (stock_code) {
          const clean_code = stock_code.split("/").join("|");
          this.$bind("product", products.doc(clean_code));
        }
      },
    },
  },
};
</script>
