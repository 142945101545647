<template>
  <div
    v-if="
      edi_order && edi_order.skipped_lines && edi_order.skipped_lines.length
    "
    slot-scope=""
    class="card"
  >
    <div class="card-header">
      <div class="row">
        <div class="col-6 mb-2">
          <h6>SKIPPED LINES</h6>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="tableFixHead col-md-8 col-sm-12">
          <table class="table table-bordered table-hover table-sm">
            <thead class="thead-dark">
              <tr>
                <th @click="sort_by('line')" class="text-left">Line</th>
                <th @click="sort_by('stock_code')" class="text-left">
                  Stock Code
                </th>
                <th @click="sort_by('barcode')" class="text-left">Barcode</th>
                <th @click="sort_by('description')" class="text-left">Title</th>
                <th>Quantity</th>
                <!-- <th></th> -->
              </tr>
            </thead>
            <tbody>
              <tr v-for="line in skipped_lines_sorted" :key="line.line">
                <td class="text-left">{{ line.line }}</td>
                <td class="text-left">{{ line.stock_code }}</td>
                <td class="text-left">{{ line.barcode }}</td>
                <td class="text-left">{{ line.description }}</td>
                <td class="text-left">{{ line.quantity }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { db } from '@/firebaseConfig'
import { orderBy } from 'lodash'

const edi_orders = db.collection('edi')

export default {
  name: 'edi_skipped_lines',
  props: {
    order_id: String
  },
  data () {
    return { edi_order: {} }
  },
  watch: {
    order_id: {
      immediate: true,
      handler: function (new_val) {
        console.log('order: ' + new_val)
        this.$bind('edi_order', edi_orders.doc(new_val))
      }
    }
  },

  methods: {
    sort_by (column) {
      this.sort_column = column
      this.sort_order = this.sort_order === 'asc' ? 'desc' : 'asc'
    }
  },
  computed: {
    skipped_lines_sorted () {
      // return orderBy(this.edi_order.skipped_lines, this.sort_column);
      return (
        (this.edi_order.skipped_lines &&
          orderBy(this.edi_order.skipped_lines, this.sort_column)) ||
        []
      )
    }
  }
}
</script>

<style scoped>
</style>
