<template>
  <basicLayout>
    <h5>CUSTOMERS</h5>
    <div class="row">
      <div class="col-xl-6">
        <div class="input-group input-group-sm">
          <div class="input-group-prepend">
            <span class="input-group-text">Search...</span>
          </div>
          <input
            @input="debounce_search"
            :value="search"
            type="text"
            class="form-control"
            placeholder="Code, Name etc." />
          <div class="input-group-append">
            <button
              @click="clear_search"
              class="btn btn-outline-secondary"
              type="button">
              Clear
            </button>
          </div>
        </div>
      </div>
      <div class="col-6"></div>
    </div>

    <div class="row m-2">
      <div class="col text-right">
        <b>Showing: {{ this.filtered.length }}</b> of
        <b>{{ this.names.length }}</b>
      </div>
    </div>
    <div class="row m-2">
      <div class="tableFixHead tableFixHeadLong">
        <table class="table table-bordered table-hover table-sm">
          <thead class="thead-dark">
            <tr>
              <th @click="sort_by('code')">Code</th>
              <th @click="sort_by('name')">Name</th>
              <th @click="sort_by('head_office')">Head Office</th>
              <th @click="sort_by('group')">Group</th>
              <th @click="sort_by('delivery_suburb')">Address</th>
              <th @click="sort_by('productpricing')">Pricing</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="name in filtered"
              :key="name.id">
              <td>{{ name.code }}</td>
              <td>{{ name.name }}</td>
              <td>{{ name.head_office }}</td>
              <td>{{ name.group }}</td>
              <td>
                {{ name.delivery_street }} {{ name.delivery_suburb }}
                {{ name.delivery_city }} {{ name.delivery_postcode }}
              </td>
              <td>
                {{ name.productpricing }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- <h1>{{selected_page}}</h1> -->
      <Pagination
        v-if="pages"
        :pages="pages"
        :selected_page="selected_page"
        v-on:updatePage="updatePage" />
    </div>
  </basicLayout>
</template>

<script>
import { db } from "@/firebaseConfig";
import { orderBy } from "lodash";
import basicLayout from "@/components/basicLayout";
import Pagination from "@/components/Pagination";
// import sort_mixins from "@/mixins/filter_mixins";
import filter_mixins from "@/mixins/sort_mixins";

export default {
  name: "customers",
  data() {
    return {
      names: [],
      search: "",
      sort_column: ["code"],
      sort_order: "asc",
      page_limit: 300,
      selected_page: 1,
      debounce: null,
    };
  },
  components: { basicLayout, Pagination },
  mixins: [filter_mixins],

  firestore: {
    names: db.collection("names").where("deleted", "==", false),
  },

  created() {
    this.search = this.$route.params.filter || "";
  },

  methods: {
    debounce_search(event) {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.search = event.target.value;
      }, 200);
    },
    updatePage(value) {
      this.selected_page = value;
      this.clear_search();
    },
    sort_by(column) {
      this.sort_column = column;
      this.sort_order = this.sort_order === "asc" ? "desc" : "asc";
    },
    clear_search() {
      this.search = "";
    },
  },
  computed: {
    filtered() {
      let visible_customers = this.names.slice();
      const search = this.search;

      if (this.sort_column) {
        visible_customers = orderBy(
          visible_customers,
          (item) => item[this.sort_column] || "",
          this.sort_order
        );
      }

      // if (!search) {
      //   let start = (this.selected_page - 1) * this.page_limit;
      //   let end = start + this.page_limit;
      //   visible_customers = visible_customers.slice(start, end);
      // }

      visible_customers = visible_customers.filter((customer) => {
        const s = Object.values(customer);
        const su = s.toString().toUpperCase();
        return su.match(search.toString().toUpperCase());
      });

      return visible_customers;
    },

    pages() {
      const pages_array = this.search ? this.filtered : this.names;
      return Math.ceil(pages_array.length / this.page_limit);
    },
  },

  watch: {
    search: function (newSearch) {
      if (newSearch && newSearch.length > 0) {
        this.selected_page = 1;
      }
    },
  },
};
</script>

<style scoped>
</style>
