import Vue from 'vue'
import {
  db
} from '@/firebaseConfig'
import LogRocket from 'logrocket'

export const state = Vue.observable({
  currentUser: {},
  userProfile: {},
  loading: false
})

export const getters = {
  currentUser: () => state.currentUser,
  userProfile: () => state.userProfile,
  isLoading: () => state.loading
}

export const mutations = {
  setCurrentUser: (val) => state.currentUser = val,
  setUserProfile: (val) => state.userProfile = val,
  setLoading: (val) => state.loading = val
}

export const actions = {
  fetchCurrentUserProfile(user) {
    db.collection('users').doc(user.uid).get()
      .then(res => {
        const user_profile = res.data()
        mutations.setUserProfile(user_profile)

        LogRocket.identify(user.uid, {
          name: user_profile.name,
          email: user_profile.email
        })
      })
      .catch(err => {
        console.log(err)
      })
  },
  clearUser() {
    mutations.setUserProfile({})
    mutations.setCurrentUser({})
  },
  setLoading(loadingState) {
    mutations.setLoading(loadingState)
  }
}
