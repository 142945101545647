<template>
  <basicLayout>
    <div class="p-2">
      <h5>RECEIPTS (GOODS INWARDS)</h5>
      <div class="row">
        <div class="col-6">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">Search...</span>
            </div>
            <input
              :value="search"
              type="text"
              class="form-control"
              placeholder="PO, SO, Name, or Carrier"
            />
            <div class="input-group-append">
              <button
                @click="clear_search"
                class="btn btn-outline-secondary"
                type="button"
              >
                Clear
              </button>
            </div>
          </div>
        </div>
        <div class="col-6">
          <button @click="create_receipt" class="btn btn-outline-success">
            New Receipt
          </button>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <div class="btn-toolbar m-2"></div>
        </div>
      </div>
      <div class="tableFixHead" ref="pick_table">
        <table class="table table-bordered table-hover table-sm">
          <thead class="thead-dark">
            <tr>
              <th @click="sort_date(receipts, 'date_received')">Date</th>
              <th @click="sort_string(receipts, 'from')">From</th>
              <th @click="sort_string(receipts, 'ref')">Ref</th>
              <!-- <th class="w-5">Count</th> -->
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="receipt in receipts" :key="receipt.id">
              <td>{{ receipt.created | moment_short }}</td>
              <td>{{ receipt.name }}</td>
              <td>{{ receipt.ref }}</td>
              <!-- <td>{{product.count}}</td> -->
              <td class="text-center">
                <router-link
                  class="btn btn-sm btn-outline-secondary"
                  :to="{
                    name: 'ReceiptEdit',
                    params: { receipt_id: receipt.id },
                  }"
                >
                  <edit-icon size="1x" class="custom-class"></edit-icon
                  >&nbsp;Edit
                </router-link>
              </td>
              <td class="text-center">
                <trash-2-icon
                  @click="delete_receipt(receipt)"
                  size="1x"
                  class="custom-class"
                ></trash-2-icon>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </basicLayout>
</template>

<script>
import { db } from '@/firebaseConfig'
import basicLayout from '@/components/basicLayout'
import { EditIcon, Trash2Icon } from 'vue-feather-icons'
import sort_mixins from '@/mixins/sort_mixins'
import filter_mixins from '@/mixins/filter_mixins'

export default {
  name: 'receipts',
  data () {
    return {
      receipts: [],
      search: '',
      selected_line: null,
      processing: false,
      sort_column: '',
      sort_type: '',
      sort_toggle: 1
    }
  },
  components: { basicLayout, EditIcon, Trash2Icon },
  mixins: [sort_mixins, filter_mixins],

  firestore () {
    return {
      receipts: db.collection('receipts'),
      products_in: db.collection('products_in')
    }
  },
  methods: {
    create_receipt: async function () {
      const new_receipt = await db
        .collection('receipts')
        .add({ created: new Date() })
      const new_receipt_id = new_receipt.id
      this.$router.push({
        name: 'ReceiptEdit',
        params: { receipt_id: new_receipt_id }
      })
    },
    clear_search () {
      this.search = ''
    },
    delete_receipt (receipt) {
      db.collection('receipts').doc(receipt.id).delete()
    }
  }
}
</script>
