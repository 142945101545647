import moment from 'moment'

export default {
  filters: {
    currency: function (number) {
      return (
        number &&
        number.toLocaleString('en-NZ', { style: 'currency', currency: 'NZD' })
      )
    },
    double: function (number) {
      return number && number.toFixed(2)
    },
    date_local_short: function (date) {
      return date && moment(date)
        .format('lll')
    },
    date_local_long: function (date) {
      return date && moment(date)
        .format('LLLL')
    },
    date_short: function (date) {
      return date && moment
        .utc(date)
        .local()
        .format('lll')
    },
    date_long: function (date) {
      return moment(date)
        .local()
        .format('LLLL')
    },
    moment_short: function (date) {
      return date && date.seconds && moment
        .utc(date.toDate())
        .local()
        .format('lll')
    },
    moment_shortest: function (date) {
      return date && date.seconds && moment
        .utc(date.toDate())
        .local()
        .format('DD/MM/YYYY')
    },
    moment_long: function (date) {
      return date && moment
        .utc(date.toDate())
        .local()
        .format('LTS')
    }

  }
}
