<template>
  <basicLayout>
    <h5>ORDERS TO PICK</h5>
    <div class="row mb-2">
      <div class="col-xl-6">
        <div class="input-group input-group-sm">
          <div class="input-group-prepend">
            <span class="input-group-text">Search...</span>
          </div>
          <input
            @input="debounce_search"
            :value="search"
            type="text"
            class="form-control"
            placeholder="PO, SO, Name, or Carrier"
          />
          <div class="input-group-append">
            <button
              @click="clear_search"
              class="btn btn-outline-secondary"
              type="button"
            >
              Clear
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="row mb-2">
      <div class="tableFixHead col">
        <table class="table table-bordered table-hover table-sm">
          <thead class="thead-dark">
            <tr>
              <th @dblclick="sort_date('code')" class="text-left">Code</th>
              <th @dblclick="sort_string('name')" class="text-left">Name</th>
              <!-- <th @dblclick="sort_number('allocated')" class="text-left">
              Allocated
            </th> -->
              <th>Total Outstanding</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr
              @dblclick="pick_lines(name)"
              v-for="name in filtered"
              :key="name.id"
            >
              <td class="text-left">
                {{ name.code }}
                <span
                  v-if="filtered_picked_name(name)"
                  class="badge badge-success"
                  >{{ filtered_picked_name(name) }}</span
                >
              </td>
              <td class="text-left">{{ name.name }}</td>
              <!-- <td class="text-left">{{ filtered_allocated(name) }}</td> -->
              <td class="text-right">
                {{ filtered_outstanding_name(name) | currency }}
              </td>
              <td class="text-center">
                <button
                  @click="pick_lines(name)"
                  type="button"
                  class="btn btn-x-sm btn-outline-success mr-2"
                >
                  <list-icon size="1.0x" class="mr-1"></list-icon>
                  Pick Lines
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </basicLayout>
</template>

<script>
import { db } from "@/firebaseConfig";
import basicLayout from "@/components/basicLayout";
import filter_mixins from "@/mixins/filter_mixins";
import orders_mixins from "@/mixins/orders_mixins";
import utility_mixins from "@/mixins/utility_mixins";
import { ListIcon } from "vue-feather-icons";

export default {
  name: "picking",
  data() {
    return {
      names: [],
      lines: [],
      search: "",
      to_pick_only: true,
    };
  },
  components: { basicLayout, ListIcon },
  mixins: [orders_mixins, filter_mixins, utility_mixins],

  firestore() {
    return {
      names: db.collection("names").where("deleted", "==", false),
      lines: db.collection("lines").where("is_outstanding", "==", true),
    };
  },
  methods: {
    debounce_search(event) {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.search = event.target.value;
      }, 600);
    },
    filter_by(filter) {
      this.search = filter;
    },
    clear_search() {
      this.search = "";
    },
    sort_by(column) {
      this.sort_column = column;
      this.sort_order = this.sort_order === "asc" ? "desc" : "asc";
    },
    pick_lines(name) {
      this.$router.push({
        name: "PickingCustomerLines",
        params: { name_code: name.code },
      });
    },

    // lines_with_picked(name_code) {
    //   return this.lines_to_pick.filter(
    //     line => line.name_code === name_code && line.picked > 0
    //   ).length;
    // }

    filtered_picked_name(name) {
      const lines_for_name = this.lines_to_pick.filter((l) => {
        return l.name_code === name.code;
      });
      return lines_for_name.reduce(function (prev, cur) {
        return Number(prev) + Number(cur.picked);
      }, 0);
    },

    filtered_outstanding_name(name) {
      const lines_for_name = this.lines_to_pick.filter((l) => {
        return l.name_code === name.code;
      });
      return lines_for_name.reduce(function (prev, cur) {
        return (
          Number(prev) + (Number(cur.picking) || 0) * (Number(cur.price) || 0)
          //  (Number(cur.quantity) - Number(cur.shipped)) * Number(cur.price)
        );
      }, 0);
    },
  },
  computed: {
    lines_to_pick() {
      return this.lines.filter((l) => this.handling(l));
    },

    filtered() {
      const name_codes_with_lines = new Set(
        this.lines_to_pick.map((l) => l.name_code)
      );

      const names_with_line_to_pick = this.names.filter((n) => {
        return name_codes_with_lines.has(n.code);
      });

      return names_with_line_to_pick.filter((name) => {
        const s = Object.values(name);
        const su = s.toString().toUpperCase();
        return su.match(this.search.toString().toUpperCase());
      });
    },
  },
};
</script>

<style scoped></style>
