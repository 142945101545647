import moment from 'moment'
import { starshipit_request } from './starshipit_request'

export async function starshipit_tracking() {
  try {
    const method = 'GET'
    const last_week = moment()
      .subtract(4, 'days')
      .toISOString()
    const url = `orders/shipped?since_last_updated=${last_week}&limit=2000`

    let account = "RHYTHMETHOD"

    const options = {
      account, url, method
    }

    const order_res = await starshipit_request(options)
    const order_details = order_res.orders

    return order_details
  } catch (error) {
    console.log(error)
  }
}
