import { orderBy } from 'lodash'
// import moment from "moment"
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import JsBarcode from 'jsbarcode/bin/JsBarcode'
pdfMake.vfs = pdfFonts.pdfMake.vfs
export default {
  methods: {
    pdfgen() {
      let table_data
      // let week_start = moment().startOf("week");
      // let week_end = moment().endOf("week");

      table_data = this.filtered
        .slice()
        .map(line => {
          if (line.category1.includes('CD')) line.format_short = 'CD'
          if (line.category1.includes('VINYL')) line.format_short = 'VINYL'

          if (line.supplier.includes('RHYTHMETHOD')) line.supplier = 'RM'
          if (line.supplier.includes('UNIVERSAL')) line.supplier = 'UNI'

          // let release_date = line.line_product.custom2.seconds * 1000;

          // if (release_date > week_start && release_date < week_end) {
          //   line.new_release = true
          // } else {
          //   line.new_release = true
          // }
          return line
        })

      table_data = orderBy(
        table_data,
        ['supplier', 'format_short', 'description'],
        ['asc', 'asc', 'asc']
      )

      table_data = table_data.reduce((array, line, i) => {
        const {
          supplier,
          category1: format,
          order_type: type,
          stock_code: code,
          barcode,
          description,
          soh,
          picking
        } = line

        const fields = {
          supplier,
          format,
          type,
          code,
          picking,
          barcode,
          description,
          soh,
        }

        if (i === 0) {
          const keys = Object.keys(fields).map(k => {
            return { text: k.toUpperCase(), style: 'tableHeader' }
          })
          array.push(keys)
        }

        const entries = Object.entries(fields).map(([k, v]) => {
          const right = ['soh', 'picking']
          const alignment = right.includes(k) ? 'right' : 'left'
          const bold = k === 'picking' || line.new_release
          const color = k === 'picking' ? 'red' : 'black'
          return { text: v, style: 'tableExample', alignment, bold, color }
        })
        array.push(entries)
        return array
      }, [])

      console.log(JSON.stringify(table_data))
      const dd = {
        pageSize: 'A4',
        pageOrientation: 'landscape',
        content: [
          {
            columns: [
              {
                text: `PICKING LIST FOR ${this.name.name} (${this.name.code})`,
                width: '80%',
                margin: [0, 20, 0, 5],
                style: 'header'
              },
              {
                image: this.textToBase64Barcode(this.name.code),
                alignment: 'right'
              }
            ]
          },
          {
            columns: [
              {
                text: `${new Date().toLocaleString('en-NZ')}`,
                margin: [0, 5, 0, 5],
                alignment: 'left',
                style: 'subheader'
              },
              {
                text: `CD: ${this.filtered_cd} | VINYL: ${this.filtered_vinyl}`,
                margin: [0, 5, 0, 5],
                alignment: 'right',
                style: 'subheader'
              }
            ]
          },

          {
            style: 'tableExample',
            table: {
              widths: [
                'auto',
                'auto',
                'auto',
                'auto',
                'auto',
                'auto',
                '*',
                'auto'
              ],
              headerRows: 1,
              body: table_data
            },
            layout: {
              hLineColor: function () {
                return '#ccc'
              },
              vLineColor: function () {
                return '#ccc'
              },
              hLineWidth: function () {
                return 0.5
              },
              vLineWidth: function () {
                return 0.5
              },
              fillColor: function (rowIndex) {
                return rowIndex % 2 === 0 ? '#ddd' : null
              }
            }
          }
        ],
        styles: {
          header: {
            fontSize: 16,
            bold: true,
            margin: [0, 5, 0, 5]
          },
          subheader: {
            fontSize: 11,
            bold: true,
            margin: [0, 5, 0, 5]
          },
          tableExample: {
            fontSize: 10,
            margin: [0, 0, 0, 0]
          },
          tableHeader: {
            bold: true,
            fontSize: 11,
            color: 'black'
          }
        }
      }
      pdfMake.createPdf(dd).open()
    },
    textToBase64Barcode(text) {
      const canvas = document.createElement('canvas')
      JsBarcode(canvas, text, {
        format: 'CODE39',
        displayValue: false,
        width: 1,
        height: 30
      })
      return canvas.toDataURL('image/png')
    }
  }
}
