<template>
  <basicLayout>
    <div class="row">
      <div class="col-6">
        <h4>{{ user.name }}</h4>
        <div class="card m-2">
          <div class="card-body">
            <form @submit.prevent="save_user">
              <div class="form-group">
                <label for="Name">Name</label>
                <input
                  v-model="user.name"
                  type="text"
                  class="form-control"
                  id="name"
                  placeholder="Name" />
              </div>
              <div class="form-group">
                <label for="exampleInputEmail1">Email address</label>
                <input
                  v-model="user.email"
                  type="email"
                  class="form-control"
                  id="exampleInputEmail1"
                  placeholder="Enter email" />
              </div>

              <div class="form-group">
                <label for="exampleInputEmail1">Can Access</label>

                <v-select
                  v-model="user.access"
                  :options="access_types"
                  multiple
                  :selectOnTab="true"
                  :clearable="true"></v-select>
              </div>

              <button
                type="submit"
                class="btn btn-secondary">
                Save
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </basicLayout>
</template>

<script>
import { db } from "@/firebaseConfig";
import { getters } from "@/store";
import basicLayout from "@/components/basicLayout";

export default {
  name: "userProfile",
  data() {
    return {
      user: {},
      access_types: ["all", "orders", "inwards", "outwards", "dropship"],
    };
  },
  firestore() {
    return {
      user: db.collection("users").doc(this.$route.params.id),
    };
  },
  components: {
    basicLayout,
  },

  methods: {
    save_user() {
      console.log(this.user);
      db.collection("users").doc(this.user.id).set(this.user);
      this.$router.push("/users");
    },
  },
  computed: {
    userProfile() {
      return getters.userProfile();
    },
    isAdmin() {
      return this.userProfile.role === "admin";
    },

    isLoading() {
      return getters.isLoading();
    },
  },
};
</script>
