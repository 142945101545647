<template>
  <div>
    <h5 v-if="filter_label">
      <span
            v-if="origin"
            @click="$router.go(-1)"
            title="back"
            class="mr-3">
        <arrow-left-circle-icon
                                size="1.0x"
                                class="custom-class"></arrow-left-circle-icon></span>{{ filter_label }}
    </h5>
    <div v-if="!allocating_releasing">
      <div class="row">
        <div class="col-xl-3">
          <div class="input-group input-group-sm mb-2">
            <div class="input-group-prepend">
              <span class="input-group-text">Search..</span>
            </div>
            <input
                   @input="debounce_search"
                   :value="search"
                   type="text"
                   class="form-control"
                   placeholder="PO Number, Name, Code or Title" />
            <div class="input-group-append">
              <button
                      @click="clear_search"
                      class="btn btn-outline-secondary"
                      type="button">
                Clear
              </button>
            </div>
          </div>
        </div>

        <div class="col-xl-4 mb-2">
          <div
               class="btn-group btn-group-sm mr-3"
               v-if="filter != 'product'">
            <button
                    @click="can_supply_filter = 'all'"
                    type="button"
                    class="btn btn-sm alert-success w-15">
              all
            </button>
            <button
                    @click="can_supply_filter = 'some'"
                    type="button"
                    class="btn btn-sm alert-warning">
              some
            </button>
            <button
                    @click="can_supply_filter = 'none'"
                    type="button"
                    class="btn btn-sm alert-danger">
              none
            </button>
            <button
                    @click="can_supply_filter = 'clear'"
                    type="button"
                    class="btn btn-sm alert-secondary">
              clear
            </button>
          </div>
          <div class="btn-group btn-group-sm mr-3">
            <button
                    @click="show_allocated = true"
                    type="button"
                    class="btn btn-sm alert-info"
                    :class="{ active: show_allocated }">
              allocated
            </button>
            <button
                    @click="show_handling = true"
                    type="button"
                    class="btn btn-sm alert-info">
              handling
            </button>

            <button
                    @click="clear_filter"
                    type="button"
                    class="btn btn-sm alert-secondary">
              clear
            </button>
          </div>
        </div>
        <div
             v-if="parent !== 'order_form'"
             class="col-xl-5 text-right mb-2">
          <button
                  @click="download_csv()"
                  type="button"
                  class="btn btn-sm btn-outline-secondary mr-1">
            <download-icon
                           size="1.0x"
                           class="custom-class"></download-icon>
            Download
          </button>

          <button
                  @click="get_products()"
                  type="button"
                  class="btn btn-sm btn-outline-secondary mr-1">
            <refresh-cw-icon
                             size="1.0x"
                             class="custom-class"></refresh-cw-icon>
            Refresh
          </button>

          <button
                  @click="verbose = !verbose"
                  type="button"
                  class="btn btn-sm btn-outline-secondary mr-1">
            <info-icon
                       size="1.0x"
                       class="custom-class"></info-icon>
            {{ verbose ? "Summary" : "Detail" }}
          </button>

          <button
                  v-if="name"
                  @click="auto_allocate"
                  title="Allocate all visible, unlocked lines"
                  type="button"
                  class="btn btn-sm btn-outline-secondary mr-1">
            <zap-icon
                      size="1.0x"
                      class="custom-class"></zap-icon>
            Allocate
          </button>
          <button
                  v-if="product"
                  @click="auto_allocate_pro_rata"
                  title="Pro-rata all visible, unlocked lines"
                  type="button"
                  class="btn btn-sm btn-outline-secondary mr-1">
            <zap-icon
                      size="1.0x"
                      class="custom-class"></zap-icon>
            Allocate
          </button>
          <button
                  @click="unallocate"
                  title="Unallocate all visible, unlocked lines"
                  type="button"
                  class="btn btn-sm btn-outline-secondary mr-1">
            <zap-off-icon
                          size="1.0x"
                          class="custom-class"></zap-off-icon>
            Unallocate All
          </button>

          <button
                  v-if="filter === 'name'"
                  @click="release"
                  type="button"
                  class="btn btn-sm btn-success mr-2">
            <share-icon
                        size="1.0x"
                        class="custom-class"></share-icon>
            Release
          </button>
        </div>
      </div>

      <div
           class="row m-2"
           :class="filter_class()">
        <div class="col text-left">
          <span v-if="show_allocated"><b>Allocated</b></span><span
                v-if="show_allocated && show_handling"><b>&nbsp;&amp;&nbsp;</b></span><span
                v-if="show_handling"><b>Handling</b></span>
        </div>
        <div class="col text-right">
          <b>Showing: {{ filtered.length }}</b> of
          <b>{{ display_lines.length }}</b>
        </div>
      </div>
      <div class="row m-2">
        <div class="col text-right">
          <p style="font-size: 0.8em">
            Value:
            {{
              total_price.toLocaleString("en-NZ", {
                style: "currency",
                currency: "NZD",
              })
            }}
            | Allocated &amp; Releasable:
            {{
              filtered_allocated.toLocaleString("en-NZ", {
                style: "currency",
                currency: "NZD",
              })
            }}
          </p>
        </div>
      </div>

      <div class="row m-2">
        <div
             class="tableFixHead tableFixHeadLong"
             :class="table_class()">
          <table class="table table-bordered table-hover table-sm">
            <thead class="thead-dark">
              <tr>
                <th
                    @click="sort_by('can_supply')"
                    class="w-5"></th>
                <th
                    @click="sort_by('created')"
                    class="text-left w-10">
                  Date
                </th>
                <th
                    @click="sort_by('type')"
                    class="text-left w-10">
                  Type
                </th>
                <th
                    v-if="!purchase_order"
                    @click="sort_by('po_number')"
                    class="text-left w-5">
                  PO No.
                </th>
                <th
                    @click="sort_by('po_line_number')"
                    class="text-left w-5">
                  Job Code
                </th>
                <th
                    v-if="!name && !purchase_order"
                    @click="sort_by('name')"
                    class="text-left w-15">
                  To
                </th>
                <th
                    v-if="!product"
                    @click="sort_by('stock_code')"
                    class="text-left w-5">
                  Code
                </th>
                <th
                    v-if="!product"
                    @click="sort_by('description')"
                    class="text-left w-20">
                  Title
                </th>
                <th></th>
                <th
                    @click="sort_by('price')"
                    class="text-left">
                  Price
                </th>
                <th
                    class="text-left"
                    title="Applied discount">
                  Discount
                </th>
                <th
                    @click="sort_by('quantity')"
                    class="text-right">
                  Ordered
                </th>
                <th
                    v-if="verbose"
                    class="text-right">
                  Shipped
                </th>
                <th class="text-right">Backordered</th>
                <th class="text-right">SOH</th>
                <th class="text-right">Available</th>
                <th
                    @click="sort_by('allocated', 'number')"
                    class="text-right">
                  Allocated
                </th>
                <th
                    @dblclick="lock_product_allocations()"
                    title="Double-click to lock all visible lines"></th>

                <th
                    @click="sort_by('picking')"
                    v-if="verbose || show_handling"
                    class="text-right">
                  Handling
                </th>

                <th
                    v-if="verbose"
                    class="text-right">
                  All Orders
                </th>
              </tr>
            </thead>
            <tbody>
              <template v-for="line in filtered">
                <tr
                    :key="line.id"
                    :class="prod_class(line)">
                  <td
                      v-if="line.line_product && line.line_product.can_supply === 'all'
                        "
                      class="text-left">
                    <span
                          title="Can fuflil all orders"
                          class="badge badge-success">
                      {{ line.line_product.can_supply }}</span>
                  </td>
                  <td
                      v-else-if="line.line_product && line.line_product.can_supply === 'some'
                        "
                      class="text-left w-5">
                    <span
                          title="Cannot fulfill all orders"
                          class="badge badge-warning">
                      {{ line.line_product.can_supply }}</span>
                  </td>
                  <td
                      v-else-if="line.line_product && line.line_product.can_supply === 'none'
                        "
                      class="text-left w-5">
                    <span
                          title="Cannot fuflfil any orders"
                          class="badge badge-danger">
                      {{ line.line_product.can_supply }}</span>
                  </td>
                  <td
                      v-else
                      class="text-left w-5">
                    <span
                          title="Cannot fuflfil any orders"
                          class="badge badge-danger">
                      {{
                        line.line_product && line.line_product.can_supply
                      }}</span>
                  </td>

                  <td
                      class="text-left w-10"
                      :title="line.last_update | moment_long">
                    {{ line.created | moment_short }}
                  </td>
                  <td
                      @dblclick="filter_by(line.order_type)"
                      class="text-left d-none d-sm-table-cell w-5">
                    {{ line.order_type }}
                  </td>
                  <td
                      v-if="!purchase_order"
                      @dblclick="filter_by(line.po_number)"
                      class="text-left d-none d-sm-table-cell w-5">
                    {{ line.po_number }}
                    <router-link
                                 class="float-right text-secondary"
                                 :to="{
                                   name: 'OrderEdit',
                                   params: { order_id: line.order_id },
                                 }">
                      <edit-icon
                                 size="1x"
                                 class="custom-class"></edit-icon>
                    </router-link>
                  </td>
                  <td
                      @dblclick="filter_by(line.po_line_number)"
                      class="text-left d-none d-sm-table-cell w-5">
                    {{ line.po_line_number }}
                  </td>
                  <td
                      v-if="!name && !purchase_order"
                      @dblclick="filter_by(line.name_code)"
                      class="text-left w-15">
                    {{ line.name }} ({{ line.name_code }})
                  </td>
                  <td
                      v-if="!product"
                      @dblclick="filter_by(line.stock_code)"
                      class="text-left w-5">
                    {{ line.stock_code }}
                  </td>
                  <td
                      v-if="!product"
                      class="text-left w-20">
                    {{ line.description }}
                  </td>
                  <td>
                    <v-popover
                               v-if="line.stock_code"
                               placement="left">
                      <info-icon size="1.0x"></info-icon>
                      <template slot="popover">
                        <LinePopover :stock_code="line.stock_code"></LinePopover>
                      </template>
                    </v-popover>
                    <div
                         v-else
                         class="text-muted"></div>
                  </td>

                  <td
                      v-if="!editing_price"
                      @dblclick="editing_price = true"
                      class="text-right">
                    {{ line.price | currency }}
                  </td>

                  <td
                      v-if="editing_price"
                      class="w-10">
                    <input
                           v-model="line.price"
                           type="number"
                           class="form-control form-control-x-sm text-right"
                           :disabled="line.locked"
                           @change="update_price(line)"
                           @dblclick="editing_price = false" />
                  </td>

                  <!-- <td class="text-right">{{ line.price | currency }}</td> -->
                  <td class="text-right">{{ line.discount }}</td>
                  <td
                      v-if="!editing_quantity"
                      @dblclick="editing_quantity = true"
                      class="text-right">
                    {{ line.quantity }}
                  </td>

                  <td
                      v-if="editing_quantity"
                      class="w-10">
                    <input
                           v-model="line.quantity"
                           type="number"
                           class="form-control form-control-x-sm text-right"
                           :disabled="line.locked"
                           @change="update_quantity(line)"
                           @dblclick="editing_quantity = false" />
                  </td>

                  <td
                      v-if="verbose"
                      class="text-right">
                    {{ line.shipped }}
                  </td>
                  <td
                      class="text-right"
                      title="Backordered and not yet allocated">
                    {{ outstanding(line) }}
                    <span
                          v-if="line.sent_back"
                          :title="line.pick_notes"
                          class="badge badge-warning">!</span>
                  </td>
                  <td class="text-right">
                    {{
                      (line.line_product && line.line_product.stockonhand) || 0
                    }}
                  </td>
                  <td
                      class="text-right"
                      title="Total units available and unallocated">
                    {{ unallocated(line) }}
                  </td>
                  <!-- <td class="text-right">{{ pro_rata(line) }}</td> -->
                  <td class="w-10">
                    <input
                           v-model="line.allocated"
                           type="number"
                           class="form-control form-control-x-sm text-right"
                           :disabled="updating_allocation || line.locked"
                           @change="allocate(line)" />
                  </td>
                  <td class="text-center">
                    <div class="form-check">
                      <input
                             v-model="line.locked"
                             type="checkbox"
                             class="form-check-input"
                             :title="line.locked_by"
                             @change="lock_allocation(line)" />
                    </div>
                  </td>
                  <td
                      v-if="verbose || show_handling"
                      class="text-right">
                    {{ line.picking + line.picked }}
                  </td>
                  <td
                      v-if="verbose"
                      class="text-right">
                    {{
                      (line.line_product &&
                        line.line_product.all_orders +
                        (line.line_product.stock_on_so || 0)) ||
                      0
                    }}
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { fb, db, auth } from "@/firebaseConfig";
import { orderBy } from "lodash";
import orders_mixins from "@/mixins/orders_mixins";
import filter_mixins from "@/mixins/filter_mixins";
import { actions, getters } from "@/store";
import LinePopover from "@/components/LinePopover";
import moment from "moment";
import Papa from "papaparse";
import {
  InfoIcon,
  EditIcon,
  ShareIcon,
  RefreshCwIcon,
  ZapIcon,
  ZapOffIcon,
  ArrowLeftCircleIcon,
  DownloadIcon,
} from "vue-feather-icons";

const lines = db.collection("lines");

export default {
  name: "orders",
  props: {
    parent: { type: String, default: "orders" },
    origin: String,
    filter: String,
    name: Object,
    product: Object,
    purchase_order: Object,
    parent_can_supply_filter: String,
  },
  data() {
    return {
      display_lines: [],
      products: [],
      search: "",
      debounce: null,
      txt: "",
      verbose: false,
      show_handling: false,
      show_allocated: false,
      sort_column: "code",
      sort_order: "asc",
      locked_products: false,
      can_supply_filter: "all",
      selected_line: {},
      editing_quantity: false,
      editing_price: false,
      updating_allocation: false,
      allocating_releasing: false
    };
  },
  components: {
    InfoIcon,
    EditIcon,
    ShareIcon,
    RefreshCwIcon,
    DownloadIcon,
    ZapIcon,
    ZapOffIcon,
    // StarIcon,
    LinePopover,
    // LoaderIcon,
    // CircleIcon,
    ArrowLeftCircleIcon,
  },
  mixins: [orders_mixins, filter_mixins],

  // GET PRODUCTS ONCE TO PREVENT CONSTANT REFRESHING WHICH REALLY SLOWS DOWN THIS ENORMOUS LIST
  // INCLUDES A 'REFRESH' BUTTON

  created() {
    this.get_products();
  },

  methods: {
    get_products: async function () {
      const productsSnapshot = await db
        .collection("products")
        .where("is_outstanding", "==", true)
        .get();

      const products = productsSnapshot.docs.map((doc) => {
        const product_data = doc.data();
        product_data.id = doc.id;
        return product_data;
      });

      console.log(products[0]);
      console.log("OUSTANDING PRODUCTS: " + products.length);

      this.products = products;
    },
    filter_class() {
      if (this.can_supply_filter === "all") {
        return "alert-success";
      }
      if (this.can_supply_filter === "some") {
        return "alert-warning";
      }
      if (this.can_supply_filter === "none") {
        return "alert-danger";
      }
    },

    prod_class(line) {
      if (line.line_product) {
        if (line.line_product.new_release) return "font-weight-bold text-mint";
        if (line.line_product.colour == 1) return "text-orange";
        if (line.line_product.colour == 2) return "text-red";
        if (line.line_product.colour == 3) return "text-magenta";
        if (line.line_product.colour == 4) return "text-cyan";
        if (line.line_product.colour == 5) return "text-blue";
        if (line.line_product.colour == 6) return "text-green";
        if (line.line_product.colour == 7) return "text-brown";
      }
    },

    table_class() {
      return !this.origin && "tableFixHeadShort";
    },

    lock_allocation(line) {
      const locked_by = (line.locked && this.currentUser.email) || null;
      db.collection("lines")
        .doc(line.id)
        .update({ locked: line.locked, locked_by });
    },
    lock_product_allocations() {
      if (!this.product) return;
      this.lines
        .filter((line) => {
          return line.stock_code === this.product.code;
        })
        .map((line) => {
          const locked_by = (line.locked && this.currentUser.email) || null;
          db.collection("lines").doc(line.id).update({
            locked: !line.locked,
            locked_by,
          });
        });
    },

    filter_by(filter) {
      this.search = filter;
    },
    debounce_search(event) {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.search = event.target.value;
      }, 600);
    },
    clear_search() {
      this.search = "";
    },
    clear_filter() {
      this.show_handling = false;
      this.show_allocated = false;
    },
    sort_by(column) {
      this.sort_column = column;
      this.sort_order = this.sort_order === "asc" ? "desc" : "asc";
    },
    allocate: async function (line) {
      if (line.allocated < 0) {
        line.allocated = 0;
      }

      const outstanding = this.outstanding(line);

      if (outstanding < 0) {
        line.allocated = line.last_allocation;
      }

      await db
        .collection("lines")
        .doc(line.id)
        .update({
          allocated: Number(line.allocated),
          last_allocation: Number(line.allocated),
        });

      await this.update_product(line);
      this.get_products();
    },

    update_quantity: async function (line) {
      if (line.quantity < 0) {
        line.quantity = 0;
        return;
      }
      await db
        .collection("lines")
        .doc(line.id)
        .update({
          quantity: Number(line.quantity),
        });

      await this.update_product(line);
      this.get_products();
    },
    update_price: async function (line) {
      if (line.price < 0) {
        line.price = 0;
        return;
      }
      await db
        .collection("lines")
        .doc(line.id)
        .update({
          price: Number(line.price),
        });

      await this.update_product(line);
      this.get_products();
    },
    // product_allocate() {
    //   const product_allocate = fb.httpsCallable("sync_product_allocate");
    //   product_allocate(this.product.code).then(function (result) {
    //     console.log(result);
    //   });
    // },

    update_product(line) {
      const update_product = fb.httpsCallable("sync_update_product");
      update_product(line.stock_code).then(function (result) {
        console.log(result);
      });
    },

    auto_allocate_pro_rata: async function () {
      console.log("PRO RATA");
      const allocated_lines = this.filtered.filter((line) => {
        return (
          !line.locked && !line.deleted_title && line.line_product.colour != 4
        ); // cyan titles are not yet released
      });

      this.allocating_releasing = true

      actions.setLoading(true);
      const allocation_promise = allocated_lines.map(async (line) => {
        const pro_rata = Number(this.pro_rata(line));

        console.log("Pro Rata allocation = " + pro_rata);
        if (line.allocated !== pro_rata && pro_rata > 0) {
          await db.collection("lines").doc(line.id).update({
            allocated: pro_rata,
            last_allocation: pro_rata,
          });

          await this.update_product(line);
        }
      });
      await Promise.all(allocation_promise);
      await this.get_products();
      this.allocating_releasing = false
      actions.setLoading(false);
      this.$toast.success("Allocation Complete", {
        position: "top-right",
        timeout: 2000,
      });
    },

    auto_allocate: async function () {
      console.log("AUTO ALLOCATE");
      const allocated_lines = this.filtered.filter((line) => {
        return (
          !line.locked && !line.deleted_title && line.line_product.colour != 4
        ); // cyan titles are not yet released
      });

      this.allocating_releasing = true

      actions.setLoading(true);
      const allocation_promise = allocated_lines
        .filter((line) => !line.locked)
        .map(async (line, i, arr) => {
          console.log(
            "UNALLOCATED: " +
            this.unallocated(line) +
            " - OUTSTANDING: " +
            this.outstanding(line)
          );

          const unallocated = this.unallocated(line);
          const outstanding = this.outstanding(line);

          let line_allocation;

          if (outstanding < 1) {
            line_allocation = 0;
          } else {
            const occurences_in_array = arr.filter(
              (l) => l.stock_code === line.stock_code
            ).length;

            console.log("occurences_in_array: " + occurences_in_array);

            let adjusted_unallocated = Math.floor(
              unallocated / occurences_in_array
            );

            if (adjusted_unallocated < 1) {
              const occurences_left = arr
                .slice(i, arr.length)
                .filter((l) => l.stock_code === line.stock_code).length;
              console.log(occurences_left);
              if (occurences_left <= unallocated) {
                adjusted_unallocated = 1;
              }
            }

            console.log("adjusted_unallocated: " + adjusted_unallocated);

            line_allocation = Math.min(
              adjusted_unallocated,
              outstanding + line.allocated // add back items already allocated for this calculation of 'outstanding'
            );
          }

          if (line_allocation > 0) {
            await db.collection("lines").doc(line.id).update({
              allocated: line_allocation,
              last_allocation: line_allocation,
            });
            await this.update_product(line);
          }
        });
      await Promise.all(allocation_promise);
      await this.get_products();
      this.allocating_releasing = false
      actions.setLoading(false);
      this.$toast.success("Allocation Complete", {
        position: "top-right",
        timeout: 2000,
      });
    },

    unallocate: async function () {
      const allocated_lines = this.filtered.filter((line) => {
        return !line.locked && line.allocated > 0
      });
      this.allocating_releasing = true
      actions.setLoading(true);
      const unallocate_promise = allocated_lines.map(async (line) => {
        await db.collection("lines").doc(line.id).update({
          allocated: 0,
          last_allocation: 0,
        });

        this.update_product(line);
      });
      await Promise.all(unallocate_promise);
      await this.get_products();
      this.allocating_releasing = false
      actions.setLoading(false);
      this.$toast.success("Unallocation Complete", {
        position: "top-right",
        timeout: 2000,
      });
    },
    release: async function () {
      const released_lines = this.filtered.filter(
        (line) =>
          line.allocated > 0 &&
          line.line_product.stockonhand > 0 && // don't send lines with no stock
          (line.line_product.colour != 4 ||
            line.order_type.toUpperCase() === "PROMO") // cyan titles are not yet released - ok if promo
      );
      this.allocating_releasing = true
      actions.setLoading(true);
      const release_promises = released_lines.map(async (line) => {
        const new_picking_total = Number(line.allocated) + Number(line.picking);
        await db
          .collection("lines")
          .doc(line.id)
          .update({
            picking: new_picking_total,
            allocated: 0,
            sent_back: false,
            supplier: line.supplier || line.line_product.supplier || "",
            last_update: new Date(),
          });
        await this.update_product(line);
      });
      await Promise.all(release_promises);
      this.allocating_releasing = false
      actions.setLoading(false);
      this.$toast.success("Order Lines Released", {
        position: "top-right",
        timeout: 2000,
      });
    },
    download_csv(event) {
      // console.log(event);
      if (this.filtered.length === 0) return;

      const download_data = this.filtered.map((line) => {
        // line.created = line.created.toDate();
        line.ordered = line.quantity;
        line.backordered = this.outstanding(line);
        line.stock_code_text = `\`${line.stock_code}`;
        line.barcode_text = `\`${line.barcode}`;
        return line;
      });

      const csv = Papa.unparse({
        fields: [
          "name_code",
          "name",
          "po_number",
          "stock_code",
          "stock_code_text",
          "description",
          "barcode",
          "barcode_text",
          "price",
          "category1",
          "ordered",
          "backordered",
          "allocated",
          "shipped",
        ],
        data: download_data,
        quotes: true,
      });
      const csv_name =
        this.filter_label +
        "-" +
        moment().format("YYYY-MM-DD") +
        "_order_lines.csv";

      console.log(csv_name);
      this.downloading = false;
      const anchor = document.createElement("a");
      anchor.href = "data:text/csv," + encodeURIComponent(csv);
      anchor.download = csv_name;
      anchor.click();
    },
  },

  watch: {
    name: {
      immediate: true,
      handler(name) {
        if (name) {
          this.$bind(
            "display_lines",
            lines
              .where("is_outstanding", "==", true)
              .where("name_code", "==", name.code)
          );
        }
      },
    },
    product: {
      immediate: true,
      handler(product) {
        if (product) {
          this.$bind(
            "display_lines",
            lines
              .where("is_outstanding", "==", true)
              .where("stock_code", "==", product.code)
          );
        }
      },
    },
    purchase_order: {
      immediate: true,
      handler(purchase_order) {
        if (purchase_order) {
          this.$bind(
            "display_lines",
            lines
              .where("is_outstanding", "==", true)
              .where("order_id", "==", purchase_order.id)
          );
        }
      },
    },
    parent_can_supply_filter: {
      immediate: true,
      handler(parent_can_supply_filter) {
        if (parent_can_supply_filter) {
          this.can_supply_filter = parent_can_supply_filter;
        } else {
          this.can_supply_filter = "clear";
        }
      },
    },
  },

  computed: {
    is_loading() {
      return getters.isLoading();
    },

    filtered() {
      console.log("filter");
      if (!this.display_lines.length) return [];
      let filtered_lines = this.display_lines.slice();
      const search = this.search.toString().toUpperCase();

      filtered_lines = filtered_lines.filter((line) => {
        return this.show_allocated ? line.allocated : true;
      });

      filtered_lines = filtered_lines.filter((line) => {
        return this.show_handling ? this.handling(line) : !this.handling(line);
      });

      filtered_lines = filtered_lines.filter((line) => {
        const s = Object.values(line);
        const su = s.toString().toUpperCase();
        return su.match(search);
      });

      filtered_lines = filtered_lines.map((line) => {
        const product = this.products.find((p) => p.code == line.stock_code);
        line.line_product = product;
        return line;
      });

      if (this.can_supply_filter != "clear") {
        filtered_lines = filtered_lines.filter((line) => {
          return (
            (line.line_product &&
              line.line_product.can_supply === this.can_supply_filter) ||
            false
          );
        });
      }

      if (this.sort_column) {
        filtered_lines = orderBy(
          filtered_lines,
          this.sort_column,
          this.sort_order
        );
      }
      return filtered_lines;
    },

    filter_label() {
      let label;
      if (this.filter === "purchase_order") {
        label =
          (this.purchase_order.po_number &&
            `PO ${this.purchase_order.po_number} (${this.purchase_order.name})`) ||
          null;
      } else if (this.filter === "product") {
        label =
          (this.product.code &&
            `${this.product.description} (${this.product.code})`) ||
          null;
      } else if (this.filter === "name") {
        label =
          (this.name.code && `${this.name.name} (${this.name.code})`) || null;
      } else {
        label = null;
      }
      return label;
    },
    currentUser() {
      return auth.currentUser;
    },
  },
};
</script>

<style scoped></style>
