import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/functions'
import 'firebase/storage'

const config = {
  apiKey: 'AIzaSyCOfX9Yb9QQ5Hz3s-nnCfrXCEh1qBRbuhc',
  authDomain: 'rhythmethod-playlist.firebaseapp.com',
  databaseURL: 'https://rhythmethod-playlist.firebaseio.com',
  projectId: 'rhythmethod-playlist',
  storageBucket: 'rhythmethod-playlist.appspot.com',
  messagingSenderId: '357113749547',
  appId: '1:357113749547:web:9ea42bda6d8d21068e8a6e',
  measurementId: 'G-NMD8WSDG8G'
}

firebase.initializeApp(config)

// if (location.hostname === 'localhost') {

//   firebase.firestore().settings({
//     host: "localhost:8081",
//     ssl: false,
//     experimentalForceLongPolling: true
//   })
//   firebase.functions().useFunctionsEmulator('http://localhost:5001');
// }

const ff = firebase
const db = firebase.firestore()
const fb = firebase.functions()
const auth = firebase.auth()
const storage = firebase.storage()
const currentUser = auth.currentUser

export {
  ff,
  db,
  fb,
  auth,
  storage,
  currentUser
}
