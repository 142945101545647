<template>
  <basicLayout>
    <h5>INVOICES</h5>
    <div class="row">
      <div class="col-xl-6">
        <div class="input-group input-group-sm">
          <div class="input-group-prepend">
            <span class="input-group-text">Search...</span>
          </div>
          <input
                 @input="debounce_search"
                 :value="search"
                 type="text"
                 class="form-control"
                 placeholder="Seq Number, Code, Name" />
          <div class="input-group-append">
            <button
                    @click="clear_search"
                    class="btn btn-outline-secondary"
                    type="button">
              Clear
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="row m-2">
      <div class="tableFixHead">
        <table class="table table-bordered table-hover table-sm">
          <thead class="thead-dark">
            <tr>
              <th
                  @click="sort_by('seq')"
                  class="text-left">
                Invoice #
              </th>
              <th
                  @click="sort_by('name_code')"
                  class="text-left">
                Name Code
              </th>
              <th
                  @click="sort_by('name')"
                  class="text-left">
                Name
              </th>
              <th class="text-left">Date</th>
              <th></th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr
                v-for="invoice in filtered"
                :key="invoice.id">
              <td
                  @dblclick="filter_by(invoice.seq)"
                  class="text-left">
                {{ invoice.seq }}
              </td>
              <td
                  @dblclick="filter_by(invoice.name_code)"
                  class="text-left">
                {{ invoice.name_code }}
              </td>
              <td
                  @dblclick="filter_by(invoice.name)"
                  class="text-left">
                {{ invoice.name }}
              </td>
              <td class="text-left">{{ invoice.created | moment_short }}</td>
              <td class="text-center">
                <button
                        @click="see_lines(invoice.seq)"
                        type="button"
                        class="btn btn-x-sm btn-outline-success mr-2">
                  <list-icon
                             size="1.0x"
                             class="custom-class"></list-icon>
                  See Lines
                </button>
              </td>
              <td class="text-center">
                <button
                        @click="get_form(invoice.seq)"
                        class="btn btn-x-sm btn-outline-success mr-2"
                        type="button">
                  <file-text-icon
                                  size="1.0x"
                                  class="custom-class"></file-text-icon>
                  Packing Slip
                </button>
              </td>
              <td
                  v-if="invoice.ssid"
                  class="text-center">
                <button
                        @click="track_order(invoice.ssid)"
                        class="btn btn-x-sm btn-outline-success mr-2"
                        type="button">
                  <compass-icon
                                size="1.0x"
                                class="custom-class"></compass-icon>
                  Track
                  {{ invoice.ssid }}
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <Pagination
                  v-if="pages"
                  :pages="pages"
                  :selected_page="selected_page"
                  v-on:updatePage="updatePage" />
    </div>
  </basicLayout>
</template>

<script>
import { fb, db } from "@/firebaseConfig";
import { orderBy } from "lodash";
import basicLayout from "@/components/basicLayout";
import filter_mixins from "@/mixins/filter_mixins";
import sort_mixins from "@/mixins/sort_mixins";
import utility_mixins from "@/mixins/utility_mixins";
import Pagination from "@/components/Pagination";
import { ListIcon, FileTextIcon, CompassIcon } from "vue-feather-icons";

const invoices = db.collection("invoices");

export default {
  name: "invoices",
  data() {
    return {
      invoices: [],
      search: "",
      debounce: null,
      sort_column: ["code"],
      sort_order: "asc",
      page_limit: 50,
      selected_page: 1,
    };
  },
  components: {
    basicLayout,
    Pagination,
    ListIcon,
    FileTextIcon,
    CompassIcon,
  },
  mixins: [filter_mixins, sort_mixins, utility_mixins],

  firestore() {
    return {
      // invoices: db.collection('invoices').orderBy('seq', 'desc')
    };
  },
  methods: {
    debounce_search(event) {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.search = event.target.value;
      }, 200);
    },
    see_lines(seq) {
      this.$router.push({
        name: "InvoicedLines",
        params: { seq: seq },
      });
    },

    filter_by(filter) {
      this.search = filter;
    },

    sort_by(column) {
      this.sort_column = column;
      this.sort_order = this.sort_order === "asc" ? "desc" : "asc";
    },
    clear_search() {
      this.search = "";
    },
    updatePage(value) {
      this.selected_page = value;
      this.clear_search();
    },

    track_order: async function (ssid) {
      const track_order = fb.httpsCallable("starshipit_track");
      const tracking_res = await track_order(ssid);
      console.log(JSON.stringify(tracking_res));
      const order_data = tracking_res.data.data.order;
      console.log(order_data);
      console.log(order_data.packages);
      const tracking_url = order_data.packages[0].tracking_url;
      window.open(tracking_url, "_blank");
    },
  },
  computed: {
    filtered() {
      let visible_invoices = this.invoices.slice();

      // FILTER BECAUSE EARLY INVOICES DID NOT HAVE SSID - I.E. TEST INVOICES

      visible_invoices = visible_invoices.filter((invoice) => invoice.ssid);
      const search = this.search;

      if (this.sort_column) {
        visible_invoices = orderBy(
          visible_invoices,
          (item) => item[this.sort_column] || "",
          this.sort_order
        );
      }

      if (!search) {
        const start = (this.selected_page - 1) * this.page_limit;
        const end = start + this.page_limit;
        visible_invoices = visible_invoices.slice(start, end);
      }

      visible_invoices = visible_invoices.filter((invoice) => {
        const s =
          Object.values(invoice) +
          invoice.order_lines.map((line) => line.stock_code);
        const su = s.toString().toUpperCase();
        return su.match(search.toString().toUpperCase());
      });

      return visible_invoices;
    },
    pages() {
      const pages_array = this.search ? this.filtered : this.invoices;
      return Math.ceil(pages_array.length / this.page_limit);
    },
  },

  watch: {
    search: {
      immediate: true,
      handler(search) {
        let query = invoices.orderBy("seq", "desc").limit(600)
        if (!search) {
          query = query.limit(50);
        }
        this.$bind("invoices", query);
      },
    },
  },
};
</script>

<style scoped></style>
