<template>
  <basicLayout>
    <div class="p-2">
      <h5>Users</h5>
      <div class="row">
        <div class="col-10">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">Search...</span>
            </div>
            <input
              :value="search"
              type="text"
              class="form-control"
              placeholder="Name or Email" />
            <div class="input-group-append">
              <button
                @click="clear_search"
                class="btn btn-outline-secondary"
                type="button">
                Clear
              </button>
            </div>
          </div>
        </div>
        <div class="col-2">
          <router-link
            :to="{ name: 'UserCreate' }"
            type="button"
            class="btn btn-secondary btn-block"
            >Create User</router-link
          >
        </div>
      </div>

      <div class="tableFixHead">
        <table class="table table-bordered table-hover table-sm">
          <thead class="thead-dark">
            <tr>
              <th
                class="text-left"
                scope="col">
                Name
              </th>

              <th
                class="text-left"
                scope="col">
                email
              </th>
              <th
                class="text-left"
                scope="col">
                Role
              </th>
              <th
                class="text-left"
                scope="col">
                Access
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="user in filtered"
              :key="user.order_id">
              <td class="text-left">{{ user.name }}</td>

              <td class="text-left">
                <a :href="`mailto:${user.email}`">{{ user.email }}</a>
              </td>
              <td class="text-left">{{ user.role }}</td>
              <td class="text-left">{{ user.access && user.access.join(", ") }}</td>
              <td class="text-center">
                <router-link
                  :to="{ name: 'UserProfile', params: { id: user.id } }"
                  class="btn btn-x-sm btn-outline-secondary mr-3">
                  <edit-icon
                    size="1x"
                    class="custom-class"></edit-icon
                  >&nbsp;Edit
                </router-link>

                <button
                  @click="deleteUser(user.id)"
                  class="btn btn-x-sm btn-outline-danger">
                  <trash-2-icon
                    size="1x"
                    class="custom-class"></trash-2-icon>
                  &nbsp;{{ confirm_delete ? "Really Delete?" : "Delete" }}
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </basicLayout>
</template>

<script>
import { fb, db } from "@/firebaseConfig";
import basicLayout from "@/components/basicLayout";
import { EditIcon, Trash2Icon } from "vue-feather-icons";

export default {
  name: "users",
  data() {
    return {
      users: [],
      search: "",
      confirm_delete: false,
    };
  },
  components: { basicLayout, EditIcon, Trash2Icon },

  firestore() {
    return {
      users: db.collection("users"),
    };
  },
  methods: {
    deleteUser(id) {
      if (this.confirm_delete) {
        const deleteUser = fb.httpsCallable("deleteUser");
        deleteUser({ id: id }).then((result) => {
          console.log(JSON.stringify(result));
        });
        this.confirm_delete = false;
        return;
      }
      this.confirm_delete = true;
      setTimeout(() => {
        this.confirm_delete = false;
      }, 4000);

      // const deleteUser = fb.httpsCallable("deleteUser");

      // deleteUser({ id: id }).then(result => {
      //   console.log(JSON.stringify(result));
      // });
    },

    clear_search() {
      this.search = "";
    },
  },
  computed: {
    filtered: function () {
      return this.users.filter((user) => {
        const s = user.name + user.email;
        const su = s.toUpperCase();
        return su.match(this.search.toUpperCase());
      });
    },
  },
};
</script>
