<template>
  <basicLayout>
    <div class="row">
      <div class="col-8">
        <h5>DROPSHIP ORDER UPLOAD</h5>
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-6 form-group">
                <div class="custom-file">
                  <input
                    @change="file_picker"
                    type="file"
                    class="custom-file-input"
                    id="customFile" />
                  <label
                    class="custom-file-label"
                    for="customFile"
                    >Choose file</label
                  >
                </div>
              </div>
              <div class="col-6 form-group">
                <div
                  v-cloak
                  @drop.prevent="drag"
                  @dragover.prevent>
                  <input
                    class="form-control progress-bar-striped alert-dark"
                    placeholder="...or drag file here" />
                </div>
              </div>
            </div>
            <div v-if="upload_file.length">
              <div class="form-group">
                <label for="Name">Service</label>
                <small class="form-text text-muted"
                  >Use this input if the csv upload file does not contain a column for Service</small
                >

                <select
                  v-model="service"
                  class="form-control"
                  id="service">
                  <option value="SHOPIFY">SHOPIFY</option>
                  <option value="MUSIC_GLUE">MUSIC GLUE</option>
                </select>
              </div>

              <div class="form-group">
                <label for="Name">Store</label>
                <small class="form-text text-muted"
                  >Use this input if the csv upload file does not contain a column for Store</small
                >

                <select
                  v-model="store"
                  class="form-control"
                  id="service">
                  <option value="FFD">FAT FREDDY'S DROP</option>
                  <option value="BF">BROOKE FRASER</option>
                  <option value="TEEKS">TEEKS</option>
                  <option value="SIX60">SIX60</option>
                </select>
              </div>

              <div class="form-group">
                <label for="dropship_template">Saved Templates</label>
                <v-select
                  v-model="dropship_template"
                  :options="template_options"
                  :selectOnTab="true"
                  :clearable="false"></v-select>
              </div>
              <hr />

              <div
                v-for="field in dropship_template.field_map"
                :key="field.id">
                <div class="form-group">
                  <label :for="field.label">{{ field.label }}</label>
                  <v-select
                    placeholder="maps to..."
                    v-model="field.maps_to"
                    multiple
                    :options="upload_fields"
                    :selectOnTab="true"></v-select>
                </div>
              </div>
            </div>
          </div>

          <div
            v-if="upload_file.length && !dropship_template.id"
            class="card-footer">
            <p>Save this Template As</p>
            <form class="form-inline">
              <label
                class="sr-only"
                for="template_name"
                >Name</label
              >
              <input
                v-model="dropship_template.label"
                type="text"
                class="form-control mb-2 mr-sm-2"
                id="template_name"
                placeholder="Template Name" />
              <button
                @click.prevent="save_template"
                type="submit"
                class="btn btn-secondary mb-2">
                Save
              </button>
            </form>
          </div>
          <div
            v-if="upload_file.length && dropship_template.id"
            class="card-footer">
            <button
              @click.prevent="update_template"
              type="submit"
              class="btn btn-secondary mb-2 text-right">
              Update Template
            </button>
          </div>
          <div
            v-if="upload_file.length"
            class="card-footer text-right">
            <button
              @click.prevent="process_order"
              type="submit"
              class="btn btn-outline-success">
              Preview Upload
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="upload_file.length && dropship_array.length"
      class="row mt-2">
      <div class="col">
        <div class="card">
          <div class="card-body">
            <h6>PREVIEW</h6>
            Service: {{ service }} Store: {{ store }}
            <div class="tableFixHead tableFixHeadShort">
              <table class="table table-bordered table-hover table-sm">
                <thead class="thead-dark">
                  <tr>
                    <th class="text-left">System ID</th>
                    <th class="text-left">Order#</th>
                    <th class="text-left">Name</th>
                    <th class="text-left">Shipping Name</th>
                    <th class="text-left">Company</th>
                    <th class="text-left">Street</th>
                    <th class="text-left">Suburb</th>
                    <th class="text-left">City</th>
                    <th class="text-left">State</th>
                    <th class="text-left">Postcode</th>
                    <th class="text-left">Country</th>
                    <th class="text-left">Items</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="line in dropship_array"
                    :key="line.order_number">
                    <td class="text-left">
                      {{ line.order_id }}
                    </td>
                    <td class="text-left">
                      {{ line.order_number }}
                    </td>
                    <td class="text-left">{{ line.first_name }} {{ line.last_name }}</td>
                    <td class="text-left">
                      {{ line.shipping_address.first_name }}
                      {{ line.shipping_address.last_name }}
                    </td>
                    <td class="text-left">
                      {{ line.shipping_address.company }}
                    </td>
                    <td class="text-left">
                      {{ line.shipping_address.address1 }}
                    </td>
                    <td class="text-left">
                      {{ line.shipping_address.address2 }}
                    </td>
                    <td class="text-left">{{ line.shipping_address.city }}</td>
                    <td class="text-left">{{ line.shipping_address.state }}</td>
                    <td class="text-left">
                      {{ line.shipping_address.postcode }}
                    </td>
                    <td class="text-left">
                      {{ line.shipping_address.country }}
                    </td>
                    <td>
                      <ul
                        v-for="item in line.line_items"
                        :key="item.sku"
                        class="list-unstyled p-0 m-0">
                        <li>
                          <b>{{ item.sku }}</b> {{ item.description }} x{{ item.quantity }}
                        </li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="card-footer text-right">
            <button
              @click.prevent="upload_order"
              type="submit"
              class="btn btn-outline-success">
              Upload
            </button>
          </div>
        </div>
      </div>
    </div>
  </basicLayout>
</template>

<script>
import basicLayout from "@/components/basicLayout";
import Papa from "papaparse";
import { actions } from "@/store";
import { fb, db } from "@/firebaseConfig";

const default_template = {
  id: "",
  default: true,
  label: "Default",
  field_map: [
    { id: "service", label: "Service", maps_to: "" },
    { id: "store", label: "Store", maps_to: "" },
    {
      id: "key",
      label: "Unique Key (required for multi-line orders",
      maps_to: "",
    },
    { id: "order_id", label: "System Order ID", maps_to: "" },
    { id: "order_number", label: "Order No.", maps_to: "" },
    { id: "order_total", label: "Order Total", maps_to: "" },
    { id: "first_name", label: "First Name", maps_to: "" },
    { id: "last_name", label: "Last Name", maps_to: "" },
    { id: "email", label: "Email", maps_to: "" },
    { id: "shipping_first_name", label: "Shipping First Name", maps_to: "" },
    { id: "shipping_last_name", label: "Shipping Last Name", maps_to: "" },
    { id: "shipping_email", label: "Shipping Email", maps_to: "" },
    { id: "shipping_company", label: "Company", maps_to: "" },
    { id: "shipping_address1", label: "Address 1", maps_to: "" },
    { id: "shipping_address2", label: "Address 2", maps_to: "" },
    { id: "shipping_city", label: "City", maps_to: "" },
    { id: "shipping_state", label: "State", maps_to: "" },
    { id: "shipping_postcode", label: "Postcode", maps_to: "" },
    { id: "shipping_country", label: "Country", maps_to: "" },
    { id: "item_sku", label: "Item SKU", maps_to: "" },
    { id: "item_description", label: "Item Desciption", maps_to: "" },
    { id: "item_quantity", label: "Item Quantity", maps_to: "" },
    { id: "item_weight", label: "Item Weight", maps_to: "" },
  ],
};
export default {
  name: "order_upload",
  data: function () {
    return {
      service: "",
      store: "",
      upload_file: {},
      upload_fields: [],
      dropship_templates: [{ label: "", field_map: [] }],
      dropship_template: default_template,
      dropship_array: [],
    };
  },
  firestore() {
    return {
      dropship_templates: db.collection("dropship_templates"),
    };
  },
  components: {
    basicLayout,
  },
  methods: {
    drag(event) {
      this.upload(event.dataTransfer.files[0]);
      event.target.value = "";
    },
    file_picker(event) {
      // console.log("file picker");
      this.upload(event.target.files[0]);
      event.target.value = "";
    },
    upload(file) {
      const pre_process_upload = this.pre_process_upload;
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        dynamicTyping: true,
        complete(results) {
          pre_process_upload(results);
        },
      });
    },

    pre_process_upload: function (upload_data) {
      this.upload_fields = upload_data.meta.fields;
      this.upload_file = upload_data.data;
    },

    process_order: async function () {
      const field_map = new Map(this.dropship_template.field_map.map((i) => [i.id, i.maps_to]));

      const dropship_array = this.upload_file
        .map((line, index) => {
          function join_fields(field_id) {
            let join = " ";
            if (field_id === "key") {
              join = "_";
            }
            return (
              field_id &&
              field_map.get(field_id) &&
              field_map
                .get(field_id)
                .map((f) => line[f])
                .join(join)
            );
          }

          let first_name = join_fields("first_name");
          let last_name = join_fields("last_name");

          if (!last_name) {
            last_name = first_name.split(" ").slice(-1).join(" ");
            first_name = first_name.split(" ").slice(0, -1).join(" ");
          }

          console.log("FIRST NAME: " + first_name);
          console.log("LAST NAME: " + last_name);

          let shipping_first_name = join_fields("shipping_first_name");
          let shipping_last_name = join_fields("shipping_last_name");

          if (!shipping_last_name) {
            shipping_last_name = shipping_first_name.split(" ").slice(-1).join(" ");

            shipping_first_name = shipping_first_name.split(" ").slice(0, -1).join(" ");
          }

          console.log("SHIPPING FIRST NAME: " + shipping_first_name);
          console.log("SHIPPING LAST NAME: " + shipping_last_name);

          const order_object = {
            key: join_fields("key"),
            order_id: join_fields("order_id"),
            order_number: join_fields("order_number"),
            order_total: join_fields("order_total"),
            service:
              (join_fields("service") && join_fields("service").toString().toUpperCase()) ||
              this.service.toString().toUpperCase(),
            store:
              (join_fields("store") && join_fields("store").toString().toUpperCase()) ||
              this.store.toString().toUpperCase(),
            first_name: first_name,
            last_name: last_name,
            email: join_fields("email"),

            shipping_address: {
              first_name: shipping_first_name,
              last_name: shipping_last_name,
              company: join_fields("shipping_company"),
              email: join_fields("shipping_email"),
              address1: join_fields("shipping_address1"),
              address2: join_fields("shipping_address2"),
              city: join_fields("shipping_city"),
              state: join_fields("shipping_state"),
              province: join_fields("shipping_state"),
              postcode: join_fields("shipping_postcode"),
              zip: join_fields("shipping_postcode"),
              country: join_fields("shipping_country") || "NZ",
            },
            line_items: [
              {
                id: `${join_fields("key")}_${index}`,
                sku: join_fields("item_sku"),
                description: join_fields("item_description"),
                quantity: Number(join_fields("item_quantity")),
                weight: Number(join_fields("item_weight")),
              },
            ],
            shipped: false,
            complete: false,
            fulfilled: false,
          };
          // console.log(JSON.stringify(order_object));
          return order_object;
        })

        .reduce((result, line) => {
          const existing = result.find((r) => r.key === line.key);
          if (existing) {
            const line_items = {
              id: line.line_items[0].id,
              sku: line.line_items[0].sku,
              description: line.line_items[0].description,
              quantity: line.line_items[0].quantity,
              weight: line.line_items[0].weight,
            };
            existing.line_items.push(line_items);
          } else {
            result.push(line);
          }
          // console.log(JSON.stringify(result));
          return result;
        }, []);

      console.log(JSON.stringify(dropship_array));
      this.dropship_array = dropship_array;
    },

    upload_order: async function () {
      actions.setLoading(true);
      const dropship_order_import = fb.httpsCallable("dropship_order_import");
      const order_importer_res = await dropship_order_import(this.dropship_array);

      console.log(order_importer_res);
      if (order_importer_res.data.success) {
        this.$router.push({ name: "DropShip" });

        this.$toast.success("Order Imported", {
          position: "top-right",
          timeout: 2000,
        });
      } else {
        this.$toast.error("Order did not Import: " + order_importer_res.error, {
          position: "top-right",
          timeout: 2000,
        });
      }
      actions.setLoading(false);
    },

    save_template: async function () {
      if (this.dropship_template.label === "Default") {
        alert("Don't name your saved template 'Default'");
        return;
      }
      db.collection("dropship_templates").add({
        created: new Date(),
        label: this.dropship_template.label,
        field_map: this.dropship_template.field_map,
      });
    },

    update_template: async function () {
      db.collection("dropship_templates").doc(this.dropship_template.id).update({
        updated: new Date(),
        field_map: this.dropship_template.field_map,
      });
    },
  },
  computed: {
    template_options() {
      const template_array = [...this.dropship_templates];
      template_array.push(default_template);
      return template_array;
    },
  },
};
</script>
