<template>
  <basicLayout>
    <h5>
      PURCHASE ORDER
      <span v-if="purchase_order">{{ purchase_order.our_ref }} - {{ purchase_order.tofrom }} ({{
        purchase_order.namecode
      }})</span>
    </h5>



    <div class="row mb-2">
      <div class="col-xl-6">
        <div class="input-group input-group-sm">
          <div class="input-group-prepend">
            <span class="input-group-text">Search...</span>
          </div>
          <input
                 @input="debounce_search"
                 :value="search"
                 type="text"
                 class="form-control"
                 placeholder="Code, description, barcode" />
          <div class="input-group-append">
            <button
                    @click="clear_search"
                    class="btn btn-outline-secondary"
                    type="button">
              Clear
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="row mb-2">
      <div class="col">
        <div class="btn-toolbar">
          <button
                  @click="get_po(ourref)"
                  class="btn btn-sm btn-outline-success mr-3">
            <refresh-cw-icon
                             size="1.5x"
                             class="mr-2"></refresh-cw-icon>
            Fetch / Refresh
          </button>

          <button
                  @click="pdfgen"
                  class="btn btn-sm btn-secondary mr-2"
                  type="button">
            <clipboard-icon
                            size="1.5x"
                            class="mr-2"></clipboard-icon>
            Inwards List
          </button>

          <button
                  @click="save_purchase_order"
                  :disabled="is_loading"
                  class="btn btn-sm btn-success mr-2">
            <save-icon
                       size="1.5x"
                       class="custom-class"></save-icon>
            Save Changes
          </button>
          <div
               v-if="purchase_order && purchase_order.last_saved"
               class="ml-3">
            <p style="font-size: 0.8em">
              <b>LAST SAVED:</b>
              {{ purchase_order.last_saved | moment_short }}
            </p>
          </div>
          <div
               v-if="!purchase_order"
               class="ml-3">
            <p style="font-size: 0.8em">NOT SAVED</p>
          </div>
        </div>
      </div>
      <div class="col text-right">
        <p style="font-size: 0.8em">
          CD: {{ filtered_cd }} | VINYL: {{ filtered_vinyl }} | DVD:
          {{ filtered_dvd }} | BLU-RAY:
          {{ filtered_blu_ray }}
        </p>
      </div>
    </div>
    <div class="row">
      <div
           class="tableFixHead tableFixHeadLong col"
           ref="pick_table">
        <table class="table table-bordered table-hover table-sm">
          <thead class="thead-dark">
            <tr>
              <th
                  @click="sort_by('stock_code')"
                  class="text-left">
                Code
              </th>
              <th
                  @click="sort_by('description')"
                  class="text-left">
                Description
              </th>
              <th
                  @click="sort_by('product.custom2')"
                  class="text-left">
                Release Date
              </th>
              <th
                  @click="sort_by('format')"
                  class="text-left">
                Format
              </th>
              <th
                  @click="sort_by('supplier')"
                  class="text-left">
                Supplier
              </th>
              <th
                  @click="sort_by('barcode')"
                  class="text-left">
                Barcode
              </th>
              <th></th>
              <th></th>
              <th
                  @click="sort_by('quantity')"
                  class="text-right">
                Ordered
              </th>
              <th
                  @click="sort_by('received')"
                  class="text-right">
                Received
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
                v-for="line in filtered"
                :key="line.id">
              <td class="text-left">{{ line.stock_code }}</td>
              <td class="text-left">{{ line.description }}</td>
              <td class="text-left">
                {{ line.product.custom2 | date_short }}
              </td>
              <td class="text-left">{{ line.format }}</td>
              <td class="text-left">{{ line.supplier }}</td>
              <td class="text-left">{{ line.barcode }}</td>
              <td class="text-center">
                <v-popover
                           v-if="line.product.code"
                           placement="bottom-start"
                           offset="8">
                  <info-icon size="1.2x"></info-icon>
                  <template slot="popover">
                    <ProductPopover
                                    :product_code="line.product.code"></ProductPopover>
                  </template>
                </v-popover>
              </td>
              <td class="text-center">
                <button
                        @click="pick_one_manually(line)"
                        type="button"
                        class="btn btn-x-sm btn-outline-success mr-2">
                  +1
                </button>
                <button
                        @click="unpick_one(line)"
                        type="button"
                        class="btn btn-x-sm btn-outline-danger mr-2">
                  - 1
                </button>
                <button
                        @click="pick_all_line(line)"
                        type="button"
                        class="btn btn-x-sm btn-outline-success mr-2">
                  All
                </button>
                <button
                        @click="unpick_all_line(line)"
                        type="button"
                        class="btn btn-x-sm btn-outline-danger mr-2">
                  Clear
                </button>
                <button
                        @click="add_note_modal(line)"
                        type="button"
                        class="btn btn-x-sm"
                        :class="{
        'btn-warning': line.receiving_notes,
        'btn-outline-warning': !line.receiving_notes,
      }">
                  Notes
                </button>
              </td>
              <td class="text-right">{{ line.quantity }}</td>
              <td
                  class="text-right"
                  :class="received_column_style(line)">
                {{ line.received }}
              </td>
            </tr>
          </tbody>
          <tr>
            <td
                colspan="7"
                class="font-weight-bold text-right">
              TOTAL
            </td>
            <td class="font-weight-bold text-right">
              {{ filtered_ordered }}
            </td>
            <td class="font-weight-bold text-right">
              {{ filtered_received }}
            </td>
          </tr>
        </table>
      </div>
    </div>

    <PurchaseOrderLinesAddNote
                               v-if="show_add_note"
                               :selected_line="selected_line"
                               v-on:close_add_note="close_add_note"
                               v-on:update_note="update_note" />
    <ConfirmDialog ref="confirmDialog"></ConfirmDialog>
  </basicLayout>
</template>

<script>
import { fb, db } from "@/firebaseConfig";
import { orderBy } from "lodash";
import basicLayout from "@/components/basicLayout";
import ProductPopover from "@/components/ProductPopover";
import PurchaseOrderLinesAddNote from "@/components/PurchaseOrderLinesAddNote";
import ConfirmDialog from "../components/ConfirmDialog.vue";

import BarcodeToast from "@/components/BarcodeToast";
import { getters } from "@/store";
import orders_mixins from "@/mixins/orders_mixins";
import filter_mixins from "@/mixins/filter_mixins";
import utility_mixins from "@/mixins/utility_mixins";
import po_pdfgen from "@/mixins/po_pdfgen";
import {
  SaveIcon,
  InfoIcon,
  RefreshCwIcon,
  ClipboardIcon,
} from "vue-feather-icons";

const purchase_orders = db.collection("purchase_orders_saved");

export default {
  name: "purchase_order_lines",
  data() {
    return {
      ourref: this.$route.params.ourref,
      purchase_order: {},
      selected_line: {},
      search: "",
      barcode: "",
      sort_column: ["stock_code"],
      sort_order: ["asc"],
      show_add_note: false,
    };
  },
  components: {
    basicLayout,
    ProductPopover,
    PurchaseOrderLinesAddNote,
    ConfirmDialog,
    SaveIcon,
    InfoIcon,
    RefreshCwIcon,
    ClipboardIcon,
    // RewindIcon,
    // TruckIcon,
  },

  mixins: [orders_mixins, filter_mixins, utility_mixins, po_pdfgen],

  watch: {
    ourref: {
      immediate: true,
      handler: function (new_val) {
        console.log("purchase_order: " + new_val);
        this.$bind("purchase_order", purchase_orders.doc(`${new_val}`));
      },
    },
  },

  created() {
    window.addEventListener("keypress", this.scanner);
  },
  destroyed() {
    window.removeEventListener("keypress", this.scanner);
  },

  methods: {
    get_po: async function (our_ref) {
      console.log("get_po");

      if (this.purchase_order && this.purchase_order.id) {
        let confirm_refresh = await this.$refs.confirmDialog.show({
          confirm_title: "PO Saved",
          confirm_message:
            "Are you sure you want to refresh?  All updates will be lost.",
          confirm_ok_button: "Yes, refresh anyway",
        });

        if (!confirm_refresh) return;
      }

      const moneyworks_get_po = fb.httpsCallable("moneyworks_get_po");
      moneyworks_get_po(our_ref).then((result) => {
        console.log(JSON.stringify(result.data));
        this.purchase_order = result.data.transaction;
      });
    },

    scanner(e) {
      if (e.key != "Enter") {
        this.barcode = this.barcode + e.key;
        console.log(this.barcode);
      } else {
        console.log("BARCODE: " + this.barcode);
        const line = this.filtered
          .filter((l) => l.received < l.quantity)
          .find((l) => {
            return (
              l.barcode === this.barcode ||
              l.alt_barcode === this.barcode ||
              `0${l.barcode}` === this.barcode ||
              `0${l.alt_barcode}` === this.barcode ||
              (l.barcode &&
                l.barcode.toString().substring(1) === this.barcode) ||
              (l.alt_barcode &&
                l.alt_barcode.toString().substring(1) === this.barcode)
            );

            // barcode or alternate barcode
          });
        console.log(line);
        const product_id = line && line.stock_code;
        if (!product_id) {
          console.log("that product is not in this order");
          const content = {
            component: BarcodeToast,
            props: {
              code: this.barcode,
              description: "that product is not in this order",
            },
          };
          this.$toast.error(content, {
            position: "top-right",
            timeout: 2000,
          });
          this.barcode = "";
        } else {
          const content = {
            component: BarcodeToast,
            props: {
              code: line.stock_code,
              description: line.description,
            },
          };
          this.$toast.success(content, {
            position: "top-right",
            timeout: 2000,
          });
          this.pick_one(line);
          this.barcode = "";
        }
      }
    },

    add_note_modal(line) {
      // console.log("add note: " + JSON.stringify(line));
      this.selected_line = line;
      this.show_add_note = true;
    },

    update_note(value) {
      this.selected_line.receiving_notes = value;
    },

    close_add_note() {
      console.log("closing");
      // this.$toast.success("Saving Purchase Order", {
      //   position: "top-right",
      //   timeout: 2000,
      // });
      // this.save_purchase_order();
      this.show_add_note = false;
    },

    debounce_search(event) {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.search = event.target.value;
      }, 600);
    },
    filter_by(filter) {
      this.search = filter;
    },
    sort_by(column) {
      this.sort_column = column;
      this.sort_order = this.sort_order === "asc" ? "desc" : "asc";
    },
    clear_search() {
      this.search = "";
    },

    pick_one_manually(line) {
      this.pick_one(line);
    },

    pick_one: async function (line) {
      const new_received = (line.received && Number(line.received) + 1) || 1;
      console.log(new_received);
      if (line.received < line.quantity) {
        line.received = new_received;
      }
    },
    pick_all_line: async function (line) {
      const new_received = Number(line.quantity);
      if (line.picking === 0) return;
      line.received = new_received;
    },
    unpick_one: async function (line) {
      const new_received = Number(line.received) - 1;
      if (line.received === 0) {
        line.over_received = false;
        return;
      }
      line.received = new_received;
    },
    unpick_all_line: async function (line) {
      line.received = 0;
    },
    received_column_style(line) {
      return line.received === line.quantity
        ? "text-white bg-success text-right font-weight-bold"
        : "text-right";
    },

    save_purchase_order: async function () {
      console.log(this.purchase_order && this.purchase_order.id);

      this.purchase_order.last_saved = new Date();

      if (this.purchase_order && this.purchase_order.id) {
        await db
          .collection("purchase_orders_saved")
          .doc(this.purchase_order.id)
          .update(this.purchase_order);
      } else {
        await db
          .collection("purchase_orders_saved")
          .doc(this.ourref)
          .set(this.purchase_order);
      }
    },

    // ================================================================
    // PRE-CHECK ITEMS BEFORE INVOICING / SHIPPING
  },
  beforeRouteLeave: async function (_to, _from, next) {
    if (!this.purchase_order || !this.purchase_order.id) {
      let confirm_exit = await this.$refs.confirmDialog.show({
        confirm_title: "Not Saved",
        confirm_message:
          "Are you sure you want to exit without saving?  All updates will be lost.",
        confirm_ok_button: "Yes, exit without saving",
      });

      if (!confirm_exit) return;
    }

    this.$toast.warning("Saving Changes");
    this.save_purchase_order();
    next();
  },

  computed: {
    is_loading() {
      return getters.isLoading();
    },

    lines_processing() {
      return (
        (this.purchase_order &&
          this.purchase_order.details &&
          this.purchase_order.details.length &&
          this.purchase_order.details.slice()) ||
        {}
      );
    },

    filtered() {
      const search = this.search.toString().toUpperCase();

      let filtered_lines =
        this.lines_processing.length &&
        this.lines_processing.filter((line) => {
          const s = Object.values(line);
          const su = s.toString().toUpperCase();
          return su.match(search);
        });

      if (this.sort_column) {
        filtered_lines = orderBy(
          filtered_lines,
          this.sort_column,
          this.sort_order
        );
      }
      return filtered_lines;
    },

    filtered_ordered() {
      return this.filtered.reduce(function (prev, cur) {
        return Number(prev) + Number(cur.quantity);
      }, 0);
    },

    filtered_received() {
      return this.filtered.reduce(function (prev, cur) {
        return Number(prev) + Number(cur.received) || 0;
      }, 0);
    },

    filtered_cd() {
      return (
        this.lines_processing.length &&
        this.lines_processing
          .filter(
            (line) =>
              line.product.category1 && line.product.category1.includes("CD")
          )
          .reduce(function (prev, cur) {
            return Number(prev) + Number(cur.quantity);
          }, 0)
      );
    },
    filtered_vinyl() {
      return (
        this.lines_processing.length &&
        this.lines_processing
          .filter(
            (line) =>
              line.product.category1 && line.product.category1.includes("VINYL")
          )
          .reduce(function (prev, cur) {
            return Number(prev) + Number(cur.quantity);
          }, 0)
      );
    },
    filtered_dvd() {
      return (
        this.lines_processing.length &&
        this.lines_processing
          .filter(
            (line) =>
              line.product.category1 && line.product.category1.includes("DVD")
          )
          .reduce(function (prev, cur) {
            return Number(prev) + Number(cur.quantity);
          }, 0)
      );
    },
    filtered_blu_ray() {
      return (
        this.lines_processing.length &&
        this.lines_processing
          .filter(
            (line) =>
              line.product.category1 &&
              line.product.category1.includes("BLU-RAY")
          )
          .reduce(function (prev, cur) {
            return Number(prev) + Number(cur.quantity);
          }, 0)
      );
    },

    invoice_lines() {
      return this.filtered.filter((line) => {
        return line.received > 0;
      });
    },
  },
};
</script>

<style scoped></style>
