<template>
  <basicLayout>
    <h5>PURCHASE ORDERS</h5>
    <div class="row mb-2">
      <div class="col-xl-6">
        <div class="input-group input-group-sm">
          <div class="input-group-prepend">
            <span class="input-group-text">Search...</span>
          </div>
          <input
                 @input="debounce_search"
                 :value="search"
                 type="text"
                 class="form-control"
                 placeholder="Seq Number, Code, Name" />
          <div class="input-group-append">
            <button
                    @click="clear_search"
                    class="btn btn-outline-secondary"
                    type="button">
              Clear
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col">
        <button
                @click="get_pos()"
                class="btn btn-sm btn-outline-success mr-3">
          <refresh-cw-icon
                           size="1.5x"
                           class="mr-2"></refresh-cw-icon>
          Fetch / Refresh
        </button>
      </div>
    </div>

    <!-- <div class="col-xl-6">
        <div class="input-group input-group-sm">
          <div class="input-group-prepend">
            <span class="input-group-text">Import...</span>
          </div>
          <input
            v-model="import_po_seq"
            class="form-control"
            placeholder="Sequence Number" />
          <div class="input-group-append">
            <button
              @click="get_po(import_po_seq)"
              class="btn btn-outline-secondary"
              type="button">
              Import
            </button>
          </div>
        </div>
      </div> -->

    <div class="row mb-2">
      <div class="tableFixHead col">
        <table class="table table-bordered table-hover table-sm">
          <thead class="thead-dark">
            <tr>
              <th
                  @click="sort_by('po_date')"
                  class="text-left">
                Date
              </th>
              <!--<th
                  @click="sort_by('sequence_number')"
                  class="text-left">
                Sequence Number
              </th>-->
              <th
                  @click="sort_by('name_code')"
                  class="text-left">
                Name
              </th>
              <th
                  @click="sort_by('description')"
                  class="text-left">
                Description
              </th>
              <th
                  @click="sort_by('our_ref')"
                  class="text-left">
                Our Ref
              </th>
              <th
                  @click="sort_by('their_ref')"
                  class="text-left">
                Their Ref
              </th>
              <!-- <th
                  @click="sort_by('lines')"
                  class="text-left">
                Lines
              </th>
              <th
                  @click="sort_by('items')"
                  class="text-left">
                Items
              </th>
              <th
                  @click="sort_by('order_total')"
                  class="text-left">
                PO Total
              </th> -->
              <th
                  @click="sort_by('gross')"
                  class="text-left">
                Gross
              </th>

              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr
                v-for="purchase_order in filtered"
                :key="purchase_order.id">
              <td class="text-left">
                {{ purchase_order.po_date | date_short }}
              </td>
              <!-- <td class="text-left">
                {{ purchase_order.sequence_number }}
              </td> -->
              <td
                  @dblclick="filter_by(purchase_order.name_code)"
                  class="text-left">
                {{ purchase_order.name_code }}
              </td>

              <td
                  @dblclick="filter_by(purchase_order.description)"
                  class="text-left">
                {{ purchase_order.description }}
              </td>

              <td
                  @dblclick="filter_by(purchase_order.our_ref)"
                  class="text-left">
                {{ purchase_order.our_ref }}
              </td>
              <td
                  @dblclick="filter_by(purchase_order.their_ref)"
                  class="text-left">
                {{ purchase_order.their_ref }}
              </td>
              <!-- <td
                  @dblclick="filter_by(purchase_order.lines)"
                  class="text-right">
                {{ purchase_order.lines }}
              </td>
              <td
                  @dblclick="filter_by(purchase_order.items)"
                  class="text-right">
                {{ purchase_order.items }}
              </td>
              <td
                  @dblclick="filter_by(purchase_order.order_total)"
                  class="text-right">
                {{ purchase_order.order_total | currency }}
              </td> -->
              <td
                  @dblclick="filter_by(purchase_order.gross)"
                  class="text-right">
                {{ purchase_order.gross | currency }}
              </td>

              <td class="text-center">
                <button
                        @click="see_lines(purchase_order.our_ref)"
                        type="button"
                        class="btn btn-x-sm btn-outline-success mr-2">
                  <list-icon
                             size="1.0x"
                             class="mr-1"></list-icon>
                  See Lines
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row">
      <Pagination
                  v-if="pages"
                  :pages="pages"
                  :selected_page="selected_page"
                  v-on:updatePage="updatePage" />
    </div>
  </basicLayout>
</template>

<script>
import { fb, db } from "@/firebaseConfig";
import { orderBy } from "lodash";
import basicLayout from "@/components/basicLayout";
import filter_mixins from "@/mixins/filter_mixins";
import sort_mixins from "@/mixins/sort_mixins";
import utility_mixins from "@/mixins/utility_mixins";
import Pagination from "@/components/Pagination";
import { ListIcon, RefreshCwIcon } from "vue-feather-icons";

export default {
  name: "purchase_orders",
  data() {
    return {
      purchase_orders: [],
      import_po_seq: "",
      search: "",
      debounce: null,
      sort_column: ["sequencenumber"],
      sort_order: "desc",
      page_limit: 300,
      selected_page: 1,
    };
  },
  components: {
    basicLayout,
    Pagination,
    ListIcon,
    RefreshCwIcon,
    // FileTextIcon,
    // CompassIcon,
  },
  mixins: [filter_mixins, sort_mixins, utility_mixins],

  firestore() {
    return {
      purchase_orders: db.collection("purchase_order_headers").orderBy("our_ref", "desc").limit(300),
    };
  },
  methods: {
    get_pos() {
      console.log("get_po");
      const moneyworks_get_pos = fb.httpsCallable("moneyworks_get_pos");
      moneyworks_get_pos().then((result) => {
        console.log(JSON.stringify(result.data));
      });
    },

    // get_po: async function () {
    //   console.log("get_po");
    //   if (this.import_po_seq) {
    //     const purchase_order_importer = fb.httpsCallable("purchase_order_importer");
    //     const po_data = await purchase_order_importer(this.import_po_seq);
    //     console.log(po_data);
    //   }
    // },
    debounce_search(event) {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.search = event.target.value;
      }, 200);
    },
    see_lines(ourref) {
      this.$router.push({
        name: "PurchaseOrderLines",
        params: { ourref: ourref },
      });
    },
    filter_by(filter) {
      this.search = filter;
    },
    sort_by(column) {
      this.sort_column = column;
      this.sort_order = this.sort_order === "asc" ? "desc" : "asc";
    },
    clear_search() {
      this.search = "";
    },
    updatePage(value) {
      this.selected_page = value;
      this.clear_search();
    },
  },
  computed: {
    filtered() {
      let visible_purchase_orders = this.purchase_orders.slice();

      visible_purchase_orders = visible_purchase_orders.filter(
        (purchase_order) => !purchase_order.mw_seq
      );

      const search = this.search;

      if (this.sort_column) {
        visible_purchase_orders = orderBy(
          visible_purchase_orders,
          (item) => item[this.sort_column] || "",
          this.sort_order
        );
      }

      if (!search) {
        const start = (this.selected_page - 1) * this.page_limit;
        const end = start + this.page_limit;
        visible_purchase_orders = visible_purchase_orders.slice(start, end);
      }

      visible_purchase_orders = visible_purchase_orders.filter(
        (purchase_order) => {
          const s = Object.values(purchase_order);
          const su = s.toString().toUpperCase();
          return su.match(search.toString().toUpperCase());
        }
      );
      return visible_purchase_orders;
    },
    pages() {
      const pages_array = this.search ? this.filtered : this.purchase_orders;
      return Math.ceil(pages_array.length / this.page_limit);
    },
  },
};
</script>

<style scoped></style>
