export async function starshipit_request(options) {
  const { account, url, method, data } = options


  if (account === "RYTHMETHOD") {
    let api_key = "d4d6455cb38b4d289001919fa9c46ae7"
    let api_subscription_key = "766fb621ab2a4ec8b9fecd5005a55c3b"
  } else if (account === "RYTHMETHOD") {
    let api_key = "27660227a67449c7836cac2236a2f5e5"
    let api_subscription_key = "6ec5944376b8499b8f9e804368eddc76"
  }




  const base_url = 'https://api.starshipit.com/api'
  const headers = {
    'Content-Type': 'application/json',
    'StarShipIT-Api-Key': api_key,
    'Ocp-Apim-Subscription-Key': api_subscription_key
  }

  try {
    const req_url = base_url + '/' + url

    const req_options = {
      method: method,
      headers: headers
    }

    console.log(JSON.stringify(data))

    if (data) {
      req_options.body = JSON.stringify(data)
    }

    console.log('req_url: ' + req_url)
    // console.log("req_options: " + JSON.stringify(req_options))

    const res = await fetch(req_url, req_options)
    const res_json = await res.json()

    // console.log("starshipit res_json: " + JSON.stringify(res_json))

    return res_json
  } catch (error) {
    console.log(error)
  }
}
