<template>
  <basicLayout>
    <h5>LINES SENT BACK</h5>
    <div class="row">
      <div class="col-xl-6">
        <div class="input-group input-group-sm mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text">Search..</span>
          </div>
          <input
                 @input="debounce_search"
                 :value="search"
                 type="text"
                 class="form-control"
                 placeholder="PO Number, Name, Code or Title" />
          <div class="input-group-append">
            <button
                    @click="clear_search"
                    class="btn btn-outline-secondary"
                    type="button">
              Clear
            </button>
          </div>
        </div>
      </div>

      <div class="col-xl-6">
        <div class="btn-toolbar d-none d-lg-block">
          <button
                  @click="download_csv"
                  type="button"
                  class="btn btn-sm btn-outline-secondary"
                  :class="{ active: outstanding_only }">
            Download CSV
          </button>
        </div>
      </div>
    </div>

    <hr />
    <div class="row mb-2 mt-2">
      <div class="col text-right">
        <b>Showing: {{ filtered.length }}</b> of
        <b>{{ display_lines.length }}</b>
      </div>
    </div>

    <div
         v-if="!isLoading"
         class="tableFixHead">
      <table class="table table-bordered table-hover table-sm">
        <thead class="thead-dark">
          <tr>
            <th
                @click="sort_by('created')"
                class="text-left">
              Date Sent Back
            </th>
            <th
                @click="sort_by('created')"
                class="text-left">
              Age
            </th>
            <th
                @click="sort_by('po_number')"
                class="text-left">
              PO No.
            </th>
            <th
                @click="sort_by('name_code')"
                class="text-left">
              To
            </th>
            <th
                @click="sort_by('stock_code')"
                class="text-left">
              Code
            </th>
            <th
                @click="sort_by('description')"
                class="text-left">
              Title
            </th>
            <!-- <th @click="sort_by('description')" class="text-right">
                Release
              </th> -->
            <th
                @click="sort_by('price')"
                class="text-left">
              Price
            </th>

            <th class="text-right">Outstanding</th>
            <th
                @click="sort_by('quantity')"
                class="text-right">
              Ordered
            </th>
            <th
                @click="sort_by('allocated')"
                class="text-right">
              Allocated
            </th>
            <!-- <th
                @click="sort_by('locked')"
                class="text-right">
              Locked
            </th> -->
            <th
                @click="sort_by('picking')"
                class="text-right">
              Handling
            </th>
            <!-- <th @click="sort_by('picked')" class="text-right">Picked</th> -->
            <th
                @click="sort_by('shipped')"
                class="text-right">
              Shipped
            </th>
            <!-- <th></th> -->
          </tr>
        </thead>
        <tbody>
          <template v-for="line in filtered">
            <tr
                :key="line.id"
                :class="prod_class(line)">
              <td
                  class="text-left"
                  :title="line.date_sent_back | moment_long">

                {{ (line.date_set_back || line.date_sent_back) | moment_short }}
              </td>
              <td
                  @dblclick="filter_by(line.line_sent_back.created)"
                  class="text-right d-none d-sm-table-cell">{{ days_ago(line.date_sent_back) }}</td>
              <td
                  @dblclick="filter_by(line.line_sent_back.po_number)"
                  class="text-left d-none d-sm-table-cell">
                {{ line.line_sent_back.po_number }}
              </td>
              <td
                  @dblclick="filter_by(line.line_sent_back.name_code)"
                  class="text-left">
                {{ line.line_sent_back.name }} ({{
                  line.line_sent_back.name_code
                }})
              </td>
              <td
                  @dblclick="filter_by(line.line_sent_back.stock_code)"
                  class="text-left">
                {{ line.line_sent_back.stock_code }}
              </td>
              <td class="text-left">{{ line.line_sent_back.description }}</td>
              <!-- <td class="text-left">{{ line.description }}</td> -->
              <td class="text-right">
                {{ line.line_sent_back.price | currency }}
              </td>
              <!-- <td class="text-left">{{ line.is_outstanding }}</td> -->
              <td class="text-right">
                {{
                  line.line_sent_back.quantity -
                  line.line_sent_back.allocated -
                  line.line_sent_back.picking -
                  line.line_sent_back.picked -
                  line.line_sent_back.shipped
                }}
              </td>
              <td class="text-right">{{ line.line_sent_back.quantity }}</td>
              <td class="text-right">{{ line.line_sent_back.allocated }}</td>

              <!-- <td class="text-center">
                <div class="form-check">
                  <input
                         v-model="line.locked"
                         type="checkbox"
                         class="form-check-input"
                         :title="line.locked_by"
                         @change="lock_allocation(line)" />
                </div>
              </td> -->
              <td class="text-right">
                {{ line.line_sent_back.picking + line.line_sent_back.picked }}
              </td>
              <!-- <td class="text-right">{{ line.picked }}</td> -->
              <td class="text-right">{{ line.line_sent_back.shipped }}</td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
    <Pagination
                v-if="pages"
                :pages="pages"
                :selected_page="selected_page"
                v-on:updatePage="updatePage" />
  </basicLayout>
</template>

<script>
import moment from "moment";
import { db } from "@/firebaseConfig";
import { actions, getters } from "@/store";
import { orderBy } from "lodash";
import basicLayout from "@/components/basicLayout";
import Pagination from "@/components/Pagination";
import orders_mixins from "@/mixins/orders_mixins";
import filter_mixins from "@/mixins/filter_mixins";
import Papa from "papaparse";
import { TrashIcon } from "vue-feather-icons";

const lines = db.collection("lines_sent_back").orderBy("date_sent_back", "desc").limit(600)

export default {
  name: "orders",

  data() {
    return {
      display_lines: [],
      lines_filters: ["outstanding_lines", "locked_lines"],
      outstanding_only: true,
      deleted_titles: true,
      age: 56,
      old_lines: false,
      search: "",
      sort_column: ["date_set_back", "date_sent_back"],
      sort_order: "desc",
      page_limit: 300,
      selected_page: 1,
      locked_products: false,
      confirm_delete_ids: [],
      confirm_delete: false,
      delete_timeouts: [],
    };
  },
  components: { basicLayout, Pagination, TrashIcon },
  mixins: [orders_mixins, filter_mixins],

  created() {
    actions.setLoading(true);
  },

  methods: {
    debounce_search(event) {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.search = event.target.value;
      }, 600);
    },
    updatePage(value) {
      this.selected_page = value;
      this.clear_search();
    },
    filter_by(filter) {
      this.search = filter;
    },
    sort_by(column) {
      this.sort_column = column;
      this.sort_order = this.sort_order === "asc" ? "desc" : "asc";
    },
    clear_search() {
      this.search = "";
    },
    days_ago(date) {
      const m = moment(date.toDate());
      const today = moment().startOf("day");
      return Math.round(moment.duration(today - m).asDays());
    },

    download_csv(event) {
      // console.log(event);
      if (this.filtered.length === 0) return;

      const download_data = this.filtered.map((line) => {

        const csv_line = {
          date_sent_back: moment(
            line.date_sent_back.toDate())
            .local()
            .format('DD/MM/YYYY'),
          po_number: line.line_sent_back.po_number,
          name_code: line.line_sent_back.name_code,
          name: line.line_sent_back.name,
          stock_code: line.line_sent_back.stock_code,
          stock_code_text: `\`${line.line_sent_back.stock_code}`,
          barcode: line.line_sent_back.barcode,
          barcode_text: `\`${line.line_sent_back.barcode}`,
          title: line.line_sent_back.description,
          ordered: line.line_sent_back.quantity,
          allocated: line.line_sent_back.allocated,
          picking: line.line_sent_back.picking,
          picked: line.line_sent_back.picked,
          shipped: line.line_sent_back.shipped,
          pick_notes: line.pick_notes,
        }
        return csv_line
      })
      const csv = Papa.unparse({
        // fields: [
        //   "name_code",
        //   "name",
        //   "po_number",
        //   "stock_code",
        //   "stock_code_text",
        //   "description",
        //   "barcode",
        //   "barcode_text",
        //   "price",
        //   "category1",
        //   "ordered",
        //   "outstanding",
        //   "allocated",
        //   "handling",
        //   "shipped",
        // ],
        data: download_data,
        quotes: true,
      });
      const csv_name = moment().format("YYYY-MM-DD") + "_lines_sent_back.csv";

      console.log(csv_name);
      this.downloading = false;
      const anchor = document.createElement("a");
      anchor.href = "data:text/csv," + encodeURIComponent(csv);
      anchor.download = csv_name;
      anchor.click();

    },

    prod_class(line) {
      if (line.deleted_title) return "text-red";
    },
  },

  computed: {
    isLoading() {
      return getters.isLoading();
    },
    filtered() {
      let filtered_lines = this.display_lines.slice();
      let visible_lines = [];
      // const search = this.search;

      if (this.sort_column) {
        filtered_lines = orderBy(
          filtered_lines,
          (item) => item[this.sort_column] || "",
          this.sort_order
        );
      }

      //   if (this.lines_filters.includes("deleted_titles")) {
      //     filtered_lines = filtered_lines.filter((line) => {
      //       return line.deleted_title;
      //     });
      //   }

      //   if (this.lines_filters.includes("old_lines")) {
      //     filtered_lines = filtered_lines.filter((line) => {
      //       return line.created && this.days_ago(line.created) > this.age;
      //     });
      //   }

      // console.log(visible_lines.length);

      visible_lines = filtered_lines.filter((line) => {
        const s = Object.values(line);
        const su = s.toString().toUpperCase();
        return su.match(this.search.toString().toUpperCase());
      });

      return visible_lines;
    },

    pages() {
      let lines_array = this.lines;

      if (this.search || this.outstanding_only) {
        lines_array = this.filtered;
      }

      return Math.ceil(lines_array.length / this.page_limit);
    },
  },

  watch: {
    lines_filters: {
      immediate: true,
      handler(filter) {
        actions.setLoading(true);
        let query = lines;

        query = query
        // .orderBy("date_set_back", "desc")
        // .orderBy("date_sent_back", "desc")
        // .limit(100);

        // if (filter.length && filter.includes("outstanding_lines")) {
        //   // console.log("outstanding_lines");
        //   query = query.where("is_outstanding", "==", true);
        // }

        // if (filter.length && filter.includes("locked_lines")) {
        //   // console.log("locked_lines");
        //   query = query.where("locked", "==", true);
        // }

        this.$bind("display_lines", query);
      },
    },

    search: function (newSearch) {
      if (newSearch && newSearch.length > 0) {
        this.selected_page = 1;
      }
    },

    display_lines: function (new_lines) {
      if (new_lines.length > 1) {
        actions.setLoading(false);
      }
    },
  },
};
</script>

<style scoped></style>
