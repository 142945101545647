<template>
  <basicLayout>
    <div class="row">
      <div class="col-6">
        <h4>Create User</h4>
        <div class="card m-2">
          <div class="card-body">
            <form @submit.prevent="create_user">
              <div class="form-group">
                <label for="Name">Name</label>
                <input
                  v-model="user.name"
                  type="text"
                  class="form-control"
                  id="name"
                  placeholder="Name"
                  required
                />
              </div>

              <div class="form-group">
                <label for="exampleInputEmail1">Email address</label>
                <input
                  v-model="user.email"
                  type="email"
                  class="form-control"
                  id="exampleInputEmail1"
                  placeholder="Enter email"
                  required
                />
              </div>
              <div class="form-group">
                <label for="exampleInputPassword1">Password</label>
                <input
                  v-model="user.password"
                  type="password"
                  class="form-control"
                  id="exampleInputPassword1"
                  placeholder="Password"
                  required
                />
              </div>
              <button type="submit" class="btn btn-secondary">Save</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </basicLayout>
</template>

<script>
import basicLayout from '@/components/basicLayout'
import { fb } from '@/firebaseConfig'

export default {
  name: 'createUser',
  data: function () {
    return {
      user: {}
    }
  },
  components: {
    basicLayout
  },
  methods: {
    create_user: function () {
      console.log(this.user)
      const createUser = fb.httpsCallable('createUser')
      createUser(this.user).then(function (result) {
        console.log(result)
      })
      this.$router.push('/users')
    }
  }
}
</script>
