<template>
  <div>
    <div
      class="modal fade show modal-mask"
      tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              {{ selected_line.description }} ({{ selected_line.stock_code }})
            </h5>

            <button
              @click="close_add_note"
              type="button"
              class="close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label>Notes</label>
              <textarea v-model="receiving_notes"></textarea>
            </div>
          </div>
          <div class="modal-footer">
            <button
              @click.prevent="delete_note_line"
              @keyup.enter.prevent="delete_note_line"
              type="button"
              class="btn btn-sm btn-outline-danger">
              Delete
            </button>
            <button
              @click.prevent="add_note_line"
              @keyup.enter.prevent="add_note_line"
              type="button"
              class="btn btn-sm btn-outline-success">
              Save
            </button>
            <button
              @click="close_add_note"
              type="button"
              class="btn btn-sm btn-outline-secondary">
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { db } from "@/firebaseConfig";

export default {
  name: "picking_add_note",
  data() {
    return {
      receiving_notes: this.selected_line.receiving_notes,
    };
  },
  props: {
    selected_line: Object,
  },
  methods: {
    close_add_note() {
      this.$emit("close_add_note");
    },
    add_note_line: async function () {
      const receiving_notes = this.receiving_notes || null;
      console.log(this.selected_line.id);
      this.$emit("update_note", receiving_notes);
      //   await db.collection("lines").doc(this.selected_line.id).update({
      //     receiving_notes: receiving_notes,
      //   });
      this.close_add_note();
    },

    delete_note_line: async function () {
      this.$emit("update_note", null);
      //   await db.collection("lines").doc(this.selected_line.id).update(null);
      this.close_add_note();
    },
  },
};
</script>

<style scoped>
.my-toast {
  font-size: 5em;
}
.modal {
  display: block;
  padding-right: 17px;
}
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

textarea {
  width: 100%;
  height: 100px;
}
</style>
