import { render, staticRenderFns } from "./OrdersByName.vue?vue&type=template&id=355c1630&scoped=true&"
import script from "./OrdersByName.vue?vue&type=script&lang=js&"
export * from "./OrdersByName.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "355c1630",
  null
  
)

export default component.exports