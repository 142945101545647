<template>
  <basicLayout>
    <div v-if="shipment" class="p-2">
      <h5>{{ shipment.name }} ({{ shipment.name_code }})</h5>
      <h6>SSID #{{ ssid }}</h6>
      <h6 v-if="shipment.mw_seq">MW SEQ #{{ shipment.mw_seq }}</h6>
      <hr />
      <h6>CREATED: {{ shipment.created | moment_short }}</h6>

      <h6>ITEMS (EXCLUDING LEVY): {{ Number(filtered_shipment) }}</h6>
    </div>
    <div class="btn-toolbar m-2">
      <a
        href="/shipped"
        role="button"
        class="btn btn-sm btn-outline-secondary mr-2"
        >Back</a
      >

      <div class="input-group input-group-sm">
        <div class="input-group-prepend">
          <div class="input-group-text" id="btnGroupAddon">Freight</div>
        </div>
        <input
          v-model="freight_amount"
          type="number"
          step="0.01"
          class="form-control mr-2"
          placeholder="0.00"
          aria-label="Input group example"
          aria-describedby="btnGroupAddon"
        />
      </div>
      <button
        v-if="!shipment.mw_seq && !confirm_invoice && !processing"
        @click="invoice_shipment()"
        class="btn btn-sm btn-success mr-2"
      >
        <file-text-icon size="1.0x" class="custom-class"></file-text-icon>
        Invoice Shipment
      </button>
      <button
        v-if="confirm_invoice && !processing"
        @click="invoice_shipment()"
        class="btn btn-sm alert-success mr-2"
      >
        <file-text-icon size="1.0x" class="custom-class"></file-text-icon>
        No Freight - Really Invoice?
      </button>
      <button
        v-if="confirm_invoice"
        @click="confirm_invoice = false"
        class="btn btn-sm btn-secondary mr-2"
      >
        Cancel
      </button>
    </div>
    <div class="printable-table">
      <div class="tableFixHead">
        <table class="table table-bordered table-hover table-sm">
          <thead class="thead-dark">
            <tr>
              <th @click="sort_by('stock_code')" class="text-left">Code</th>
              <th @click="sort_by('stock_code')" class="text-left">
                Description
              </th>
              <th @click="sort_by('format')" class="text-left">Format</th>
              <th @click="sort_by('po_number')" class="text-left">PO Number</th>
              <th @click="sort_by('po_number')" class="text-left">Price</th>
              <th @click="sort_by('po_number')" class="text-left">Quantity</th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="line in filtered" :key="line.id">
              <td>{{ line.stock_code }}</td>
              <td>{{ line.description }}</td>
              <td>{{ line.format || line.category1 }}</td>
              <td>{{ line.po_number }}</td>
              <td class="text-right">{{ line.price | currency }}</td>
              <td class="text-right">
                {{ line.shipment_quantity || line.invoice_quantity }}
              </td>
            </tr>
          </tbody>
          <tr>
            <td colspan="5" class="font-weight-bold text-right">TOTAL</td>
            <td class="font-weight-bold text-right">{{ filtered_shipment }}</td>
          </tr>
        </table>
      </div>
    </div>
  </basicLayout>
</template>

<script>
import { fb, db } from "@/firebaseConfig";
import { actions } from "@/store";
import { orderBy } from "lodash";
import basicLayout from "@/components/basicLayout";
import filter_mixins from "@/mixins/filter_mixins";
import orders_mixins from "@/mixins/orders_mixins";
import utility_mixins from "@/mixins/utility_mixins";
import { FileTextIcon } from "vue-feather-icons";

const shipments = db.collection("shipments");

export default {
  name: "shipped_lines",
  data() {
    return {
      ssid: this.$route.params.ssid,
      shipment: {},
      freight_amount: 0,
      name: {},
      sort_column: ["code"],
      sort_order: "asc",
      confirm_invoice: false,
      processing: false,
    };
  },
  components: { basicLayout, FileTextIcon },
  mixins: [filter_mixins, orders_mixins, utility_mixins],

  watch: {
    ssid: {
      immediate: true,
      handler: function (new_val) {
        console.log("shipment: " + new_val);
        this.$bind("shipment", shipments.doc(`${new_val}`));
      },
    },
  },

  methods: {
    sort_by(column) {
      this.sort_column = column;
      this.sort_order = this.sort_order === "asc" ? "desc" : "asc";
    },

    async invoice_shipment() {
      try {
        if (this.processing || this.shipment.mw_seq) return false;

        actions.setLoading(true);
        this.processing = true;

        if (this.freight_amount === 0 && this.confirm_invoice === false) {
          this.confirm_invoice = true;
          this.processing = false;
          actions.setLoading(false);
          return false;
        }

        const mw_invoice_request = await Promise.race([
          this.create_mw_invoice(),
          this.timeout(10000),
        ]);
        console.log("we made it on time");

        const mw_invoice = mw_invoice_request.data;

        if (mw_invoice.success) {
          this.mw_invoice = mw_invoice;
          this.$router.push("/invoiced");
          this.$toast.success("Shipment Invoiced", {
            position: "top-right",
            timeout: 2000,
          });
          this.freight_amount = 0;
          await this.get_form(mw_invoice.mw_seq);
          this.timeout(2000);
          this.confirm_invoice = false;
        } else {
          this.$toast.error("Shipment not invoiced: " + mw_invoice.error, {
            position: "top-right",
            timeout: 10000,
          });
          this.timeout(2000);
          this.confirm_invoice = false;
        }
        return;
      } catch (err) {
        console.log("our function didn't finish on time");
      } finally {
        this.processing = false;
        actions.setLoading(false);
      }
    },

    create_mw_invoice: async function () {
      const invoice_data = this.shipment;
      invoice_data.freight_amount = this.freight_amount;
      console.log(JSON.stringify(invoice_data));
      const shipment_invoice = fb.httpsCallable("shipment_invoice");
      const mw_res = await shipment_invoice(invoice_data);
      console.log("mw_res: " + JSON.stringify(mw_res));
      return mw_res;
    },

    timeout(time) {
      return new Promise((_resolve, reject) => setTimeout(reject, time));
    },
  },

  computed: {
    filtered() {
      if (!this.shipment.order_lines) {
        return [];
      }

      let visible_lines = this.shipment.order_lines.slice();

      if (this.sort_column) {
        visible_lines = orderBy(
          visible_lines,
          (item) => item[this.sort_column] || "",
          this.sort_order
        );
      }

      return visible_lines;
    },
    filtered_shipment() {
      return this.filtered.reduce(function (prev, cur) {
        return Number(prev) + Number(cur.shipment_quantity);
      }, 0);
    },
  },
};
</script>
<style scoped></style>
