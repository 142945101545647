export default {

  // ================================================================
  // GET RULES APPLICABLE TO THIS NAME

  computed: {
    applied_rules() {
      let name_code
      let group_code
      let head_office

      if (this.selected_name) {
        console.log('THIS SELECTED NAME: ' + this.selected_name)
        name_code = this.selected_name.code
        group_code = this.selected_name.group
        head_office = this.selected_name.head_office
      } else {
        console.log('THIS NAME: ' + this.name.code)
        name_code = this.name.code
        group_code = this.name.group
        head_office = this.name.head_office
      }
      console.log('NAME CODE: ' + name_code)
      console.log('GROUP CODE: ' + group_code)
      console.log('HEAD OFFICE: ' + head_office)

      // ================================================================
      // FIND HEAD-OFFICE RULES, NAME RULES AND GROUP RULES
      // NB CHECK HEAD-OFFICE FIRST

      const name_code_rules =
        this.rules.filter((r) => {
          const str_name_code = r.name_code.toString().toUpperCase()
          return str_name_code === head_office || str_name_code === name_code // && r.rule_type === "everyday";
        }) || []

      console.log('name_code_rules: ' + name_code_rules.length)

      // ================================================================
      // GET RULES APPLICABLE TO THE NAME'S GROUP

      const group_code_rules =
        (group_code &&
          this.rules.filter((r) => {
            const str_name_code = r.name_code.toString().toUpperCase()
            return str_name_code === group_code // && r.rule_type === "everyday";
          })) ||
        []

      console.log('group_code_rules: ' + group_code_rules.length)

      // ================================================================
      // MERGE THE RULE ARRAYS - WITH NAME RULES TAKING PRIORITY OVER GROUP RULES:

      // FIND UNIQUE PRODUCT CODES IN NAME RULES
      const product_codes = new Set(name_code_rules.map((d) => d.product_code))

      // FILTER GROUP RULES TO EXCLUDE THOSE UNIQUE CODES
      // RETURN CODE AND PRICE
      const merged_rules = [
        ...name_code_rules,
        ...group_code_rules.filter((r) => !product_codes.has(r.product_code))
      ]
      return merged_rules || []
    },

    customer_price_list() {
      const name = this.selected_name || this.name
      if (!name || !name.name) return []
      let customer_products = this.products
      const applied_rules = this.applied_rules

      if (!name.code.includes('050')) {
        customer_products = customer_products.filter(product => product.colour != 7)
      }

      const product_pricing =
        (name && `sellprice_${name.productpricing.toLowerCase()}`) ||
        'sellprice_a'

      const price_list = customer_products.map((product) => {
        let prevailing_price

        const product_rule = applied_rules.find((rule) => {
          return (
            rule.product_code === product.code || rule.product_code === 'ALL'
          )
        })

        if (
          product_rule &&
          product_rule.discount &&
          product.selldiscountmode === 2 // MoneyWorks - apply discount by customer
        ) {
          prevailing_price =
            product[product_pricing] * (1 - product_rule.discount / 100)
        } else if (product_rule && product_rule.price) {
          prevailing_price = product_rule.price
        } else {
          prevailing_price = product[product_pricing]
        }

        // OLD WAY WITHOUT 'DISCOUNTS'

        // const prevailing_price =
        //   (product_rule && product_rule.price) || product[product_pricing];
        // product.price_level;

        product.sell_price = prevailing_price
        product.rules = product_rule
        // product.end_date = product_rule && product_rule.end_date;

        return product
      })
      return price_list || []
    }
  }
}
