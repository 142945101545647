<template>
  <div>
    <div
         class="modal fade show modal-mask"
         tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              {{ selected_line.description }} ({{ selected_line.stock_code }})
            </h5>

            <button
                    @click="close_send_back"
                    type="button"
                    class="close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label>Quantity NOT shipped</label>
              <div class="form-control">{{ selected_line.picking }}</div>
            </div>

            <div class="form-group">
              <label>Notes</label>
              <textarea v-model="pick_notes"></textarea>
            </div>
          </div>
          <div class="modal-footer">
            <button
                    @click.prevent="send_back_line"
                    @keyup.enter.prevent="send_back_line"
                    type="button"
                    class="btn btn-sm btn-outline-success mr-2">
              Send Back
            </button>
            <button
                    @click="close_send_back"
                    type="button"
                    class="btn btn-sm btn-outline-secondary">
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { db } from "@/firebaseConfig";

export default {
  name: "picking_send_back",
  data() {
    return {
      pick_notes: this.selected_line.pick_notes,
    };
  },
  props: {
    selected_line: Object,
  },
  methods: {
    close_send_back() {
      this.$emit("close_send_back");
    },
    send_back_line: async function () {
      const selected_line = { ...this.selected_line };
      const pick_notes = this.pick_notes || null;

      // db.collection("lines_sent_back").add({
      //   date_sent_back: new Date(),
      //   line_sent_back: selected_line,
      //   pick_notes,
      // });

      console.log(this.selected_line.id);

      await db.collection("lines").doc(this.selected_line.id).update({
        picking: 0,
        // allocated: 0,
        sent_back: true,
        // locked: false,
        pick_notes: pick_notes,
      });
      this.close_send_back();
    },
  },
};
</script>

<style scoped>
.my-toast {
  font-size: 5em;
}

.modal {
  display: block;
  padding-right: 17px;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

textarea {
  width: 100%;
  height: 100px;
}
</style>
