<template>
  <div v-if="edi_order" class="card">
    <div class="card-header">
      <div class="row">
        <div class="col mr-3">
          <span class="text-muted h6 mr-3">FILE:</span>
          {{ edi_order.file_name }}
        </div>
        <div class="col mr-3">
          <span class="text-muted h6 mr-3">NAME:</span>
          {{ edi_order.name_code }}
        </div>
        <div class="col">
          <span class="text-muted h6">PO:</span>
          {{ edi_order.po_number }}
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-xl-6">
          <div class="form-group">
            <label for="upload_template">Order Type</label>
            <v-select
                      v-model="edi_order.order_type"
                      :options="type_options"
                      :selectOnTab="true"
                      :clearable="false"
                      @input="update_order_type"></v-select>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="tableFixHead col-xl-10">
          <table class="table table-bordered table-hover table-sm">
            <thead class="thead-dark">
              <tr>
                <th class="text-left" scope="col">Their Code</th>
                <th class="text-left" scope="col">Our Code</th>
                <th class="text-left" scope="col">Title</th>
                <th class="text-left" scope="col">Quantity</th>
                <th class="text-left" scope="col">Price</th>
                <th class="text-left" scope="col">Line Total</th>
                <th class="text-center" cope="col">Skip</th>
                <th class="text-center" cope="col">Valid</th>
              </tr>
            </thead>
            <tbody>
              <tr
                  v-for="line in edi_order.order_lines"
                  :key="line.line_sequence_number">
                <td class="w-15 text-left">
                  {{ line.their_stock_code }}
                </td>

                <td class="w-15">
                  <input
                         v-model="line.stock_code"
                         type="text"
                         class="form-control form-control-x-sm text-left"
                         :disabled="line.skip"
                         @change="update_order_lines()" />
                </td>
                <td class="w-25 text-left">
                  {{ line.import_description }}
                </td>
                <td class="w-10 text-right">
                  {{ line.quantity }}
                </td>
                <td class="w-10 text-right">
                  {{ line.import_price }}
                </td>
                <td class="w-10 text-right">
                  {{ (line.quantity * line.import_price).toFixed(2) }}
                </td>
                <td class="text-center">
                  <div class="form-check">
                    <input
                           v-model="line.skip"
                           type="checkbox"
                           class="form-check-input"
                           @change="update_order_lines()" />
                  </div>
                </td>
                <td class="w-10">
                  {{ line.is_valid_code }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="card-footer">
      <button
              class="btn btn-sm btn-outline-secondary mr-2"
              @click="import_order()"
              :disabled="!edi_order.order_type">
        Import Order
      </button>
      <router-link
                   v-if="edi_order.new_order_id"
                   class="btn btn-sm btn-outline-success ml-1"
                   :to="{
    name: 'OrderEdit',
    params: { order_id: edi_order.new_order_id },
  }">
        Edit Order
      </router-link>
    </div>
  </div>
</template>

<script>
import { db } from '@/firebaseConfig'

const edi_orders = db.collection('edi')

export default {
  name: 'dropship_order',
  props: {
    order_id: String
    // edi_order: Object,
  },
  data() {
    return {
      edi_order: {},
      type_options: ['Urgent', 'Catalogue', 'New Release', 'Website']
    }
  },
  watch: {
    order_id: {
      immediate: true,
      handler: function (new_val) {
        console.log('order: ' + new_val)
        this.$bind('edi_order', edi_orders.doc(new_val))
      }
    }
  },
  methods: {
    import_order() {
      console.log('emitting import_order')
      this.$emit('import_order', this.edi_order)
    },
    update_order_type: async function () {
      console.log('updating order type to ' + this.edi_order.order_type)
      await db.collection('edi').doc(this.order_id).update({
        order_type: this.edi_order.order_type
      })
    },
    update_order_lines: async function () {
      await db
        .collection('edi')
        .doc(this.edi_order.id)
        .update({ order_lines: this.edi_order.order_lines })
    }
  }
  // computed: {
  //   order() {
  //     return this.edi_order;
  //   },
  // },
}
</script>

<style scoped></style>
