<template>
  <basicLayout>
    <div class="p-2">
      <h5>EDI ORDERS</h5>
      <div class="row">
        <div class="col-6">
          <div class="input-group input-group-sm mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">Search...</span>
            </div>
            <input
              @input="debounce_search"
              :value="search"
              class="form-control"
              placeholder="PO, SO, Name, or Carrier" />
            <div class="input-group-append">
              <button
                @click="clear_search"
                class="btn btn-outline-secondary"
                type="button">
                Clear
              </button>
            </div>
          </div>
        </div>
        <div class="col-6">
          <button
            @click="ftp_fetch"
            class="btn btn-sm btn-outline-secondary mr-2">
            Fetch Orders
          </button>

          <!-- <button
            @click="download_csv"
            class="btn btn-sm btn-outline-secondary mr-2"
          >
            Download csv
          </button> -->
          <button
            v-if="selected.length"
            @click="import_selected()"
            class="btn btn-sm btn-outline-secondary mr-2">
            Import Selected
          </button>
          <!-- <button
            v-if="selected_order"
            @click="import_order()"
            class="btn btn-sm btn-outline-secondary mr-2"
          >
            Import order
          </button> -->
        </div>
      </div>
      <div class="btn-toolbar">
        <button
          @click="update_status_filter('not_imported')"
          class="btn btn-sm btn-outline-secondary mr-1"
          :class="filter_active('not_imported')">
          Not Imported
        </button>
        <button
          @click="update_status_filter('imported')"
          class="btn btn-sm btn-outline-secondary mr-1"
          :class="filter_active('imported')">
          Imported
        </button>
        <button
          @click="update_status_filter('')"
          class="btn btn-sm btn-outline-secondary mr-1"
          :class="filter_active('')">
          All
        </button>
      </div>

      <hr />
      <div class="row mb-2">
        <div
          class="col text-right"
          :class="filter_class()">
          <b>Showing: {{ this.filtered.length }}</b> of
          <b>{{ this.edi_orders.length }}</b>
        </div>
      </div>

      <div class="row">
        <div class="tableFixHead tableFixHeadShort">
          <table class="table table-bordered table-hover table-sm">
            <thead class="thead-dark">
              <tr>
                <th></th>
                <th @click="sort_by('created')">Date</th>
                <th @click="sort_by('name_code')">Name Code</th>
                <th @click="sort_by('file_name')">File Name</th>
                <th @click="sort_by('order_type')">Order Type</th>
                <th>Imported</th>
                <th>-</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(order, i) in filtered"
                :key="order.id"
                :class="row_class(order)"
                @click="select_one(order)">
                <td>
                  <input
                    @click="selectOne($event, i)"
                    type="checkbox"
                    :disabled="!order.order_type"
                    :value="order.id"
                    v-model.lazy="selected" />
                </td>

                <td class="text-left">
                  {{ order.created | moment_short }}
                </td>
                <td
                  class="text-left"
                  @dblclick="filter_by(order.name_code)">
                  {{ order.name_code }}
                </td>
                <td class="text-left">
                  {{ order.file_name }}
                </td>
                <td class="text-left">
                  {{ order.order_type }}
                </td>
                <td>
                  <span
                    class="badge"
                    :class="order.imported ? 'badge-success' : 'badge-warning'"
                    >{{ order.imported }}</span
                  >
                </td>
                <td class="text-center w-10">
                  <button
                    @click="delete_item(order)"
                    type="button"
                    class="btn btn-x-sm btn-outline-danger">
                    <trash-icon
                      size="1x"
                      class="custom-class"></trash-icon>
                    &nbsp;{{ confirm_delete_ids.includes(order.id) ? "Really Delete?" : "Delete" }}
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <hr />

      <EDIOrder
        :order_id="selected_order.id"
        v-on:import_order="import_order"></EDIOrder>
      <hr />
      <EDISkippedLines :order_id="selected_order.id"></EDISkippedLines>
    </div>
  </basicLayout>
</template>

<script>
import { fb, db } from "@/firebaseConfig";
import { actions } from "@/store";
import { orderBy } from "lodash";
import basicLayout from "@/components/basicLayout";
import filter_mixins from "@/mixins/filter_mixins";
import EDIOrder from "@/components/EDIOrder";
import EDISkippedLines from "@/components/EDISkippedLines";
import { TrashIcon } from "vue-feather-icons";

export default {
  name: "edi_orders",
  data() {
    return {
      edi_orders: [],
      selected_order: {},
      search: "",
      debounce: null,
      status_filter: "not_imported",
      sort_column: ["created", "desc"],
      sort_order: "asc",
      selected: [],
      selectAll: false,
      savedIndex: null,
      confirm_delete_ids: [],
      confirm_delete: false,
      delete_timeouts: [],
    };
  },
  components: {
    basicLayout,
    EDIOrder,
    EDISkippedLines,
    TrashIcon,
  },
  mixins: [filter_mixins],
  firestore: {
    edi_orders: db.collection("edi").orderBy("created", "desc").limit(2000),
  },

  methods: {
    show_selected() {
      console.log(this.selected_order);
    },
    ftp_fetch() {
      console.log("ftp_fetch");
      const ftp_fetch = fb.httpsCallable("ftp_fetch");
      ftp_fetch({ text: "yoyoyoyo" }).then((result) => {
        console.log(JSON.stringify(result));
      });
    },
    update_stock_code: async function (line) {
      await db.collection("edi").doc(line.id).update({
        stock_code: line.stock_code,
      });
    },
    skip_line(line) {
      db.collection("edi").doc(line.id).update({ skip: true });
    },

    pre_process_order: async function (edi_order) {
      console.log("pre_process_order function triggered");

      const order_to_import = edi_order;
      let is_order_valid = true;

      console.log(JSON.stringify(order_to_import));

      const unskipped_order_lines = order_to_import.order_lines.filter((line) => !line.skip);

      if (!unskipped_order_lines.length) {
        actions.setLoading(false);
        is_order_valid = false;
        return is_order_valid;
      }

      const order_lines_array = await Promise.all(
        unskipped_order_lines.map(async (line) => {
          const stock_code = line.stock_code;
          const clean_code = stock_code.split("/").join("|");
          const line_product_ref = await db.collection("products").doc(clean_code).get();
          console.log("line product: " + line_product_ref.data());
          if (!line_product_ref.exists) {
            line.is_valid_code = false;
            is_order_valid = false;
          }
          console.log(line);
          return line;
        })
      );

      console.log(JSON.stringify(order_lines_array));

      await db.collection("edi").doc(order_to_import.id).update({ order_lines: order_lines_array });
      return is_order_valid;
    },

    import_order: async function (edi_order) {
      console.log("import order function triggered");

      const is_valid_order = await this.pre_process_order(edi_order);

      console.log("is_valid_order: " + is_valid_order);

      if (!is_valid_order) {
        console.log("NOT VALID");

        this.$toast.error("Order Not Valid", {
          position: "top-right",
          timeout: 2000,
        });

        return;
      }

      console.log(JSON.stringify(edi_order));

      const order_to_import = edi_order;

      console.log(JSON.stringify(order_to_import));

      const unskipped_order_lines = order_to_import.order_lines.filter((line) => !line.skip);

      if (!unskipped_order_lines.length) {
        actions.setLoading(false);
        return;
      }

      order_to_import.order_lines = unskipped_order_lines;

      console.log(order_to_import);

      const order_importer = fb.httpsCallable("order_importer");
      const order_importer_res = await order_importer(order_to_import);
      console.log(JSON.stringify(order_importer_res));
      const { success, error, new_order_id, skipped_lines } = order_importer_res.data;

      let update_edi;

      if (success) {
        update_edi = {
          imported: true,
          new_order_id,
          skipped_lines,
        };

        this.$toast.success("Order Imported", {
          position: "top-right",
          timeout: 2000,
        });

        // this.new_order_id = order_importer_res.data.new_order_id;
        this.skipped_lines = order_importer_res.data.skipped_lines;
      } else {
        update_edi = {
          imported: success,
          error: error,
        };

        this.$toast.error("Order did not Import: " + order_importer.error, {
          position: "top-right",
          timeout: 2000,
        });
      }

      db.collection("edi").doc(order_to_import.id).update(update_edi);

      actions.setLoading(false);
    },

    row_class(order) {
      return { "alert-primary": this.selected_order.id === order.id };
    },

    selectOne(e, index) {
      const savedIndex = this.savedIndex;
      this.savedIndex = index;

      if (savedIndex === null || !e.shiftKey) return;
      if (index === savedIndex) return;

      const subset = this.filtered
        .slice(Math.min(index, savedIndex), Math.max(index, savedIndex) + 1)
        // eslint-disable-next-line no-unused-vars
        .map(({ id, ...order }) => {
          return id;
        });

      if (e.currentTarget.checked) {
        if (!this.selected.length) {
          this.selected = subset;
        } else {
          this.selected = [...new Set(this.selected.concat(subset))];
        }
      } else {
        const subset_ids = new Set(subset);
        this.selected = this.selected.filter((sel) => {
          return !subset_ids.has(sel);
        });
      }
    },
    select_one(order) {
      this.selected_order = order;
      console.log(JSON.stringify(this.selected_order.order_type));
    },
    debounce_search(event) {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.search = event.target.value;
      }, 600);
    },
    sort_by(column) {
      this.sort_column = column;
      this.sort_order = this.sort_order === "asc" ? "desc" : "asc";
    },
    filter_by(filter) {
      this.selected = [];
      this.selectAll = false;
      this.search = filter;
    },
    clear_search() {
      this.selected_order = {};
      this.selectAll = false;
      this.search = "";
    },
    update_status_filter(filter) {
      this.status_filter = filter;
      this.selected_order = {};
    },
    filter_active(filter) {
      if (this.status_filter === filter) {
        return "active";
      }
    },
    filter_class() {
      if (this.status_filter === "imported") {
        return "alert-success";
      } else if (this.status_filter === "not_imported") {
        return "alert-warning";
      }
    },
    import_selected: async function () {
      const selected_orders = this.filtered.filter((order) => {
        return this.selected.includes(order.id);
      });

      console.log(selected_orders);

      const import_promises = selected_orders.map(async (order) => {
        console.log(order.order_type);
        return this.import_order(order);
      });

      await Promise.all(import_promises);

      this.selected = [];
    },
    delete_item: async function (po) {
      let confirm_delete_ids = this.confirm_delete_ids;

      // let delete_wait;
      if (confirm_delete_ids.includes(po.id)) {
        clearTimeout(this.delete_timeouts[po.id]);
        await db.collection("edi").doc(po.id).delete();
        confirm_delete_ids = confirm_delete_ids.filter((i) => i !== po.id);
        return;
      }
      confirm_delete_ids.push(po.id);
      this.delete_timeouts[po.id] = setTimeout(() => {
        confirm_delete_ids = confirm_delete_ids.filter((i) => i !== po.id);
      }, 4000);
    },
  },
  computed: {
    filtered() {
      let visible_orders = this.edi_orders.slice();
      const search = this.search;

      if (!search) visible_orders = visible_orders.slice(0, 200);

      if (this.status_filter === "not_imported") {
        visible_orders = visible_orders.filter((line) => !line.imported);
      } else if (this.status_filter === "imported") {
        visible_orders = visible_orders.filter((line) => line.imported);
      }

      visible_orders = visible_orders.filter((order) => {
        const s =
          Object.values(order) +
          order.order_lines.map((line) => line.stock_code) +
          order.order_lines.map((line) => line.import_description);
        const su = s.toString().toUpperCase();
        return su.match(search.toString().toUpperCase());
      });

      if (this.sort_column) {
        visible_orders = orderBy(visible_orders, this.sort_column, this.sort_order);
      }
      return visible_orders;
    },
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    search: function (newSearch) {
      if (newSearch) {
        this.selected_order = {};
        this.selected_page = -1;
      } else {
        this.selected_page = 1;
      }
    },
  },
};
</script>

<style scoped>
</style>
