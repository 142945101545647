<template>
  <basicLayout>
    <div class="card">
      <div class="card-body p-3">
        <h4>{{ rule.name_code }} - {{ rule.product_code }}</h4>
        <h6 v-if="rule.start_date">
          {{ rule.start_date }} - {{ rule.end_date }}
        </h6>
        <form @submit.prevent="save_rule">
          <div class="form-group col-xl-6">
            <label for="Price">Price</label>
            <input
              v-model="rule.price"
              type="text"
              class="form-control"
              id="price"
              placeholder="Price"
            />
          </div>
          <div class="form-group col-xl-6">
            <label for="Price">Discount Percentage</label>
            <input
              v-model="rule.discount"
              type="text"
              class="form-control"
              id="price"
              placeholder="Discount percentage e.g. 15"
            />
          </div>
          <div class="form-group col-xl-6">
            <label for="end_date">Start Date</label>
            <input
              v-model="rule.start_date"
              type="date"
              class="form-control"
              id="end_date"
              placeholder="Rule Start Date DD/MM/YYYY"
            />
          </div>
          <div class="form-group col-xl-6">
            <label for="end_date">End Date</label>
            <input
              v-model="rule.end_date"
              type="date"
              class="form-control"
              id="end_date"
              placeholder="Rule End Date DD/MM/YYYY"
            />
          </div>

          <button type="submit" class="btn btn-secondary mr-3">Save</button>
          <router-link class="nav-btn btn-secondary" :to="{ name: 'Rules' }">
            <button class="btn btn-secondary">Cancel</button>
          </router-link>
        </form>
      </div>
    </div>
  </basicLayout>
</template>

<script>
import { db } from '@/firebaseConfig'
import basicLayout from '@/components/basicLayout'

export default {
  name: 'RuleEdit',
  data () {
    return {
      rule: { type: 'catalogue' }
    }
  },
  firestore () {
    return {
      rule: db.collection('rules').doc(this.$route.params.rule_id)
    }
  },
  components: {
    basicLayout
  },

  methods: {
    save_rule () {
      console.log(this.rule.id)
      db.collection('rules')
        .doc(this.rule.id)
        .set(this.rule)
      this.$router.push('/rules')
    }
  }
}
</script>
